import React, { useState, useEffect } from "react";
import Dropdown from 'react-bootstrap/Dropdown';
import shortingIcon from '../Assets/img/icon/shortingIcon.svg';
import useRequest from "../hooks/useRequest";
import apiPath from "../constants/apiPath";
import { useParams } from "react-router-dom";
import Banners from "../Components/banners/Banners";
import Loader from "../Components/Loader";
import ProductsCard from "../Components/products/ProductsCard";
import lang from "../helper/lang";
import { useAppContext } from "../context/AppContext";
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import searchIcon from '../Assets/img/icon/searchIcon.svg';
import NoDataFound from "../Assets/img/NotFound.png";


const BrandProducts = () => {

    const { id } = useParams();
    const { language } = useAppContext();
    const { request } = useRequest();
    const [toggle, setToggle] = useState(false);
    const [loading, setLoading] = useState(false);
    const [products, setProducts] = useState([]);
    const [brand, setBrand] = useState();


    const getProducts = (id) => {
        setLoading(true);
        request({
            url: apiPath.brancdProductList + "/" + id,
            method: "GET",
            onSuccess: (data) => {
                if (data.status) {
                    console.log("products", data);
                    setProducts(data.data);
                    setBrand(data.brand);
                    setLoading(false);
                } else {
                    console.log("something went wrong!")
                    setLoading(false);
                }
            },
            onError: (err) => {
                setLoading(false);
            }
        });
    }

    const handleSort = (val) => {
        setLoading(true);
        request({
            url: apiPath.brancdProductList + "/" + id + `?sort=${val}`,
            method: "GET",
            onSuccess: (data) => {
                if (data.status) {
                    console.log("products", data);
                    setProducts(data.data);
                } else {
                    console.log("something went wrong!")
                }
                setLoading(false);
            },
            onError: (err) => {
                setLoading(false);
            }
        });
    }

    const handleSearch = (val) => {
        setLoading(true);
        request({
            url: apiPath.brancdProductList + "/" + id + `?text=${val}`,
            method: "GET",
            onSuccess: (data) => {
                if (data.status) {
                    setProducts(data.data);
                    setBrand(data.brand);
                    setLoading(false);
                } else {
                    console.log("something went wrong!")
                    setLoading(false);
                }
            },
            onError: (err) => {
                setLoading(false);
            }
        });
    }

    useEffect(() => {
        getProducts(id);
    }, [id]);

    return (
        <>
            <div className="mainWraper">

                <div className="otherSliderMain sectionPading bgLight">
                    <Banners />
                </div>

                <div className="productSection sectionPading">
                    <div className="container">
                        <div className="sectionHeading">
                            <div className="row g-3 align-items-center">
                                <div className="col">
                                    <div className="title d-inline-block">
                                        <div className="txt">{lang("Brand")} <span className="ms-2 me-2">-</span> <span className="selectedCategory text-capitalize">{language !== ('en' || null) ? (brand[`${language}_name`] ?? brand?.name) : brand?.name}</span></div>
                                        <div className="titleDesign d-flex align-items-center gap-2 mt-3"></div>
                                    </div>
                                </div>
                                <div className="col-sm-auto d-flex align-items-center gap-3">
                                    <InputGroup className="position-relative">
                                        <Form.Control type="search" id='headerSearch' onChange={({ target }) => handleSearch(target.value)} className="rounded-1 pe-5" placeholder={lang("Search") + "..."} />
                                        <label for="headerSearch" className="btn searchBtn d-flex align-items-cneter justify-content-center m-0 rounded-1 border-0 shadow-none position-absolute end-0 top-0 bottom-0 me-1 my-auto">
                                            <img className="w-100" src={searchIcon} alt="search" />
                                        </label>
                                    </InputGroup>
                                    <Dropdown className="filterDrop shortBy">
                                        <Dropdown.Toggle id="dropdown-basic" className="p0 searchBtn d-flex align-items-cneter justify-content-center border-0 shadow-none">
                                            <img className="" src={shortingIcon} alt="" style={{ filter: "invert(1)" }} />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu className="border-0 p-0">
                                            <Dropdown.Item className="border-bottom" onClick={() => handleSort('A-Z')} href="#sort=A-Z">A {lang("to")} Z</Dropdown.Item>
                                            <Dropdown.Item className="border-bottom" onClick={() => handleSort('Z-A')} href="#sort=Z-A">Z {lang("to")} A</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </div>
                        </div>
                        <div className="productsOuter mt-2">
                            {loading ? (<Loader />) : (<div className="row g-3 row-cols-xxl-6">
                                {products?.length
                                    ? (products?.map((item, idx) => {
                                        return (
                                            <ProductsCard prod={item} favourite={item?.is_wishlist} />
                                        )
                                    })

                                    ) : (
                                        <div>
                                            <img width={120} src={NoDataFound} alt="" />
                                            <h6 className='pt-2'>{lang("No Data Found")}</h6>
                                        </div>
                                    )
                                }
                            </div>)}
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default BrandProducts;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Header from "../Components/header/Header";
import Footer from "../Components/footer/footer";
import chatIconTheme from '../Assets/img/icon/chatIconTheme.svg';
import Banners from "../Components/banners/Banners";
import { useNavigate, useParams } from "react-router";
import useRequest from "../hooks/useRequest";
import apiPath from "../constants/apiPath";
import { Button } from "antd";
import { Link } from "react-router-dom";
import { Severty, ShowToast } from "../helper/toast";
import lang from "../helper/lang";
import { useAppContext } from "../context/AppContext";
import useFirebase from "../hooks/useFirebase";

const QuotesDetailsPage = () => {
    const { id } = useParams();
    const { request } = useRequest();
    const [quote, setQuote] = useState();
    const [title, setTitle] = useState();
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const { addChat } = useAppContext()

    const getQuoteDetail = () => {
        setLoading(true);
        request({
            url: apiPath.getQuoteDetailsPage(id),
            method: "GET",
            onSuccess: (data) => {
                if (data.status) {
                    console.log(data);
                    setQuote(data.data);
                    setLoading(false);
                }
            },
            onError: (err) => {
                console.log(err);
                setLoading(false);
            }
        })
    }

    useEffect(() => {
        if (!quote) return

        const title = {
            name: `${quote?.name}`,
            quoteId: `${quote?.quoteId}`,
            type: 'quote',
            price: quote?.price
        }
        setTitle(title)

    }, [quote])

    useEffect(() => {
        getQuoteDetail();
    }, []);

    return (
        <>
            <div className="mainWraper">

                <div className="quotesDetail sectionPading">
                    <div className="container">
                        <div className="sectionHeading">
                            <div className="row align-items-center">
                                <div className="col">
                                    <div className="title d-inline-block">
                                        <div className="txt">Quote Detail</div>
                                        <div className="titleDesign d-flex align-items-center gap-2 mt-3"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="favTabs mt-2 position-relative">
                            <div className="row g-3">
                                <div className="col-xl-8">
                                    <div className="quoteItem bg-white p-3 h-100">
                                        <div className="quoteType text-capitalize mb-1">{quote?.quote_id?.part_type}</div>
                                        <div className="d-flex align-items-center justify-content-between py-2 ">
                                            <div className="quoteId mb-1">
                                                <div className="quoteValue"> #{quote?.quoteId}</div>
                                                <div className="quoteTitle">{lang("Quote ID")}</div>
                                            </div>
                                            <div className="quoteDetail mb-1 text-end">
                                                <div className="text-capitalize quoteValue">{quote?.make_id?.name} - {quote?.model_id?.name} ({quote?.vehicle_id?.year})</div>
                                                <div className="text-capitalize quoteTitle">{lang("Make - Model")} ({quote?.type_id?.name})</div>
                                            </div>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between pt-2 border-bottom pb-1">
                                            <div className="quoteId mb-1">
                                                <div className="text-capitalize quoteValue">{quote?.request_type}</div>
                                                <div className="quoteTitle">{lang("Request Type")}</div>
                                            </div>
                                            <div className="quoteDetail mb-1 text-end">
                                                <div className="text-capitalize quoteValue">{quote?.created_at?.split('T')[0]}</div>
                                                <div className="quoteTitle">{lang("Requested On")}</div>
                                            </div>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between pt-2">
                                            <div className="quoteDetail mb-1">
                                                <div className="text-capitalize quoteValue">{quote?.name}</div>
                                                <div className="quoteTitle">{lang("Request Name")}</div>
                                            </div>
                                            <div className="quoteId mb-1 text-end">
                                                <div className="quoteValue">{quote?.category_id?.name}</div>
                                                <div className="quoteTitle">{lang("Category")}</div>
                                            </div>
                                        </div>
                                        {quote?.request_type?.toLowerCase() === 'product' ? <div className="d-flex align-items-center justify-content-between pt-2">
                                            <div className="quoteDetail mb-1">
                                                <div className="text-capitalize quoteValue">{quote?.part_type}</div>
                                                <div className="quoteTitle">{lang("Part Type")}</div>
                                            </div>
                                            <div className="quoteDetail mb-1 text-end">
                                                <div className="quoteValue">{quote?.part_number}</div>
                                                <div className="quoteTitle">{lang("Part Number")}</div>
                                            </div>
                                        </div> : null}
                                        <div className="d-flex align-items-center justify-content-between pt-2 border-bottom mb-1">
                                            <div className="quoteDetail mb-1">
                                                <div className="text-capitalize quoteValue">{quote?.status}</div>
                                                <div className="quoteTitle">{lang("Quote Status")}</div>
                                            </div>
                                            <div className="quoteDetail mb-1 text-end">
                                                <div className="quoteValue">{quote?.price ? "R " + quote?.price : " - "}</div>
                                                <div className="quoteTitle">{lang("New Price")}</div>
                                            </div>
                                        </div>
                                        <div className="text-capitalize quoteDec mt-2">
                                            <div className="quoteTitle">{lang("Description : ")}</div>
                                            {quote?.description}</div>
                                    </div>
                                </div>
                                <div className='col-xl-4'>
                                    <div className='productDetail mt-3 bg-white p-3'>
                                        {quote?.request_type == 'Product'
                                            ? <ul className='text-capitalize p-0 m-0 list-unstyled d-flex flex-column gap-2'>
                                                <li className='row g-1'>
                                                    <div className='col-6 title'>{lang("Product Name")} :</div>
                                                    <div className='col-6 text-capitalize value'>{quote?.product_id?.name}</div>
                                                </li>
                                                <li className='row g-1'>
                                                    <div className='col-6 title'>{lang("Attributes")} :</div>
                                                    <div className='col-6 text-capitalize value'>{
                                                        quote?.product_id?.attribute?.map((attr) => {
                                                            return (
                                                                <li>({attr?.name + " - " + attr?.value})</li>
                                                            )
                                                        })
                                                    }</div>
                                                </li>
                                                <li className='row g-1'>
                                                    <div className='col-6 title'>{lang("Model")} :</div>
                                                    <div className='col-6 text-capitalize value'>{quote?.model_id?.name}</div>
                                                </li>
                                                <li className='row g-1'>
                                                    <div className='col-6 title'>{lang("Variant")} :</div>
                                                    <div className='col-6 text-capitalize value'>{quote?.variant_id?.name} ({quote?.vehicle_id?.year})</div>
                                                </li>
                                                <li className='row g-1'>
                                                    <div className='col-6 title'>{lang("Base Price")} :</div>
                                                    <div className='col-6 value'>R {quote?.product_id?.price}</div>
                                                </li>
                                            </ul>
                                            : <ul className='text-capitalize p-0 m-0 list-unstyled d-flex flex-column gap-2'>
                                                <li className='row g-1'>
                                                    <div className='col-6 title'>{lang("Service Name")} :</div>
                                                    <div className='col-6 text-capitalize value'>{quote?.service_id?.name}</div>
                                                </li>
                                                <li className='row g-1'>
                                                    <div className='col-6 title'>{lang("Model")} :</div>
                                                    <div className='col-6 text-capitalize value'>{quote?.model_id?.name}</div>
                                                </li>
                                                <li className='row g-1'>
                                                    <div className='col-6 title'>{lang("Variant")} :</div>
                                                    <div className='col-6 text-capitalize value'>{quote?.variant_id?.name} ({quote?.vehicle_id?.year})</div>
                                                </li>
                                                <li className='row g-1'>
                                                    <div className='col-6 title'>{lang("Base Price")} :</div>
                                                    <div className='col-6 value'>R {quote?.service_id?.price}</div>
                                                </li>
                                            </ul>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="otherSliderMain sectionPading bgLight">
                    <Banners />
                </div>

            </div>

        </>
    )
}

export default QuotesDetailsPage;

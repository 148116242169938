import react from "react";
import { Link } from "react-router-dom";
import lang from "../../helper/lang";
import YoutubeIcon from "../../Assets/img/youtube.png"
import TiktokIcon from "../../Assets/img/tik-tok.png"
import InstagramIcon from "../../Assets/img/instagram.png"
import FacebookIcon from "../../Assets/img/facebook.png"
import ThreadsIcon from "../../Assets/img/threads.png"
import TwitterIcon from "../../Assets/img/twitter.png"
const Footer = ({ setQuery}) => {


    return (
        <>
            <footer class="footerMain">
                <div class="bottomFooter">
                    <div class="container">
                        <div class="row gap-2 gap-lg-0">
                            <div class="col-lg">
                                <ul onClick={()=>setQuery("")} class="footerNav list-unstyled p-0 m-0 d-sm-flex align-items-center gap-lg-5 gap-3 justify-content-sm-center text-center justify-content-lg-start">
                                    <li class="navItem"><Link to="/about" class="navLink text-white text-decoration-none">{lang("About Us")}</Link></li>
                                    <li class="navItem"><Link to="/privacy-policy" class="navLink text-white text-decoration-none">{lang("Privacy Policy")}</Link></li>
                                    <li class="navItem"><Link to="/term-condition" class="navLink text-white text-decoration-none">{lang("Terms & Conditions")}</Link></li>
                                    <li class="navItem"><Link to="/faq" class="navLink text-white text-decoration-none">{lang("FAQs")}</Link></li>
                                </ul>
                            </div>
                            <div class="col-lg-auto text-center text-lg-start">
                                
                                <div class="copyRight text-white">© {lang("Pimpt-Up 2023, All Rights Reserved")}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="last-footer">
                     <div className="container">
                        <ul class="footer-bottam">
                            <li class="navItem"><Link to="https://www.youtube.com/channel/UCBri_d_tmFqDG6nt-lUbVGQ" target="_blank" className="bottam-icon"><img src={YoutubeIcon} /></Link></li>
                            <li class="navItem"><Link to="https://www.tiktok.com/@pimptup.com?_t=8kRvFKVIq0L&_r=1"  target="_blank" className="bottam-icon"> <img src={TiktokIcon} /></Link></li>
                            <li class="navItem"><Link to="https://www.instagram.com/pimpt_up?igsh=OTRnMGViY3k5ODZh"  target="_blank" className="bottam-icon"><img src={InstagramIcon} /> </Link></li>
                            <li class="navItem"><Link to="https://www.facebook.com/PimptUp?mibextid=qi2Omg" className="bottam-icon"  target="_blank"><img src={FacebookIcon} /> </Link></li>
                            <li class="navItem"><Link to="https://www.threads.net/@pimpt_up" target="_blank" className="bottam-icon"><img src={ThreadsIcon} /> </Link></li>
                            <li class="navItem"><Link to="@pimptup" className="bottam-icon" target="_blank"><img src={TwitterIcon} /> </Link></li> 
                        </ul>
                    </div>
                </div>
            </footer>

        </>
    )
}

export default Footer;
import React, { useContext } from "react";
import { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { AuthContext } from "../../context/AuthContext";
import { useNavigate } from "react-router";
import { Severty, ShowToast } from "../../helper/toast";
import lang from "../../helper/lang";
import useRequest from "../../hooks/useRequest";
import apiPath from "../../constants/apiPath";


const ConfirmLogoutModal = ({ onClose }) => {
    const { logout } = useContext(AuthContext);
    const navigate = useNavigate();
    const { request } = useRequest();

    return (
        // <div style={{border:"2px solid red", height:"200px", width:"200px"}}>Delete Confirmation Modal</div>
        <Modal className="loginModal" show={true} onHide={() => onClose("")} centered backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title className="fs-5 text-center fw-bold">{lang("Logout Account")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="pera">
                    {lang("Are you sure you want to Logout from your account")}?
                    <br /><br />
                    {lang("You need to login again for continue usage of web if you logout")}.
                </div>
                <div className="mt-3 mt-sm-4">
                    <button
                        type="button"
                        class="btn btnStyle1 w-100 shadow-none text-white"
                        onClick={() => {
                            request({
                                url: apiPath.logout,
                                method: "POST",
                                onSuccess: (data) => {
                                    if (!data.status) return;
                                    console.log(data);
                                },
                                onError: (err) => {
                                    console.log(err);
                                }
                            })

                            localStorage.removeItem('flag')
                            // localStorage.setItem('flag', 'false')
                            logout();
                            navigate("/");
                            onClose("");
                        }}
                    >
                        {lang("Logout")}
                    </button>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default ConfirmLogoutModal;
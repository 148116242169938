import React, { useEffect, useState } from "react";
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import shortIcon from '../Assets/img/icon/shortIcon.svg';
import Banners from "../Components/banners/Banners";
import ProductsCard from "../Components/products/ProductsCard";
import useRequest from "../hooks/useRequest";
import apiPath from "../constants/apiPath";
import ProvidersCard from "../Components/providers/ProvidersCard";
import Loader from "../Components/Loader";
import lang from "../helper/lang";
import NoDataFound from "../Assets/img/NotFound.png";
import ServicesCard from "../Components/services/ServicesCard";
import shortIcon1 from '../Assets/img/icon/shortIcon1.svg';
import searchIcon from '../Assets/img/icon/searchIcon.svg';

const Favourites = () => {

    const [products, setProducts] = useState([]);
    const [services, setServices] = useState([]);
    const [dealers, setDealers] = useState([]);
    const [specialists, setSpecialists] = useState([]);
    const [loading, setLoading] = useState(false);
    const { request } = useRequest();

    const getFavourites = () => {
        setLoading(true);
        request({
            url: apiPath.getWishList,
            method: "GET",
            onSuccess: ({ data, status }) => {
                if (status) {
                    setProducts(data.product);
                    setDealers(data.dealer);
                    setSpecialists(data.specialist);
                    setServices(data.service);
                }
                setLoading(false);
            },
            onError: (err) => {
                console.log(err);
                setLoading(false);
            }
        })
    }

    const handleSearch = (val) => {
        setLoading(true);
        request({
            url: apiPath.getWishList + `?text=${val}`,
            method: "GET",
            onSuccess: ({ data, status }) => {
                if (status) {
                    setProducts(data.product);
                    setDealers(data.dealer);
                    setSpecialists(data.specialist);
                    setServices(data.service);
                }
                setLoading(false);
            },
            onError: (err) => {
                console.log(err);
                setLoading(false);
            }
        })
    }

    useEffect(() => {
        getFavourites();
    }, []);

    return (
        <>
            <div className="mainWraper">

                <div className="otherSliderMain sectionPading bgLight">
                    <Banners />
                </div>

                <div className="productSection sectionPading">
                    <div className="container">
                        <div className="sectionHeading">
                            <div className="row align-items-center mb-2">
                                <div className="col">
                                    <div className="title d-inline-block">
                                        <div className="txt">{lang("Favourites")}</div>
                                        <div className="titleDesign d-flex align-items-center gap-2 mt-3"></div>
                                    </div>
                                </div>
                                <div className="col-sm-auto d-flex align-items-center gap-3">
                                    <InputGroup className="position-relative">
                                        <Form.Control type="search" id='headerSearch' onChange={(e) => handleSearch(e.target.value)} className="rounded-1 pe-5" placeholder={lang("Search") + "..."} />
                                        <label for="headerSearch" className="btn searchBtn d-flex align-items-cneter justify-content-center m-0 rounded-1 border-0 shadow-none position-absolute end-0 top-0 bottom-0 me-1 my-auto">
                                            <img className="w-100" src={searchIcon} alt="search" />
                                        </label>
                                    </InputGroup>
                                </div>
                            </div>
                        </div>
                        <div className="favTabs mt-2">
                            <Tabs
                                defaultActiveKey="products"
                                id="uncontrolled-tab-example"
                                className="mb-3"
                            >
                                <Tab eventKey="products" title={lang('Products')}>
                                    <div className="productsOuter mt-4">
                                        <div className="row g-3 row-cols-xxl-6">
                                            {loading
                                                ? (<Loader />)
                                                : (products?.length ? products?.map((prod) =>
                                                    <ProductsCard prod={prod?.product_id} favourite={true} setRefresh={getFavourites} />
                                                ) : <div>
                                                    <img width={120} src={NoDataFound} alt="" />
                                                    <h6 className='pt-2'>{lang("No Data Found")}</h6>
                                                </div>)
                                            }
                                        </div>
                                    </div>
                                </Tab>

                                <Tab eventKey="services" title={lang('Services')}>
                                    <div className="productsOuter mt-4">
                                        <div className="row g-3 row-cols-xs-2 row-cols-3 row-cols-md-4 row-cols-lg-5 pt-4 row-cols-xl-6 row-cols-xxxl-8">
                                            {loading
                                                ? (<Loader />)
                                                : (services?.length ? services?.map((sr) =>
                                                    <ServicesCard item={sr?.service_id} favourite={true} setRefresh={getFavourites} />
                                                ) : <div>
                                                    <img width={120} src={NoDataFound} alt="" />
                                                    <h6 className='pt-2'>{lang("No Data Found")}</h6>
                                                </div>)
                                            }
                                        </div>
                                    </div>
                                </Tab>

                                <Tab eventKey="dealers" title={lang("Dealers")}>
                                    <div className="dealerWraper sectionPading">
                                        <div className="container">
                                            <div className="dealerOuter">
                                                <div className="row g-3 row-cols-xs-2 row-cols-3 row-cols-md-4 row-cols-lg-5 row-cols-xl-6 row-cols-xxxl-8">
                                                    {dealers?.length ? dealers?.map((dl) => {
                                                        return (
                                                            <ProvidersCard pr={dl?.vendor_id} favourite={true} setRefresh={getFavourites} />
                                                        )
                                                    }) : <div>
                                                        <img width={120} src={NoDataFound} alt="" />
                                                        <h6 className='pt-2'>{lang("No Data Found")}</h6>
                                                    </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Tab>

                                <Tab eventKey="specialists" title={lang("Specialists")}>
                                    <div className="specialistWraper sectionPading">
                                        <div className="container">
                                            <div className="specialistOuter">
                                                <div className="row g-3 row-cols-xs-2 row-cols-3 row-cols-md-4 row-cols-lg-5 row-cols-xl-6 row-cols-xxxl-8">
                                                    {specialists?.length ? specialists?.map((sp) => {
                                                        return (
                                                            <ProvidersCard pr={sp?.vendor_id} favourite={true} setRefresh={getFavourites} />
                                                        )
                                                    }) : <div>
                                                        <img width={120} src={NoDataFound} alt="" />
                                                        <h6 className='pt-2'>{lang("No Data Found")}</h6>
                                                    </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Tab>
                            </Tabs>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Favourites;

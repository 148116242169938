import { useEffect, useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import Button from 'react-bootstrap/Button';
import Collapse from 'react-bootstrap/Collapse';
import Header from "../Components/header/Header";
import Footer from "../Components/footer/footer";
import dotsIcon from '../Assets/img/icon/dotsIcon.svg';
import arrowDown from '../Assets/img/icon/arrowDown.svg';
import deleteDangerIcon from '../Assets/img/icon/deleteDangerIcon.svg';
import Banners from '../Components/banners/Banners';
import useRequest from '../hooks/useRequest';
import apiPath from "../constants/apiPath";
import NotificationsCard from '../Components/notifications/NotificationsCard';
import { Severty, ShowToast } from '../helper/toast';
import Loader from '../Components/Loader';
import lang from '../helper/lang';
import NoDataFound from "../Assets/img/NotFound.png";


const Notification = () => {

    const [loading, setLoading] = useState(false);
    const [notifications, setNotifications] = useState([]);
    const { request } = useRequest();

    const getNotifications = () => {
        setLoading(true);
        request({
            url: apiPath.getNotifications,
            method: "GET",
            onSuccess: (data) => {
                if (data.status) {
                    setNotifications(data.data);
                    setLoading(false);
                }
            },
            onError: (err) => {
                console.log(err);
                setLoading(false);
            }
        })
    }

    const markAllRead = () => {
        request({
            url: apiPath.markAllRead,
            method: "GET",
            onSuccess: (data) => {
                if (data.status) {
                    console.log(data);
                    getNotifications();
                   // ShowToast(data.message, Severty.SUCCESS);
                }
            },
            onError: (err) => {
                console.log(err);
            }
        })
    }

    const markAllDelete = () => {
        setLoading(true);
        request({
            url: apiPath.markAllDelete,
            method: "GET",
            onSuccess: (data) => {
                if (data.status) {
                    console.log(data);
                    getNotifications();
                   // ShowToast(data.message, Severty.ERROR);
                }
                setLoading(false);
            },
            onError: (err) => {
                console.log(err);
                setLoading(false);
            }
        })
    }

    useEffect(() => {
        getNotifications();
    }, []);

    return (
        <>
            <div className="mainWraper">
                <div className="otherSliderMain sectionPading bgLight">
                    <Banners />
                </div>

                <div className="sectionPading">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-xl-8">
                                <div className="sectionHeading">
                                    <div className="row align-items-center">
                                        <div className="col">
                                            <div className="title d-inline-block">
                                                <div className="txt">{lang("Notifications")}</div>
                                                <div className="titleDesign d-flex align-items-center gap-2 mt-3"></div>
                                            </div>
                                        </div>
                                        <div className="col-auto d-flex align-items-center gap-1">
                                            {notifications.length ? (<div class="buttonGroup d-none d-lg-flex align-items-center gap-sm-4 gap-3">
                                                <button onClick={() => markAllRead()} type="button" class="btn btnStyle1 btnOutline d-flex align-items-center justify-content-center gap-3 shadow-none px-4">{lang("Mark as Read All")}</button>
                                                <button onClick={() => markAllDelete()} type="button" class="btn btnStyle1 dangerBtn d-flex align-items-center justify-content-center gap-3 shadow-none text-white px-4">{lang("Delete All")}</button>
                                            </div>) : ("")}
                                            <Dropdown className="filterDrop d-lg-none" align="end">
                                                <Dropdown.Toggle id="dropdown-basic" className="p0 border-0 shadow-none bg-transparent">
                                                    <img className="" src={dotsIcon} alt='' />
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu className="border-0 p-0">
                                                    <Dropdown.Item className="border-bottom" href="#/action-1">{lang("Mark as Read All")}</Dropdown.Item>
                                                    <Dropdown.Item className="border-bottom" href="#/action-2">{lang("Delete All")}</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </div>
                                </div>

                                <div className="notificationMainOuter mt-2">
                                    <div className="notificationInner d-flex flex-column gap-3">
                                        {loading
                                            ? (<Loader />)
                                            : (notifications?.length ? (notifications?.map((notify) => {
                                                return <NotificationsCard notify={notify} setRefresh={getNotifications} />
                                            })) : (<div>
                                                <img width={120} src={NoDataFound} alt="" />
                                                <h6 className='pt-2'>{lang("No Data Found")}</h6>
                                            </div>))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Notification;

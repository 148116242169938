import { useEffect, useState } from "react";
import OwlCarousel from 'react-owl-carousel3';
import Category1 from '../Assets/img/category1.png';
import useRequest from "../hooks/useRequest";
import apiPath from "../constants/apiPath";
import { Link, useParams } from "react-router-dom";
import Banners from "../Components/banners/Banners";
import ServicesCard from "../Components/services/ServicesCard";
import { categorySlider } from "../constants/sliders";
import Dropdown from 'react-bootstrap/Dropdown';
import shortingIcon from '../Assets/img/icon/shortingIcon.svg';
import lang from "../helper/lang";
import { useAppContext } from "../context/AppContext";
import NoDataFound from "../Assets/img/NotFound.png";
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import searchIcon from '../Assets/img/icon/searchIcon.svg';


const Service_categories = () => {

    const [categories, setCategories] = useState([]);
    const [service, setService] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedCtg, setSelectedCtg] = useState({});
    const { request } = useRequest();
    const { id } = useParams();
    const { language } = useAppContext();

    const getCategoryItems = (id) => {
        setLoading(true);
        request({
            url: apiPath.getCategoryItem + "/" + id,
            method: "GET",
            onSuccess: (data) => {
                if (data.status) {
                    setService(data.data)
                }
                setLoading(false);
            },
            onError: (err) => {
                setLoading(false);
                console.log("something went wrong!", err);
            }
        });
        const sc = categories?.filter((elem) => {
            return id === elem?._id;
        });
        setSelectedCtg(sc[0]);
    }

    const getServices = () => {
        request({
            url: apiPath.categoryList + `?type=service`,
            method: "GET",
            onSuccess: (data) => {
                setCategories(data.data);
                const sc = data.data?.filter((elem, idx) => {
                    return id === elem?._id;
                });
                setSelectedCtg(sc[0]);
            },
            onError: (err) => {
                console.log(err);
            }
        })
    }

    const handleSort = (val) => {
        setLoading(true);
        request({
            url: apiPath.getCategoryItem + "/" + id + `?sort=${val}`,
            method: "GET",
            onSuccess: (data) => {
                if (data.status) {
                    setService(data.data)
                }
                setLoading(false);
            },
            onError: (err) => {
                setLoading(false);
                console.log("something went wrong!", err);
            }
        });
        const sc = categories?.filter((elem) => {
            return id === elem?._id;
        });
        setSelectedCtg(sc[0]);
    }

    const handleSearch = (val) => {
        request({
            url: apiPath.getCategoryItem + "/" + id + "?name=" + val,
            method: "GET",
            onSuccess: (data) => {
                if (data.status) {
                    setService(data.data);
                }
            },
            onError: (err) => {
                console.log(err);
            }
        })
    }

    useEffect(() => {
        getServices();
    }, []);

    useEffect(() => {
        getCategoryItems(id);
    }, [id]);


    return (
        <>
            <div className="mainWraper">
                <div className="categorySection sectionPading">
                    <div className="container">
                        <div className="sectionHeading">
                            <div className="row align-items-center">
                                <div className="col">
                                    <div className="title d-inline-block">
                                        <div className="txt">{lang("Service Categories")} <span className="ms-2 me-2">-</span> <span className="selectedCategory text-capitalize">{selectedCtg?.name}</span></div>
                                        <div className="titleDesign d-flex align-items-center gap-2 mt-3"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {categories.length ? (<div className="categoriesOuter mt-2">
                            <OwlCarousel {...categorySlider} >
                                {categories?.map((ctg, idx) => {
                                    return (
                                        <Link to={`/service-categories/${ctg?._id}`} className={`${id === ctg?._id && 'active'} categoriesItem text-center d-block text-decoration-none`}>
                                            <div className="categoriesImg rounded-circle mx-auto position-relative">
                                                <img className="w-100 h-100" src={ctg?.image || Category1} alt="" />
                                            </div>
                                            <div className="categoriesName text-capitalize mt-3">{language !== ('en' || null) ? (ctg[`${language}_name`] ?? ctg?.name) : ctg?.name}</div>
                                            <div className="totalItem">{ctg?.serviceCount > 1 ? ctg?.serviceCount + ' Items' : ctg?.serviceCount + ' Item'}</div>
                                        </Link>
                                    )
                                })}
                            </OwlCarousel>
                        </div>) : <div>
                            <img width={120} src={NoDataFound} alt="" />
                            <h6 className='pt-2'>{lang("No Data Found")}</h6>
                        </div>}
                    </div>
                </div>

                <div className="otherSliderMain sectionPading bgLight">
                    <Banners />
                </div>

                <div className="categoryServiceListing sectionPading">
                    <div className="container">
                        <div className="sectionHeading">
                            <div className="row g-3 align-items-center">
                                <div className="col">
                                    <div className="title d-inline-block">
                                        <div className="txt">{lang("Services")}</div>
                                        <div className="titleDesign d-flex align-items-center gap-2 mt-3"></div>
                                    </div>
                                </div>
                                <div className="col-sm-auto d-flex align-items-center gap-3">
                                    <InputGroup className="position-relative">
                                        <Form.Control type="search" id='headerSearch' onChange={(e) => handleSearch(e.target.value)} className="rounded-1 pe-5" placeholder={lang("Search")} />
                                        <label for="headerSearch" className="btn searchBtn d-flex align-items-cneter justify-content-center m-0 rounded-1 border-0 shadow-none position-absolute end-0 top-0 bottom-0 me-1 my-auto">
                                            <img className="w-100" src={searchIcon} alt="search" />
                                        </label>
                                    </InputGroup>
                                    <Dropdown className="filterDrop shortBy">
                                        <Dropdown.Toggle id="dropdown-basic" className="p0 searchBtn d-flex align-items-cneter justify-content-center border-0 shadow-none">
                                            <img className="" src={shortingIcon} alt="" style={{ filter: "invert(1)" }} />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu className="border-0 p-0">
                                            <Dropdown.Item className="border-bottom" onClick={() => handleSort('A-Z')} href="#sort=A-Z">A {lang("to")} Z</Dropdown.Item>
                                            <Dropdown.Item className="border-bottom" onClick={() => handleSort('Z-A')} href="#sort=Z-A">Z {lang("to")} A</Dropdown.Item>
                                            <Dropdown.Item className="border-bottom" onClick={() => handleSort('price_low')} href="#sort=Low-High">Price - Low to High</Dropdown.Item>
                                            <Dropdown.Item className="border-bottom" onClick={() => handleSort('price_high')} href="#sort=High-Low">Price - High to Low</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </div>
                        </div>
                        <div className="row g-3 row-cols-xs-2 row-cols-3 row-cols-md-4 row-cols-lg-5 pt-2 row-cols-xl-6 row-cols-xxxl-8">
                            {service?.length
                                ? (service?.map((item, idx) => {
                                    return (
                                        <ServicesCard item={item} />
                                    )
                                })
                                ) : <div>
                                    <img width={120} src={NoDataFound} alt="" />
                                    <h6 className='pt-2'>{lang("No Data Found")}</h6>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Service_categories;

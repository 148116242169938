import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import useRequest from "../../hooks/useRequest";
import apiPath from "../../constants/apiPath";
import Specialist1 from '../../Assets/img/specialistImg1.png';
import chatIcon from '../../Assets/img/icon/chatIcon.svg';
import { AuthContext } from "../../context/AuthContext";
import { AppStateContext } from "../../context/AppContext";
import lang from "../../helper/lang";


const ProvidersCard2 = ({ pr, price, title }) => {

    const { request } = useRequest();
    const [fav, setFav] = useState(pr?.is_wishlist);
    const { setShowDialouge, language, addChat } = useContext(AppStateContext)
    const { isLoggedIn } = useContext(AuthContext)
    const navigate = useNavigate();

    const addFavourite = (id) => {
        isLoggedIn
            ? request({
                url: apiPath.addWishList,
                method: "POST",
                data: { vendor_id: id, type: pr?.type },
                onSuccess: (data) => {
                    if (data.status) {
                        setFav(x => !x);
                        console.log(data);
                    }
                },
                onError: (err) => {
                    console.log(err);
                }
            })
            : (setShowDialouge({ type: 'login' }))
    }

    const handleChat = async () => {
        if (isLoggedIn) {
            await addChat(pr, { ...title, price })
            navigate('/chat')
        }
        else {
            setShowDialouge({ type: 'login' })
        }
    }

    return (
        <div className="col cols">
            <div className="specialistItem bg-white d-flex flex-column align-items-center gap-2 position-relative">
                <Link to={`/provider-profile/${pr?._id}`} className="stretched-link"></Link>
                <div className="imgOuter rounded-circle overflow-hidden">
                    <img alt="" className="h-100 w-100" src={pr?.image ? apiPath.getImage + pr?.image : Specialist1} />
                </div>
                <div onClick={() => addFavourite(pr?._id)} className={`favbtn ${isLoggedIn && fav && "favouriteItem"} position-absolute top-0 end-0 mt-2 me-2 z-3`}>
                    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.517 17.836C10.2337 17.936 9.76699 17.936 9.48366 17.836C7.06699 17.011 1.66699 13.5693 1.66699 7.73597C1.66699 5.16097 3.74199 3.07764 6.30033 3.07764C7.81699 3.07764 9.15866 3.81097 10.0003 4.9443C10.842 3.81097 12.192 3.07764 13.7003 3.07764C16.2587 3.07764 18.3337 5.16097 18.3337 7.73597C18.3337 13.5693 12.9337 17.011 10.517 17.836Z" stroke="#121212" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                </div>
                <div className="specialistDetail text-center">
                    <div className="text-capitalize name">{language !== ('en' || null) ? (pr[`${language}_name`] ?? pr?.name) : pr?.name}</div>
                    <div className="serviceCharge mt-1 mb-2">R {pr?.price ?? price}</div>
                    <Link to={isLoggedIn && "/chat"} onClick={handleChat} class="btn btnStyle1 d-flex align-items-center justify-content-center gap-2 shadow-none text-white px-4 position-relative z-3">{lang("Chat")}<img alt="" className="" src={chatIcon} /></Link>
                </div>
            </div>
        </div>
    )
}

export default ProvidersCard2;

import { useEffect, useState } from "react";
import Category1 from '../Assets/img/category1.png';
import Header from "../Components/header/Header";
import Footer from "../Components/footer/footer";
import useRequest from "../hooks/useRequest";
import apiPath from "../constants/apiPath";
import { Link } from "react-router-dom";
import Banners from "../Components/banners/Banners";
import Loader from "../Components/Loader";
import Dropdown from 'react-bootstrap/Dropdown';
import shortIcon from '../Assets/img/icon/shortIcon.svg';
import shortIcon1 from '../Assets/img/icon/shortIcon1.svg';
import lang from "../helper/lang";
import { useAppContext } from "../context/AppContext";
import NoDataFound from "../Assets/img/NotFound.png";
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import searchIcon from '../Assets/img/icon/searchIcon.svg';
import OwlCarousel from 'react-owl-carousel3';
import { brandsSlider } from "../constants/sliders";


const BrandList = () => {

    const [loading, setLoading] = useState(false);
    const [toggle, setToggle] = useState(false);
    const [query, setQuery] = useState();
    const { language } = useAppContext();
    const [brands, setBrands] = useState([]);
    const { request } = useRequest();


    const getBrands = () => {
        setLoading(true);
        request({
            url: `${apiPath.brandList}${query ? `?text=${query}` : ''}`,
            method: "GET",
            onSuccess: (data) => {
                if (data.status) {
                    setBrands(data.data);
                }
                setLoading(false);
            },
            onError: (err) => {
                console.log(err);
                setLoading(false);
            },
        });
    };

    const handleSort = (val) => {
        if (val === 'Z-A') {
            setToggle(true);
        } else {
            setToggle(false);
        }
        setLoading(true);
        request({
            url: apiPath.brandList + `?sort=${val}`,
            method: "GET",
            onSuccess: (data) => {
                if (data.status) {
                    setBrands(data.data);
                }
                setLoading(false);
            },
            onError: (err) => {
                setLoading(false);
                console.log(err);
            }
        })
    }

    useEffect(() => {
        getBrands();
    }, [query]);

    return (
        <>
            <div className="mainWraper">
                <div className="otherSliderMain sectionPading bgLight">
                    <Banners />
                </div>

                <div className="categoryWraper sectionPading">
                    <div className="container">
                        <div className="sectionHeading">
                            <div className="row g-3 align-items-center">
                                <div className="col">
                                    <div className="title d-inline-block">
                                        <div className="txt">{lang("Brands")}</div>
                                        <div className="titleDesign d-flex align-items-center gap-2 mt-3"></div>
                                    </div>
                                </div>
                                <div className="col-sm-auto d-flex align-items-center gap-3">
                                    <InputGroup className="position-relative">
                                        <Form.Control type="search" id='headerSearch2' onChange={(e) => setQuery(e.target.value)} className="rounded-1 pe-5" placeholder={lang("Search") + "..."} />
                                        <label for="headerSearch" className="btn searchBtn d-flex align-items-cneter justify-content-center m-0 rounded-1 border-0 shadow-none position-absolute end-0 top-0 bottom-0 me-1 my-auto">
                                            <img className="w-100" src={searchIcon} alt="search" />
                                        </label>
                                    </InputGroup>
                                    <Dropdown className="filterDrop shortBy">
                                        <Dropdown.Toggle id="dropdown-basic" className="p0 border-0 shadow-none bg-transparent">
                                            <img className="" src={toggle ? shortIcon1 : shortIcon} alt="" />
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu className="border-0 p-0">
                                            <Dropdown.Item className="border-bottom" onClick={() => handleSort('A-Z')} href="#sort=A-Z">A {lang("to")} Z</Dropdown.Item>
                                            <Dropdown.Item className="border-bottom" onClick={() => handleSort('Z-A')} href="#sort=Z-A">Z {lang("to")} A</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </div>
                        </div>
                        <div className="brandsOuter mt-2">
                            {loading
                                ? (<Loader />)
                                : brands.length
                                    ? (<OwlCarousel {...brandsSlider} >
                                        {brands?.map((brand) => {
                                            return (
                                                <Link to={`/brand-products/${brand?._id}`} className="brandsItem text-center d-block text-decoration-none rounded-3 border overflow-hidden">
                                                    <img className="h-100 w-auto mx-auto" src={brand.image || "https://pimptup.com/Files/Products/3367images%20(4).jpeg"} />
                                                    <div className="mt-3 text-uppercase">{language !== ('en' || null) ? (brand[`${language}_name`] ?? brand?.name) : brand?.name}</div>
                                                    <div className="totalItem">{brand?.productCount > 1 ? brand?.productCount + ' Items' : brand?.productCount + ' Item'} </div>
                                                </Link>
                                            );
                                        })}
                                    </OwlCarousel>)
                                    : <div>
                                        <img width={120} src={NoDataFound} alt="" />
                                        <h6 className='pt-2'>{lang("No Data Found")}</h6>
                                    </div>
                            }
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default BrandList;

import Leftarrow from '../Assets/img/icon/saliderArrowLeft.png';
import Rightarrow from '../Assets/img/icon/saliderArrowRight.png';

export const categorySlider = {
    margin: 12,
    nav: true,
    dots: false,
    autoplay: true,
    autoplayTimeout: 2500,
    autoplayHoverPause: false,
    navText: [`<img src=${Leftarrow}>`, `<img src=${Rightarrow}>`],
    responsive: {
        0: {
            items: 3,
        },
        421: {
            items: 4,
        },
        768: {
            items: 6,
        },
        992: {
            items: 7,
        },
        1200: {
            items: 8,
        },
        1600: {
            items: 10,
        },
    },
};

export const specialistsSlider = {
    margin: 10,
    loop: false,
    nav: true,
    dots: false,
    autoplay: true,
    autoplayTimeout: 2500,
    autoplayHoverPause: false,
    navText: [`<img src=${Leftarrow}>`, `<img src=${Rightarrow}>`],
    responsive: {
        0: {
            items: 1.25,
        },
        361: {
            items: 1.5,
        },
        421: {
            items: 2,
        },
        768: {
            items: 3,
        },
        992: {
            items: 4,
        },
        1200: {
            items: 4,
        },
        1600: {
            items: 5,
        },
    },
};

export const brandsSlider = {
    margin: 10,
    loop: false,
    nav: true,
    dots: false,
    navText: [`<img src=${Leftarrow}>`, `<img src=${Rightarrow}>`],
    responsive: {
        0: {
            items: 2
        },
        421: {
            items: 3
        },
        768: {
            items: 4
        },
        992: {
            items: 5
        },
        1200: {
            items: 6
        },
        1600: {
            items: 8
        }
    }
}

export const otherSlider = {
    margin: 10,
    loop: true,
    nav: false,
    dots: false,
    autoplay: true,
    autoplayTimeout: 3000,
    autoplayHoverPause: false,
    navText: [`<img src=${Leftarrow}>`, `<img src=${Rightarrow}>`],
    responsive: {
        0: {
            items: 1,
            stagePadding: 50
        },
        576: {
            items: 1,
            stagePadding: 100
        },
        768: {
            items: 1,
            stagePadding: 150
        },
        992: {
            items: 2,
            stagePadding: 80
        },
        1200: {
            items: 3,
            stagePadding: 100
        }
    }
}

export const ProductDetailSlider = {
    margin: 0,
    loop: true,
    nav: true,
    dots: false,
    autoplay: true,
    autoplayTimeout: 2000,
    autoplayHoverPause: false,
    navText: [`<img alt=""src=${Leftarrow}>`, `<img alt=""src=${Rightarrow}>`],
    items: 1,
    0: {
        items: 1
    },
    768: {
    }
}

import { useEffect, useState } from "react";
import Category1 from '../Assets/img/category1.png';
import useRequest from "../hooks/useRequest";
import apiPath from "../constants/apiPath";
import { Link } from "react-router-dom";
import Banners from "../Components/banners/Banners";
import Loader from "../Components/Loader";
import Dropdown from 'react-bootstrap/Dropdown';
import shortingIcon from '../Assets/img/icon/shortingIcon.svg';
import lang from "../helper/lang";
import { useAppContext } from "../context/AppContext";
import NoDataFound from "../Assets/img/NotFound.png";
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import searchIcon from '../Assets/img/icon/searchIcon.svg';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';


const Category = () => {

    const [loading, setLoading] = useState(false);
    const [toggle, setToggle] = useState(false);
    const [productCtg, setProductCtg] = useState([]);
    const [serviceCtg, setServiceCtg] = useState([]);
    const [query, setQuery] = useState();
    const { request } = useRequest();
    const { language } = useAppContext();


    useEffect(() => {
        getCategories(query);
    }, [query]);

    const getCategories = (query) => {
        !query && setLoading(true);

        request({
            url: `${apiPath.categoryList}${query ? `?text=${query}` : ''}`,
            method: "GET",
            onSuccess: (data) => {
                if (data.status) {
                    const product = data.data?.filter((itm) => {
                        return itm.type === 'product';
                    })
                    const service = data.data?.filter((itm) => {
                        return itm.type === 'service';
                    })
                    setProductCtg(product);
                    setServiceCtg(service);
                }
                setLoading(false);
            },
            onError: (err) => {
                setLoading(false);
                console.log(err);
            }
        })
    }

    const handleSort = (val) => {
        setLoading(true);
        request({
            url: apiPath.categoryList + `?sort=${val}`,
            method: "GET",
            onSuccess: (data) => {
                if (data.status) {
                    const product = data.data?.filter((itm) => {
                        return itm.type === 'product';
                    })
                    const service = data.data?.filter((itm) => {
                        return itm.type === 'service';
                    })
                    setProductCtg(product);
                    setServiceCtg(service);
                }
                setLoading(false);
            },
            onError: (err) => {
                setLoading(false);
                console.log(err);
            }
        })
    }


    return (
        <>
            <div className="mainWraper">
                <div className="otherSliderMain sectionPading bgLight">
                    <Banners />
                </div>

                <div className="categoryWraper sectionPading">
                    <div className="container">
                        <div className="sectionHeading">
                            <div className="row g-3 align-items-center">
                                <div className="col">
                                    <div className="title d-inline-block">
                                        <div className="txt">{lang("Categories")} ({productCtg?.length + serviceCtg?.length})</div>
                                        <div className="titleDesign d-flex align-items-center gap-2 mt-3"></div>
                                    </div>
                                </div>
                                <div className="col-sm-auto d-flex align-items-center gap-3">
                                    <InputGroup className="position-relative">
                                        <Form.Control type="search" id='headerSearch' onChange={(e) => getCategories(e.target.value)} className="rounded-1 pe-5" placeholder={lang("Search") + "..."} />
                                        <label for="headerSearch" className="btn searchBtn d-flex align-items-cneter justify-content-center m-0 rounded-1 border-0 shadow-none position-absolute end-0 top-0 bottom-0 me-1 my-auto">
                                            <img className="w-100" src={searchIcon} alt="search" />
                                        </label>
                                    </InputGroup>
                                    <Dropdown className="filterDrop shortBy">
                                        <Dropdown.Toggle id="dropdown-basic" className="p0 searchBtn d-flex align-items-cneter justify-content-center border-0 shadow-none">
                                            <img className="" src={shortingIcon} alt="" style={{ filter: "invert(1)" }} />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu className="border-0 p-0">
                                            <Dropdown.Item className="border-bottom" onClick={() => handleSort('A-Z')} href="#sort=A-Z">A {lang("to")} Z</Dropdown.Item>
                                            <Dropdown.Item className="border-bottom" onClick={() => handleSort('Z-A')} href="#sort=Z-A">Z {lang("to")} A</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </div>
                        </div>
                        <div className="favTabs mt-2">
                            <Tabs
                                defaultActiveKey="products"
                                id="uncontrolled-tab-example"
                                className="mb-3"
                            >
                                <Tab eventKey="products" title={`${lang('Products')} (${productCtg?.length})`}>
                                    <div className="row g-3 row-cols-xs-2 row-cols-3 row-cols-md-4 row-cols-lg-5 row-cols-xl-6 row-cols-xxl-8 row-cols-xxxl-10">
                                        {loading
                                            ? (<Loader />)
                                            : (productCtg?.length ? (productCtg?.map((category, idx) => {
                                                return (
                                                    <div className="col cols">
                                                        <Link to={`/product-listing/${category?._id}`} className="categoriesItem text-center d-block text-decoration-none">
                                                            <div className="categoriesImg rounded-circle overflow-hidden mx-auto">
                                                                <img className="w-100 h-100" src={category?.image || Category1} alt="" />
                                                            </div>
                                                            <div className="categoriesName mt-3 text-uppercase">{language !== ('en' || null) ? (category[`${language}_name`] ?? category?.name) : category?.name}</div>
                                                            <div className="totalItem">{category?.productCount > 1 ? category?.productCount + ' Items' : category?.productCount + ' Item'} </div>
                                                        </Link>
                                                    </div>
                                                )
                                            })
                                            ) : (
                                                <div>
                                                    <img width={120} src={NoDataFound} alt="" />
                                                    <h6 className='pt-2'>{lang("No Data Found")}</h6>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </Tab>

                                <Tab eventKey="services" title={`${lang('Services')} (${serviceCtg?.length})`}>
                                    <div className="row g-3 row-cols-xs-2 row-cols-3 row-cols-md-4 row-cols-lg-5 row-cols-xl-6 row-cols-xxl-8 row-cols-xxxl-10">
                                        {loading
                                            ? (<Loader />)
                                            : (serviceCtg?.length ? (serviceCtg?.map((category, idx) => {
                                                return (
                                                    <div className="col cols">
                                                        <Link to={`/service-categories/${category?._id}`} className="categoriesItem text-center d-block text-decoration-none">
                                                            <div className="categoriesImg rounded-circle overflow-hidden mx-auto">
                                                                <img className="w-100 h-100" src={category?.image || Category1} alt="" />
                                                            </div>
                                                            <div className="categoriesName mt-3 text-uppercase">{language !== ('en' || null) ? (category[`${language}_name`] ?? category?.name) : category?.name}</div>
                                                            <div className="totalItem">{category?.serviceCount > 1 ? category?.serviceCount + ' Items' : category?.serviceCount + ' Item'} </div>
                                                        </Link>
                                                    </div>
                                                )
                                            })
                                            ) : (
                                                <div>
                                                    <img width={120} src={NoDataFound} alt="" />
                                                    <h6 className='pt-2'>{lang("No Data Found")}</h6>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </Tab>
                            </Tabs>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Category;

import React, { useEffect, useState } from "react";
import useRequest from "../hooks/useRequest";
import Banners from "../Components/banners/Banners";
import apiPath from "../constants/apiPath";
import lang from "../helper/lang";


const Terms_condition = () => {
    const { request } = useRequest();
    const [description, setDescription] = useState();

    useEffect(() => {
        request({
            url: apiPath.getContentList + "terms-and-conditions-customer",
            method: "GET",
            onSuccess: (data) => {
                if (data.status) {
                    if (data.data?.is_active) {
                        setDescription(data.data?.description)
                    } else {
                        setDescription("No Content Found!");
                    }
                } else {
                    setDescription("Something Went Wrong!!");
                }
            },
            onError: (err) => {
                console.log(err);
            }
        })
    }, [])

    return (
        <>
            <div className="mainWraper">
                <div className="pageImgOuter">
                    <img className="w-100 h-100" src="https://www.gaadizo.com/gaadizo-car-service-repairs/assets/media/components/b-main-slider/dent%20repairs.jpg" />
                    <div className="pageTitle position-absolute text-white text-uppercase">{lang("Terms & Conditions")}</div>
                </div>
                <div className="contentWrapper sectionPading">
                    <div className="container">
                        <div className="sectionHeading">
                            <div className="row align-items-center">
                                <div className="col">
                                    <div className="title d-inline-block">
                                        <div className="txt">{lang("Terms & Conditions")} {lang("of")} Pimpt Up</div>
                                        <div className="titleDesign d-flex align-items-center gap-2 mt-3"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="aboutOuter mt-2">
                            <div className="pera">
                                <div className='text-capitalize' dangerouslySetInnerHTML={{ __html: description }} />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="otherSliderMain sectionPading bgLight">
                    <Banners />
                </div>

            </div>

        </>
    )
}

export default Terms_condition;

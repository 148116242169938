import React, { useContext, useEffect, useState } from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import editDarkIcon from '../Assets/img/icon/editDarkIcon.svg';
import { AuthContext } from "../context/AuthContext";
import PhoneInput from "react-phone-input-2";
import { Formik, ErrorMessage } from "formik";
import * as Yup from 'yup';
import apiPath from "../constants/apiPath"
import useRequest from "../hooks/useRequest";
import { Severty, ShowToast } from "../helper/toast";
import { useNavigate } from "react-router";
import Banners from "../Components/banners/Banners";
import lang from "../helper/lang";
import { Link } from "react-router-dom";


const Edit_profile = () => {

    const { userProfile, setUserProfile } = useContext(AuthContext);
    const [image, setImage] = useState();
    const { request } = useRequest();
    const navigate = useNavigate();

    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Please Enter name').matches(/^[a-zA-Z\s]+$/, 'Please Enter Alphabets only'),
        email: Yup.string().email("Invalid Email address").required('Please Enter new password')
    });

    const handleImageChange = (event, setFieldValue) => {
        const file = event.target.files[0];
        if (!file) return
        const FileType = ['image/png', 'image/jpeg', 'image/jpg']
        if (!FileType.includes(file.type)) {
            ShowToast('file type is not correct', Severty.ERROR)
            // setUploading(false)
            return
        }
        const data = new FormData()
        data.append('image', file)
        data.append('type', "Customer")
        request({
            url: apiPath.imageUpload,
            method: "POST",
            data: data,
            onSuccess: (data) => {
                console.log(data);
                setFieldValue("image", data.data.upload)
                setImage(data.data.upload);
            },
            onError: (err) => {
                console.log(err);
            }
        })
    }

    // const isValid = .test(input);

    const getData = () => {
        request({
            url: apiPath.profile,
            method: "GET",
            onSuccess: (data) => {
                if (data.status) {
                    setUserProfile(data.data);
                }
            }
        })
    }

    useEffect(() => {
        getData();
    }, [])

    return (
        <>
            <div className="mainWraper">
                <div className="profileMainWrapper sectionPading">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-xl-7">
                                <div className="sectionHeading">
                                    <div className="row align-items-center">
                                        <div className="col">
                                            <div className="title d-inline-block">
                                                <div className="txt">{lang("Edit Profile")}</div>
                                                <div className="titleDesign d-flex align-items-center gap-2 mt-3"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="editProfileMainOuter mt-2 rounded-3 p-sm-5 p-4 bg-white">
                                    <Formik
                                        initialValues={{
                                            name: userProfile?.name,
                                            email: userProfile?.email,
                                            dob: userProfile?.dob,
                                            image: userProfile?.image,
                                            language: "En",
                                            notification: true
                                        }}
                                        onSubmit={(values) => {

                                            request({
                                                url: apiPath.updateProfile,
                                                method: "POST",
                                                data: { ...values },
                                                onSuccess: (data) => {
                                                    if (data.status) {
                                                        setUserProfile(prev => {
                                                            return {
                                                                ...prev,
                                                                ...values
                                                            }
                                                        })
                                                        ShowToast(data.message, Severty.SUCCESS);
                                                        localStorage.setItem("userProfile", JSON.stringify({ ...userProfile, ...values }))
                                                        navigate("/profile")

                                                    } else {
                                                        ShowToast(data.message, Severty.ERROR);
                                                    }
                                                },
                                                onError: (err) => {
                                                    ShowToast(err.message, Severty.ERROR);
                                                }
                                            })
                                        }}
                                        validationSchema={validationSchema}
                                    >
                                        {({ values, setFieldValue, handleSubmit, handleChange }) => (
                                            <Form className="loginSignUpForm" onSubmit={handleSubmit}>
                                                <Row className="g-3">
                                                    <Form.Group as={Col} md="12" controlId="uploadProfile">
                                                        <div className="profileChange position-relative">
                                                            <Form.Control
                                                                type="file"
                                                                placeholder={lang("Enter Name")}
                                                                className="d-none"
                                                                name="image"
                                                                onChange={(e) => handleImageChange(e, setFieldValue)}
                                                            />
                                                            <Form.Label className="uploadProfile d-flex align-items-center justify-content-center">
                                                                <img className="w-100" src={editDarkIcon} />
                                                            </Form.Label>
                                                            <div className="imgOuter w-100 h-100 rounded-circle overflow-hidden">
                                                                <img className="w-100 h-100" src={image ? apiPath.getImage + image : userProfile?.image ? apiPath.getImage + userProfile.image : "https://www.w3schools.com/howto/img_avatar.png"} />
                                                            </div>
                                                        </div>

                                                    </Form.Group>
                                                    <Form.Group as={Col} md="6">
                                                        <Form.Label>{lang("Full Name")}</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            placeholder={lang("Enter Name")}
                                                            className=""
                                                            name="name"
                                                            onChange={handleChange}
                                                            value={values?.name}
                                                        />
                                                        <ErrorMessage name="name" render={msg => <div style={{ fontSize: "14px", color: "red", marginLeft: 10 }}>{msg}</div>} />
                                                    </Form.Group>
                                                    <Form.Group as={Col} md="6">
                                                        <Form.Label>{lang("Email Address")}</Form.Label>
                                                        <Form.Control
                                                            type="email"
                                                            placeholder={lang("Enter Email Address")}
                                                            className=""
                                                            name="email"
                                                            disabled
                                                            value={values?.email}
                                                            onChange={handleChange}
                                                        />
                                                        <ErrorMessage name="email" render={msg => <div style={{ fontSize: "14px", color: "red", marginLeft: 10 }}>{msg}</div>} />

                                                    </Form.Group>
                                                    <Form.Group as={Col} md="6">
                                                        <Form.Label>{lang("Mobile Number")}</Form.Label>
                                                        <div className="input-group">
                                                            <PhoneInput
                                                                inputProps={{
                                                                    name: 'phone',
                                                                    autoFocus: true
                                                                }}
                                                                disabled
                                                                value={userProfile?.country_code + userProfile?.mobile_number}
                                                            // onChange={(value, data) => { setFieldValue("country_code", data.dialCode); setFieldValue("mobile_number", value.slice(data.dialCode.length)) }}
                                                            />
                                                        </div>
                                                        <ErrorMessage name="mobile_number" render={msg => <div style={{ fontSize: "14px", color: "red", marginLeft: 10 }}>{msg}</div>} />

                                                    </Form.Group>
                                                    <Form.Group as={Col} md="6">
                                                        <Form.Label>{lang("Date of Birth")}</Form.Label>
                                                        <Form.Control
                                                            type="date"
                                                            input="date"
                                                            max="2005-01-01"
                                                            placeholder={lang("Date of Birth")}
                                                            className=""
                                                            name="dob"
                                                            value={values?.dob}
                                                            onChange={handleChange}
                                                        />
                                                    </Form.Group>
                                                    <Form.Group as={Col} md="12" className="text-end">
                                                        <Link to='/profile'>
                                                            <button type="button" class="btn btnStyle1 me-3 shadow-none text-white px-sm-4">{lang("Cancel")}</button>
                                                        </Link>
                                                        <button type="submit" class="btn btnStyle1  shadow-none text-white px-sm-4">{lang("Save")}</button>
                                                    </Form.Group>
                                                </Row>
                                            </Form>
                                        )}
                                    </Formik>
                                    {/* <div className="row mt-0 g-3">
                                        <div className="col-6">
                                            <div className="prodetail p-2 px-3 rounded-3">
                                                <div className="title">Full Name</div>
                                                <div className="value">Olivia Rhye</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="prodetail p-2 px-3 rounded-3">
                                                <div className="title">Email Address</div>
                                                <div className="value">jolivia@untitledui.com</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="prodetail p-2 px-3 rounded-3">
                                                <div className="title">Phone  Number</div>
                                                <div className="value">+27 - (320) 555-0104</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="prodetail p-2 px-3 rounded-3">
                                                <div className="title">Date of Birth</div>
                                                <div className="value">14/10/18888</div>
                                            </div>
                                        </div>
                                        <div className="col-12 text-end">
                                            <button type="button" class="btn btnStyle1 shadow-none text-white px-sm-5">Save</button>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="otherSliderMain sectionPading bgLight">
                    <Banners />
                </div>

            </div>
        </>
    )
}

export default Edit_profile;

import React, { useEffect, useState } from "react";
import Header from "../Components/header/Header";
import Footer from "../Components/footer/footer";
import BlogImg1 from '../Assets/img/blogImg1.png';
import shareIcon from '../Assets/img/icon/shareIcon.svg';
import { useParams } from "react-router";
import useRequest from "../hooks/useRequest";
import apiPath from "../constants/apiPath";
import Banners from "../Components/banners/Banners";
import moment from "moment";
import Loader from "../Components/Loader";
import lang from "../helper/lang";
import { useAppContext } from "../context/AppContext";
import NoDataFound from "../Assets/img/NotFound.png";
import { Link } from "react-router-dom";
import ShareModel from "../Components/Modalss/ShareModal";


const Blog_detail = () => {
    const { id } = useParams();
    const { language } = useAppContext();
    const { request } = useRequest();
    const [loading, setLoading] = useState(false);
    const [blog, setBlog] = useState({});
    const [relatedBlog, setRelatedBlog] = useState([]);
    const [share, showShare] = useState(false);

    const getBlogDetail = () => {
        setLoading(true);
        let body = { type: "id", id: id };

        request({
            url: apiPath.blogDetail,
            method: "POST",
            data: body,
            onSuccess: (data) => {
                if (data.status) {
                    console.log(data);
                    setLoading(false);
                    setBlog(data.data);
                    setRelatedBlog(data.related)
                }
            },
            onError: (err) => {
                console.log(err);
                setLoading(false);
            }
        })
    }

    useEffect(() => {
        getBlogDetail();
    }, [id]);

    return (
        <>
            <div className="mainWraper">
                <div className="blogDetailWrapper sectionPading">
                    <div className="container">
                        <div className="row">
                            {loading
                                ? (<Loader />)
                                : (<div className={relatedBlog?.length && "col-xxl-9 col-lg-8"}>
                                    <div className="sectionHeading mb-2">
                                        <div className="row align-items-center">
                                            <div className="col">
                                                <div className="title d-inline-block">
                                                    <div className="txt">{lang("Blog Detail")}</div>
                                                    <div className="titleDesign d-flex align-items-center gap-2 mt-3"></div>
                                                </div>
                                            </div>
                                            <div className="col-auto d-flex align-items-center gap-1">
                                                <button onClick={()=>showShare(true)} type="button" className="btn shareBtn border-0 shadow-none p-0">
                                                    <img className="" src={shareIcon} />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="blogOuter">
                                        <div className="blogImgOuter">
                                            <img className="w-100 h-100" src={blog?.thumbnail || BlogImg1} />
                                        </div>
                                        <div className="text-capitalize blogFullDetail">
                                            <div className="blogDate">{moment(blog?.created_at).format("Do MMM YYYY")}</div>
                                            <div className="blogTitle mt-2">{language !== ('en' || null) ? (blog[`${language}_title`] ?? blog?.title) : blog?.title}</div>
                                            <div className="pera mt-2">
                                                <div dangerouslySetInnerHTML={{ __html: language !== ('en' || null) ? (blog[`${language}_description`] ?? blog?.description) : blog?.description }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>)}
                            {relatedBlog.length ? (<div className="col-xxl-3 col-md-4 d-none d-lg-block">
                                <div className="sectionHeading mb-2">
                                    <div className="row align-items-center">
                                        <div className="col">
                                            <div className="title d-inline-block">
                                                <div className="txt">{lang("Related Blogs")}</div>
                                                <div className="titleDesign d-flex align-items-center gap-2 mt-3"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row g-3">
                                    {relatedBlog.length ? relatedBlog.map((rb) => {
                                        return (<div className="col-12">
                                            <Link to={`/blog-detail/${rb?._id}`} className="blogItem bg-white d-block text-decoration-none">
                                                <div className="blogImg">
                                                    <img className="w-100 h-100" src={rb?.thumbnail || BlogImg1} />
                                                </div>
                                                <div className="blogDetail">
                                                    <div className="blogDate">{moment(rb?.created_at).format("Do MMM YYYY")}</div>
                                                    <div className="d-flex align-items-center justify-content-between gap-2 mt-1">
                                                        <div className="blogTitle text-capitalize">{language !== ('en' || null) ? (blog[`${language}_title`] ?? blog?.title) : blog?.title}</div>
                                                        <div className="arrow">
                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M4.92893 12H19.0711M19.0711 12L12 4.92896M19.0711 12L12 19.0711" stroke="#101828" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                            </svg>
                                                        </div>
                                                    </div>
                                                    <div className="dec mt-1 text-capitalize">
                                                        <div dangerouslySetInnerHTML={{ __html: language !== ('en' || null) ? (rb[`${language}_description`] ?? rb?.description) : rb?.description }} />
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>)
                                    }) : (
                                        <div>
                                            <img width={120} src={NoDataFound} alt="" />
                                            <h6 className='pt-2'>{lang("No Data Found")}</h6>
                                        </div>
                                    )}
                                </div>
                            </div>) : null}
                        </div>
                    </div>
                </div>

                <div className="otherSliderMain sectionPading bgLight">
                    <Banners />
                </div>

            </div>
            {
                share && <ShareModel show={share} hide={() => showShare(false)} />
            }
        </>
    )
}

export default Blog_detail;

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ProductsCard from "../products/ProductsCard";
import useRequest from "../../hooks/useRequest";
import apiPath from "../../constants/apiPath";
import Loader from "../Loader";
import lang from "../../helper/lang";
import NoDataFound from "../../Assets/img/NotFound.png";


const ProductSection = () => {

    const [loading, setLoading] = useState(false);
    const [products, setProducts] = useState([]);
    const { request } = useRequest();

    const getFeaturedProducts = () => {
        setLoading(true);
        request({
            url: apiPath.featuredProducts + "?page=1&pageSize=10",
            method: "GET",
            onSuccess: (data) => {
                if (data.status) {
                    setProducts(data.data);
                }
                setLoading(false);
            },
            onError: (err) => {
                console.log(err);
                setLoading(false);
            },
        });
    };

    useEffect(() => {
        getFeaturedProducts();
    }, []);


    return ( products.length && 
        <div className="container">
            <div className="sectionHeading">
                <div className="row align-items-center">
                    <div className="col">
                        <div className="title d-inline-block">
                            <div className="txt">{lang("Products")}</div>
                            <div className="titleDesign d-flex align-items-center gap-2 mt-3"></div>
                        </div>
                    </div>
                    <div className="col-auto">
                        <Link to={`/products`} className="textBtn">
                            {lang("See All")}
                        </Link>
                    </div>
                </div>
            </div>
            {loading
                ? (<Loader />)
                : products.length
                    ? (<div className="productsOuter mt-2">
                        <div className="row g-3 row-cols-xxl-6">
                            {products.map((prod) => {
                                return (
                                    <ProductsCard prod={prod} favourite={prod?.is_wishlist} />
                                );
                            })
                            }
                        </div>
                    </div>)
                    : <div>
                        <img width={120} src={NoDataFound} alt="" />
                        <h6 className='pt-2'>{lang("No Data Found")}</h6>
                    </div>
            }
        </div>
    );
};

export default ProductSection;
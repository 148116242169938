import { useContext, useEffect, useState } from 'react';
import useRequest from "../../hooks/useRequest";
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import lang from '../../helper/lang';
import apiPath from '../../constants/apiPath';
import PhoneInput from 'react-phone-input-2';
import { AuthContext } from '../../context/AuthContext';
import { Severty, ShowToast } from '../../helper/toast';


const EditMobile_Mail = ({ edit, onHide, onUpdate }) => {

    const { userProfile } = useContext(AuthContext);
    const [email, setEmail] = useState(userProfile?.email);
    const [number, setNumber] = useState({
        code: userProfile?.country_code || '',
        mobile: userProfile?.mobile_number || '',
    });
    const [error, setError] = useState();
    const { request } = useRequest();

    const isValidEmail = (email) => {
        const re =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (edit === 'email') {
            if ((!email) || !isValidEmail(email)) {
                setError(lang("Please enter valid email"));
            }
            else {
                let body = {
                    email: email,
                    type: userProfile?.type,
                    verify: 'email',
                }
                onUpdate(body);
                request({
                    url: apiPath.sendUpdateOtp,
                    method: 'POST',
                    data: body,
                    onSuccess: (data) => {
                        if (data.status) {
                            ShowToast(data.message, Severty.SUCCESS);
                            onHide('otp')
                        }
                        else{
                            ShowToast(data.message, Severty.ERROR);
                        }
                    },
                    onError: (err) => {
                        console.log(err);
                        onHide('')
                        ShowToast(err.message, Severty.ERROR)
                    }

                })
            }
        } else {
            if (!number.mobile) {
                setError("Please enter mobile number");
            } else if (number.mobile.length < 7) {
                setError(lang("Please enter minimum 7 digit"));
            }
            else {
                let body = {
                    country_code: number.code,
                    mobile_number: number.mobile,
                    type: userProfile?.type,
                    verify: 'mobile',
                }
                onUpdate(body);
                request({
                    url: apiPath.sendUpdateOtp,
                    method: 'POST',
                    data: body,
                    onSuccess: (data) => {
                        if (data.status) {
                            ShowToast(data.message, Severty.SUCCESS);
                            onHide('otp');
                        }
                        else{
                            ShowToast(data.message, Severty.ERROR);
                            onHide('');
                        }
                    },
                    onError: (err) => {
                        console.log(err);
                        onHide('');
                        ShowToast(err.message, Severty.ERROR)
                    }

                })
            }
        }

    }

    useEffect(() => {
        if (edit === 'email') {
            if (isValidEmail(email)) setError('')
        } else {
            if (number.mobile.length >= 7) setError('')
        }
    }, [number, email]);

    return (
        <Modal show={true} onHide={() => onHide()} backdrop="static" centered>
            <Modal.Header closeButton>
                <Modal.Title className="fs-5 fw-bold modal-title h4">{edit === 'email' ? 'Edit Email' : 'Edit Number'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form className="loginSignUpForm" onSubmit={handleSubmit}>
                    <Row className="g-4">
                        {edit === 'email'
                            ? (<Form.Group as={Col} md="12">
                                <Form.Label>New Email</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder={`${lang('Enter Updated Email')}`}
                                    name="age"
                                    value={email}
                                    onChange={({ target }) => setEmail(target.value)}
                                />
                                {error ? (
                                    <div className='error_msg' style={{ color: 'red' }}>{error}</div>
                                ) : null}
                            </Form.Group>)
                            : (<Form.Group as={Col} md="12">
                                <Form.Label>{lang("Mobile Number")}</Form.Label>
                                <div className="input-group">
                                    <PhoneInput
                                        inputProps={((type) => {
                                            return {
                                                name: 'phone',
                                                autoFocus: true
                                            }
                                        })()}
                                        // country={"in"}
                                        value={number?.code + number?.mobile}
                                        onChange={(value, data) => { setNumber({ code: data.dialCode, mobile: value.slice(data.dialCode.length) }) }}
                                    />
                                </div>
                                {error ? (
                                    <div className='error_msg' style={{ color: 'red' }}>{error}</div>
                                ) : null}
                            </Form.Group>)
                        }
                        <Form.Group as={Col} md="12" className="text-end">
                            <button type="submit" class="btn btnStyle1 shadow-none text-white px-sm-5">
                                Save
                            </button>
                        </Form.Group>
                    </Row>
                </Form>
            </Modal.Body>

        </Modal>)
}

export default EditMobile_Mail;
import React, { useState, useEffect, useContext } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import PassShow from "../../Assets/img/icon/passShowIcon.svg";
import PassHide from "../../Assets/img/icon/passHideIcon.svg";
import apiPath from "../../constants/apiPath";
import encryptDecrypt from "../../helper/encryptDecrypt";
import { AuthContext } from "../../context/AuthContext";
import useRequest from "../../hooks/useRequest";
import { ErrorMessage, Formik } from "formik";
import PhoneInput from "react-phone-input-2";
import * as Yup from "yup";
import { Severty, ShowToast } from "../../helper/toast";
import { useLocation, useNavigate } from "react-router";
import { useAppContext } from "../../context/AppContext";
import lang from "../../helper/lang";
import useFirebase from "../../hooks/useFirebase";
import { Spinner } from "react-bootstrap";

const validationSchema = Yup.object().shape({
  mobile_number: Yup.string()
    .min(7, lang("Please enter atleast 7 digit number"))
    .required(lang("Please Enter Mobile Number")),
  country_code: Yup.string().required("Please Enter Country Code"),
  password: Yup.string()
    .min(6, lang("Password must be at least 6 characters long"))
    .required(lang("Please Enter Password")),
});

const SignIn = ({ onClose }) => {
  const location = useLocation();
  const { setIsLoggedIn, setUserProfile } = useContext(AuthContext);
  const { showDialouge } = useAppContext();
  const [rememberMe, setRememberMe] = useState(false);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const { request } = useRequest();
  const navigate = useNavigate();

  const { fcmToken } = useFirebase();

  const [formData, setFormData] = useState({
    country_code: "27",
    mobile_number: "",
    password: "",
    type: "Customer",
  });

  const handleRememberMeChange = () => {
    setRememberMe((x) => !x);
  };

  const Submit = (data) => {
    const body = {
      ...data,
      device_token: fcmToken,
      device_type: "web",
    };
    console.log(body);
    setLoading(true);
    request({
      url: apiPath.login,
      method: "POST",
      data: body,
      onSuccess: (data) => {
        if (data.status) {
          console.log(data);
          // navigate("/");
          setIsLoggedIn(true);
          if (rememberMe) {
            var mobileEncrypt = encryptDecrypt.encryptNumber(
              body.mobile_number
            );
            var passwordEncrypt = encryptDecrypt.encryptPassword(body.password);
            localStorage.setItem("code", body?.country_code);
            localStorage.setItem("number", mobileEncrypt || "ff");
            localStorage.setItem("password", passwordEncrypt || "98");
          } else {
            localStorage.removeItem("code");
            localStorage.removeItem("number");
            localStorage.removeItem("password");
          }
          localStorage.setItem("token", data.data.token);
          localStorage.setItem("userProfile", JSON.stringify(data.data.user));
          setUserProfile(data.data.user);
          ShowToast(data.message, Severty.SUCCESS);
          onClose("");
        } else if (data?.message?.includes("verify")) {
          ShowToast(data.message, Severty.INFO);
          onClose("otp", body);
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
        setLoading(false);
      },
      onError: (err) => {
        setLoading(false);
        console.log(err);
      },
    });
  };

  useEffect(() => {
    const savedCode = localStorage.getItem("code");
    const savedNumber = localStorage.getItem("number");
    const savedPassword = localStorage.getItem("password");

    if (savedNumber && savedPassword) {
      var originalNumber = encryptDecrypt.decryptNumber(savedNumber);
      var originalPassword = encryptDecrypt.decryptPassword(savedPassword);
      setFormData({
        country_code: savedCode,
        mobile_number: originalNumber,
        password: originalPassword,
        type: "Customer",
      });
      setTimeout(() => setShow(true), 200);
      setRememberMe(true);
    } else {
      setRememberMe(false);
      setShow(true);
    }
  }, []);

  return (
    <>
      <Modal
        className="loginModal"
        show={true}
        onHide={(e) => {
          onClose();
        }}
        centered
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title className="fs-5 fw-bold">{lang("Login")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {show && (
            <FormInput
              onClose={onClose}
              handleRememberMeChange={handleRememberMeChange}
              Submit={Submit}
              rememberMe={rememberMe}
              formData={formData}
              loading={loading}
            />
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

const FormInput = ({
  onClose,
  handleRememberMeChange,
  Submit,
  rememberMe,
  formData,
  loading,
}) => {
  const [show, setShow] = useState(false);
  return (
    <Formik
      initialValues={formData}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        let body = { ...values };
        console.log(body);
        Submit(body);
      }}
    >
      {({ values, setFieldValue, handleChange, handleSubmit }) => (
        <Form className="loginSignUpForm" onSubmit={handleSubmit}>
          <Row className="gap-3">
            <Form.Group as={Col} md="12">
              <Form.Label>{lang("Mobile Number")}</Form.Label>
              <div className="input-group">
                <PhoneInput
                  inputProps={((type) => {
                    return {
                      name: "phone",
                      autoFocus: true,
                    };
                  })()}
                  // country={"in"}
                  value={values.country_code + values.mobile_number}
                  onChange={(value, data) => {
                    setFieldValue("country_code", data.dialCode);
                    setFieldValue(
                      "mobile_number",
                      value.slice(data.dialCode.length)
                    );
                  }}
                />
              </div>
            </Form.Group>
            <ErrorMessage
              name="mobile_number"
              render={(msg) => (
                <div style={{ fontSize: "14px", color: "red", marginLeft: 10 }}>
                  {msg}
                </div>
              )}
            />

            <Form.Group as={Col} md="12">
              <Form.Label>{lang("Password")}</Form.Label>
              <div className="position-relative">
                <Form.Control
                  value={values?.password}
                  type={`${show ? "text" : "password"}`}
                  placeholder={lang("Enter Password")}
                  className=""
                  name="password"
                  onChange={handleChange}
                />
                <button
                  type="button"
                  onClick={() => setShow((x) => !x)}
                  className="btn passBtn border-0 shadow-none p-0 position-absolute d-flex align-items-cneter justify-content-center"
                >
                  <img className="passShow" src={!show ? PassShow : PassHide} />
                </button>
              </div>
            </Form.Group>
            <ErrorMessage
              name="password"
              render={(msg) => (
                <div style={{ fontSize: "14px", color: "red", marginLeft: 10 }}>
                  {msg}
                </div>
              )}
            />

            <Form.Group
              as={Col}
              md="12"
              className="d-flex align-items-center justify-content-between"
            >
              <div className="mb-3 d-flex gap-2">
                <Form.Check
                  type={"checkbox"}
                  className="p-0 m-0 d-flex align-items-center gap-2"
                  id="remember-me"
                  checked={rememberMe}
                  onChange={handleRememberMeChange}
                />
                <label>{lang("Remember Me")}</label>
              </div>
              <a
                href="javascript:;"
                onClick={() => onClose("forgot")}
                className="textBtn"
              >
                {lang("Forgot Password")}
              </a>
            </Form.Group>
            <Form.Group as={Col} md="12" className="mt-3">
              <Button
                type="submit"
                className="btn btnStyle1 shadow-none text-white px-4 w-100"
              >
                {loading ? (
                  <Spinner animation="border" size="sm" />
                ) : (
                  lang("Login")
                )}
              </Button>
            </Form.Group>
            <Form.Group as={Col} md="12" className="mb-2">
              <div className="otherLink text-center">
                {lang("Don’t have an account")}?{" "}
                <a
                  href="javascript:;"
                  onClick={() => onClose("signup")}
                  className="textBtn"
                >
                  {lang("Sign Up")}
                </a>
              </div>
            </Form.Group>
          </Row>
        </Form>
      )}
    </Formik>
  );
};

export default SignIn;

const CryptoJS = require("crypto-js");
const encryptReactPassword = process.env.REACT_APP_ENCRYPT_PASSWORD || "pimptupEncryption";

const encryptNumber = (mobile_number) => {
    return CryptoJS.AES.encrypt(mobile_number, encryptReactPassword).toString();
};

const encryptPassword = (password) => {
    return CryptoJS.AES.encrypt(password, encryptReactPassword).toString();
};

const decryptNumber = (mobile_number) => {
    var byteNumber   =    CryptoJS.AES.decrypt(mobile_number, encryptReactPassword);
    return byteNumber.toString(CryptoJS.enc.Utf8);
}

const decryptPassword = (password) => {
    var bytePasssword   =    CryptoJS.AES.decrypt(password, encryptReactPassword);
    return bytePasssword.toString(CryptoJS.enc.Utf8);
}


export default { encryptNumber, encryptPassword, decryptNumber, decryptPassword }
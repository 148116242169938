import OwlCarousel from 'react-owl-carousel3';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Productimg1 from '../Assets/img/blub2.png';
import shareIcon from '../Assets/img/icon/shareIcon.svg';
import { useParams } from 'react-router';
import useRequest from '../hooks/useRequest';
import { useContext, useEffect, useState } from 'react';
import Banners from '../Components/banners/Banners';
import apiPath from "../constants/apiPath"
import NoDataFound from "../Assets/img/NotFound.png";
import Loader from '../Components/Loader';
import ProvidersCard2 from '../Components/providers/ProvidersCard2';
import { ProductDetailSlider } from "../constants/sliders";
import { AuthContext } from '../context/AuthContext';
import { AppStateContext } from '../context/AppContext';
import lang from '../helper/lang';
import ShareModal from '../Components/Modalss/ShareModal'


const Product_detail = () => {

    const { id } = useParams();
    const [loading, setLoading] = useState(false);

    const { setShowDialouge } = useContext(AppStateContext)
    const { isLoggedIn } = useContext(AuthContext)
    const [product, setProduct] = useState();
    const [productType, setProductType] = useState();
    const [specialists, setSpecialists] = useState([]);
    const [dealers, setDealers] = useState([]);
    const [allAttributes, setAllAttributes] = useState([]);
    const [attributes, setAttributes] = useState([]);
    const [fav, setFav] = useState(false);
    const [share, showShare] = useState(false);
    const [title, setTitle] = useState();
    const { request } = useRequest();

    const productDetail = () => {
        setLoading(true);
        request({
            url: apiPath.productDetail + id,
            method: "POST",
            onSuccess: (data) => {
                if (data.status) {
                    data.data?.image?.push(data.data?.thumbnail);
                    setProduct(data.data);
                    setDealers(data?.dealer);
                    setSpecialists(data?.specialist);
                    setAllAttributes(data?.data?.all_attribute);
                    setAttributes(data?.data?.attribute);
                    setFav(data?.data?.is_wishlist);

                    const type = data?.main?.specialistproducts?.map(itm => {
                        return itm.type;
                    });

                    if ((type?.includes('new') && type?.includes('used')) || (type?.includes('both'))) {
                        setProductType('Both');
                    } else if (type?.includes('new')) {
                        setProductType('New');
                    } else if (type?.includes('used')) {
                        setProductType('Used');
                    }
                }
                setLoading(false);
            },
            onError: (err) => {
                console.log(err);
                setLoading(false);
            }
        });
    }

    const handleChange = (e, qr) => {
        let body = {
            query: [
                { name: qr?.name, value: e.target.value, type: qr?.type },
            ]
        }
        console.log(body)
        setLoading(true);
        request({
            url: apiPath.productDetail + id,
            method: "POST",
            data: body,
            onSuccess: (data) => {
                if (data.status) {
                    data.data?.image?.push(data.data?.thumbnail);
                    setProduct(data.data);
                    setDealers(data?.dealer);
                    setSpecialists(data?.specialist);
                    setAllAttributes(data?.data?.all_attribute);
                    setAttributes(data?.data?.attribute);
                    setFav(data?.data?.is_wishlist);
                }
                setLoading(false);
            },
            onError: (err) => {
                console.log(err);
                setLoading(false);
            }
        });
    }

    const addFavourite = (id) => {
        isLoggedIn
            ? request({
                url: apiPath.addWishList,
                method: "POST",
                data: { product_id: id, type: "Product" },
                onSuccess: (data) => {
                    if (data.status) {
                        setFav(x => !x);
                        console.log(fav);
                    }
                },
                onError: (err) => {
                    console.log(err);
                }
            })
            : (setShowDialouge({ type: 'login' }))
    }

    useEffect(() => {
        productDetail();
    }, [id]);

    useEffect(() => {
        if (!product) return

        const title = {
            name: `${product?.name}`,
            type: 'product',
            image: product?.thumbnail,
            brand: `${product?.make_id?.name}`,
            model: `${product?.model_id?.name}`,
            variant: `${product?.variant_id?.name}`,
            category: `${product?.category_id?.name}`,
            info: JSON.stringify(product?.attribute),
        }
        setTitle(title)

    }, [product])

    return (
        <>
            <div className="mainWraper">

                <div className="productDetail sectionPading">
                    <div className="container">
                        <div className="sectionHeading">
                            <div className="row align-items-center">
                                <div className="col">
                                    <div className="title d-inline-block">
                                        <div className="txt">{lang("Product detail")}</div>
                                        <div className="titleDesign d-flex align-items-center gap-2 mt-3"></div>
                                    </div>
                                </div>
                                <div className="col-auto">
                                    <button type="button" onClick={() => showShare(true)} className="btn shareBtn border-0 shadow-none p-0">
                                        <img className="" src={shareIcon} alt='' />
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="productsDetailOuter mt-2">
                            {loading ? (<Loader />) : (<div className="row g-xl-5 g-md-4 g-3">
                                <div className="col-xl-5 col-lg-6">
                                    <div className="productImgOuter position-relative">
                                        {product?.image?.length ? <> <div onClick={() => addFavourite(product?.parent_id)} className={`favbtn ${fav && "favouriteItem"} position-absolute top-0 end-0 mt-3 me-3 z-3`}>
                                            <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10.517 17.836C10.2337 17.936 9.76699 17.936 9.48366 17.836C7.06699 17.011 1.66699 13.5693 1.66699 7.73597C1.66699 5.16097 3.74199 3.07764 6.30033 3.07764C7.81699 3.07764 9.15866 3.81097 10.0003 4.9443C10.842 3.81097 12.192 3.07764 13.7003 3.07764C16.2587 3.07764 18.3337 5.16097 18.3337 7.73597C18.3337 13.5693 12.9337 17.011 10.517 17.836Z" stroke="#919191" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>
                                        </div>
                                            <OwlCarousel {...ProductDetailSlider} >
                                                {product?.image?.length && (
                                                    product?.image?.map((item) =>
                                                    (<div className="productImg w-100">
                                                        <img alt="" className="w-100 h-100" src={item || Productimg1} />
                                                    </div>)
                                                    )
                                                )
                                                }
                                            </OwlCarousel></> : <h4>{lang("No Image Found")}</h4>}
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="productFullDetail">
                                        <div className="name text-capitalize">{product?.name}</div>
                                        <div className="brand text-uppercase mt-1">{product?.brand_id?.name}</div>
                                        <div className="category text-capitalize mt-1">{product?.category_id?.name}</div>
                                        {/* <div className="proRating d-flex align-items-center gap-1 my-2"><img alt="" src={Starfill} /> 4.5</div> */}
                                        <div className="proPrice mb-3">R {product?.price}</div>
                                        <div className='productAttribute my-3'>
                                            <Form>
                                                <Row className="gap-3">
                                                    {allAttributes?.length && (
                                                        allAttributes?.map((attr, idx) => {
                                                            return (
                                                                <>
                                                                    {attr?.name !== "Color" &&
                                                                        <Form.Group as={Col} xl="6">
                                                                            <Form.Label className='text-capitalize'>{attr?.name}</Form.Label>
                                                                            <Form.Select className="text-capitalize" onChange={(e) => { handleChange(e, attr) }} aria-label="Default select example">
                                                                                {/* <option>Select {attr?.name}</option> */}
                                                                                {attr?.value?.map((val) =>
                                                                                    <option style={{ color: val?.is_active ? 'black' : 'GrayText' }} className="text-capitalize" selected={attributes[idx]?.value === val.value} value={val.value}>{val.value}</option>
                                                                                )}
                                                                            </Form.Select>
                                                                        </Form.Group>}

                                                                    {attr?.name === "Color" &&
                                                                        <Form.Group as={Col} sm="6">
                                                                            <Form.Label className='text-capitalize'>{attr?.name}</Form.Label>
                                                                            <div className="text-capitalize d-flex align-items-center gap-2">
                                                                                {attr?.value?.map((val, idx) => (
                                                                                    <Form.Check
                                                                                        // defaultChecked = {val.value === }
                                                                                        onChange={(e) => {
                                                                                            if (e.target.checked) {
                                                                                                // alert("Hello");
                                                                                            }
                                                                                        }}
                                                                                        value={val?.value}
                                                                                        name={val?.value}
                                                                                        type={'radio'}
                                                                                        id={`radio-${idx}`}
                                                                                        label={
                                                                                            <>
                                                                                                <div id={`${idx}`} className='text-capitalize colour' style={{ backgroundColor: val.value, textAlign: "center" }}></div>
                                                                                            </>
                                                                                        }
                                                                                    />
                                                                                ))
                                                                                }
                                                                            </div>
                                                                        </Form.Group>}
                                                                </>
                                                            )
                                                        })
                                                    )}
                                                </Row>
                                            </Form>
                                            <div className='row'>
                                                <div className='col-xxl-8'>
                                                    <div className='productDetail mt-3 bg-white p-3'>
                                                        <ul className='text-capitalize p-0 m-0 list-unstyled d-flex flex-column gap-2'>
                                                            <li className='row g-1'>
                                                                <div className='col-6 title'>{lang("Product Name")} :</div>
                                                                <div className='col-6 text-capitalize value'>{product?.name}</div>
                                                            </li>
                                                            <li className='row g-1'>
                                                                <div className='col-6 title'>{lang("Make")} :</div>
                                                                <div className='col-6 text-capitalize value'>{product?.make_id?.name}</div>
                                                            </li>
                                                            <li className='row g-1'>
                                                                <div className='col-6 title'>{lang("Model")} :</div>
                                                                <div className='col-6 text-capitalize value'>{product?.model_id?.name}</div>
                                                            </li>
                                                            <li className='row g-1'>
                                                                <div className='col-6 title'>{lang("Variant")} :</div>
                                                                <div className='col-6 text-capitalize value'>{product?.variant_id?.name}</div>
                                                            </li>
                                                            <li className='row g-1'>
                                                                <div className='col-6 title'>{lang("Product Type")} :</div>
                                                                <div className='col-6 value'>{productType}</div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <Link to={isLoggedIn && "/request-quote"} onClick={handleChat} class="btn btnStyle1 shadow-none text-white">{lang("Quote Request")}</Link> */}

                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="proDec position-relative border-top pt-md-4 pt-3">
                                        <div className="title fs-5 fw-bold mb-2">{lang("Description")}</div>
                                        <div className="text-capitalize" dangerouslySetInnerHTML={{ __html: product?.description }} />
                                        {/* <button type="button" className="textBtn border-0 shadow-none text-decoration-none position-absolute bottom-0 end-0 p-1 ps-3">Read More</button> */}
                                    </div>
                                </div>
                            </div>)}
                        </div>
                    </div>
                </div>

                <div className="otherSliderMain sectionPading bgLight">
                    <Banners />
                </div>

                <div className="specialistWraper sectionPading">
                    <div className="container">
                        <div className="sectionHeading">
                            <div className="row align-items-center">
                                <div className="col">
                                    <div className="title d-inline-block">
                                        <div className="txt">{lang("Specialists")}</div>
                                        <div className="titleDesign d-flex align-items-center gap-2 mt-3"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="specialistOuter mt-2">
                            {loading
                                ? (<Loader />)
                                : specialists?.length
                                    ? (<div className="row g-3 row-cols-xs-2 row-cols-3 row-cols-md-4 row-cols-lg-5 row-cols-xl-6 row-cols-xxxl-8">
                                        {specialists?.map((sp) => {
                                            return (
                                                <ProvidersCard2 pr={sp?.user_id} price={sp?.price} title={title} />
                                            )
                                        })}
                                    </div>)
                                    : <div>
                                        <img width={120} src={NoDataFound} alt="" />
                                        <h6 className='pt-2'>{lang("No Data Found")}</h6>
                                    </div>
                            }
                        </div>
                    </div>
                </div>

                <div className="specialistWraper sectionPading">
                    <div className="container">
                        <div className="sectionHeading">
                            <div className="row align-items-center">
                                <div className="col">
                                    <div className="title d-inline-block">
                                        <div className="txt">{lang("Dealers")}</div>
                                        <div className="titleDesign d-flex align-items-center gap-2 mt-3"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="specialistOuter mt-2">
                            {loading
                                ? (<Loader />)
                                : dealers?.length
                                    ? (<div className="row g-3 row-cols-xs-2 row-cols-3 row-cols-md-4 row-cols-lg-5 row-cols-xl-6 row-cols-xxxl-8">
                                        {dealers?.map((dl) => {
                                            return (
                                                <ProvidersCard2 pr={dl?.user_id} price={dl?.price} title={title} />
                                            )
                                        })}
                                    </div>)
                                    : <div>
                                        <img width={120} src={NoDataFound} alt="" />
                                        <h6 className='pt-2'>{lang("No Data Found")}</h6>
                                    </div>
                            }
                        </div>
                    </div>
                </div>

            </div>
            {
                share && <ShareModal show={share} hide={() => showShare(false)} />
            }

        </>
    )
}

export default Product_detail;

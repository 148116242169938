import React, { useEffect, useState } from "react";
import useRequest from "../hooks/useRequest";
import Banners from "../Components/banners/Banners";
import apiPath from "../constants/apiPath";
import lang from "../helper/lang";
import Accordion from 'react-bootstrap/Accordion';
import Loader from "../Components/Loader";
import NoDataFound from "../Assets/img/NotFound.png";
import { useAppContext } from "../context/AppContext";


const Faq = () => {
    const { request } = useRequest();
    const [faqs, setFaqs] = useState({});
    const [loading, setLoading] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const { language } = useAppContext();

    const getFaqs = () => {
        setLoading(true);
        request({
            url: apiPath.getContentList + 'faq',
            method: 'GET',
            onSuccess: ({ data, status }) => {
                if (status) {
                    setIsActive(data?.is_active);
                    if (language != 'en') {
                        setFaqs(data[`${language}_faq`]);
                    } else {
                        setFaqs(data[`faq`]);
                    }
                }
                setLoading(false);
            },
            onError: (err) => {
                console.error(err);
                setLoading(false);
            }
        })
    }

    useEffect(() => {
        getFaqs();
    }, []);

    return (
        <>
            <div className="mainWraper">
                <div className="pageImgOuter">
                    <img className="w-100 h-100" src="https://www.gaadizo.com/gaadizo-car-service-repairs/assets/media/components/b-main-slider/dent%20repairs.jpg" />
                    <div className="pageTitle position-absolute text-white">{lang("FAQs")}</div>
                </div>
                <div className="contentWrapper sectionPading">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-xxl-7 col-xl-9">
                                <div className="sectionHeading">
                                    <div className="row align-items-center">
                                        <div className="col">
                                            <div className="title d-inline-block">
                                                <div className="txt">{lang("Frequently Asked Questions")}</div>
                                                <div className="titleDesign d-flex align-items-center gap-2 mt-3"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {isActive ? (<div className="faqMainSection mt-2">
                                    <Accordion defaultActiveKey="0" className="d-flex flex-column gap-2 rounded-0">
                                        {loading
                                            ? <Loader />
                                            : (faqs?.length
                                                ? faqs?.map((fq, idx) => {
                                                    return (
                                                        <Accordion.Item className="border rounded-3 overflow-hidden" eventKey={idx}>
                                                            <Accordion.Header>Q{idx + 1}. {fq?.question}?</Accordion.Header>
                                                            <Accordion.Body className="p-sm-3 p-2">
                                                                <div className="dec">A{idx + 1}. {fq?.answer}</div>
                                                            </Accordion.Body>
                                                        </Accordion.Item>
                                                    )
                                                })
                                                : <div>
                                                    <img width={120} src={NoDataFound} alt="" />
                                                    <h6 className='pt-2'>{lang("No Data Found")}</h6>
                                                </div>
                                            )
                                        }
                                    </Accordion>
                                </div>) : (
                                    <div className="faqMainSection mt-2">
                                        No Questions Found!
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="otherSliderMain sectionPading pt-0">
                    <Banners />
                </div>

            </div >
        </>
    )
}

export default Faq;

import { Modal } from "react-bootstrap";
import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom";

const ShareModel = ({ show, hide }) => {

    const [url, setUrl] = useState('')

    useEffect(() => {
        const getUrl = window.location.href.split('?')[0]
        setUrl(encodeURIComponent(getUrl));
    }, [])

    const shareOnInstagram = () => {
        window.open("https://www.instagram.com/?url=" + url, "_blank")
    }

    const shareOnWhatsApp = () => {
        window.open("https://api.whatsapp.com/send?text=" + url, "_blank")
    }

    const shareOnTwitter = () => {
        
        window.open("https://twitter.com/share?text=" + url, "_blank")
    }

    const shareOnFacebook = () => {

        window.open("https://facebook.com/share.php?u=" + url, "_blank")

    }

    return (
        <Modal className="share_modal" show={show} onHide={hide} size="sm" centered>
            <Modal.Header closeButton><h4 className="fw-bold">Share</h4></Modal.Header>
            <Modal.Body className="text-center">
                <ul className="social_sharing p-0 m-0 list-unstyled d-flex align-items-center gap-3 justify-content-center">
                    <li><Link onClick={shareOnFacebook}><img src="/assets/img/sq_fb.png" alt="" /></Link></li>
                    <li><Link onClick={shareOnTwitter}><img src="/assets/img/sq_twitter.png" alt="" /></Link></li>
                    <li><Link onClick={shareOnInstagram}><img src="/assets/img/3-instagram.png" alt="" /></Link></li>
                    <li><Link onClick={shareOnWhatsApp}><img src="/assets/img/sq_whatsapp.png" alt="" /></Link></li>
                </ul>
            </Modal.Body>
        </Modal>
    );
};

export default ShareModel;
import React, { createContext, useState, useContext } from "react";
import { Navigate } from "react-router-dom";
import { serverTimestamp } from "firebase/firestore";
import useRequest from "../hooks/useRequest";
import { AuthContext } from "./AuthContext";
import useFirebase from "../hooks/useFirebase";
import { useMediaQuery } from "react-responsive";
export const AppStateContext = createContext();

export const AppContextProvider = ({ children }) => {
  const { request } = useRequest();
  const [showDialouge, setShowDialouge] = useState({
    type: "",
    payload: {},
    reset: false,
  });
  const [refresh, setRefresh] = useState(false);
  const language = JSON.parse(localStorage.getItem("languageSet"))
    ? JSON.parse(localStorage.getItem("languageSet")).value
    : "en";
  console.log(language);

  const { addChatToInbox } = useFirebase();
  const { userProfile } = useContext(AuthContext);
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const addChat = async (user, title) => {
    const senderObj = {
      title,
      created_at: serverTimestamp(),
      is_read: false,
      last_message: "",
      type: user?.type ?? "Customer",
      last_seen: serverTimestamp(),
      name: user.name,
      image: user.image,
      receiver_id: user._id,
      sender_id: userProfile?._id,
    };

    const receiverObj = {
      title,
      created_at: serverTimestamp(),
      is_read: false,
      last_message: "",
      type: userProfile?.type,
      last_seen: serverTimestamp(),
      name: userProfile?.name,
      image: userProfile?.image,
      receiver_id: userProfile?._id,
      sender_id: user._id,
    };

    await addChatToInbox(user._id, senderObj, receiverObj);

    return <Navigate to="/chat" />;
  };

  return (
    <AppStateContext.Provider
      value={{
        refresh,
        setRefresh,
        showDialouge,
        setShowDialouge,
        language,
        addChat,
        isMobile,
      }}
    >
      {children}
    </AppStateContext.Provider>
  );
};

export const useAppContext = () => {
  return useContext(AppStateContext);
};

const de = {
    'Home': 'Startseite',
    'Quotes': 'Zitate',
    'English': 'Englisch',
    'German': 'Deutsch',
    'Spanish': 'Spanisch',
    'French': 'Französisch',
    'Messages': 'Nachrichten',
    'Notifications': 'Benachrichtigungen',
    'Categories': 'Kategorien',
    'Brands': 'Marken',
    'Products': 'Produkte',
    'Specialists': 'Spezialisten',
    'See All': 'Alle Anzeigen',
    'Dealers': 'Händler',
    'Setting': 'Einstellung',
    'Log Out': 'Abmelden',
    'Log In': 'Anmelden',
    'Account': 'Konto',
    'My Vehicles': 'Meine Fahrzeuge',
    'Favourites': 'Favoriten',
    'Blogs': 'Blogs',
    'Delete Account': 'Konto löschen',
    'Pimpt-Up 2023, All Rights Reserved': 'Pimpt-Up 2023, Alle Rechte vorbehalten',
    'Profile': 'Profil',
    'About Us': 'Über uns',
    'Contact Us': 'Kontaktiere uns',
    'Terms & Conditions': 'Geschäftsbedingungen',
    'Privacy Policy': 'Datenschutzrichtlinie',
    'Cancellation Policy': 'Stornierungsrichtlinie',
    'Help Center': 'Hilfezentrum',
    'Contact': 'Kontakt',
    'Tel': 'Tel',
    'Mail': 'Post',
    'Address': 'Adresse',
    'Fax': 'Fax',
    'Full Name': 'Vollständiger Name',
    'Email Address': 'E-Mail-Addresse',
    'Phone Number': 'Telefonnummer',
    'Enter your email': 'Geben Sie Ihre E-Mail-Adresse ein',
    'Please enter a valid email': 'Bitte geben Sie eine gültige E-Mail-Adresse ein',
    'Submit': 'Absenden',
    'About Pimpt Up': 'Über Pimpt Up',
    'Date of Birth': 'Geburtsdatum',
    'Edit Details': 'Details bearbeiten',
    'Terms & Conditions of Pimpt Up': 'Geschäftsbedingungen von Pimpt Up',
    'Legal': 'Rechtlich',
    'Policies': 'Richtlinien',
    'Terms': 'Begriffe',
    'Privacy': 'Datenschutz',
    'Security': 'Sicherheit',
    'Old Password': 'Altes Passwort',
    'Enter Old Password': 'Altes Passwort eingeben',
    'New Password': 'Neues Passwort',
    'Enter New Password': 'Neues Passwort eingeben',
    'Confirm New Password': 'Neues Passwort bestätigen',
    'Enter Confirm New Password': 'Neues Passwort bestätigen eingeben',
    'Save': 'Speichern',
    'No Data Found': 'Keine Daten gefunden',
    'No Notifications Found': 'Keine Benachrichtigungen gefunden',
    'Where Quality Meets Assurance': 'Wo Qualität auf Sicherheit trifft',
    'Request New Quote': 'Neues Angebot anfordern',
    'View More': 'Mehr anzeigen',
    'Request Quote': 'Angebot anfordern',
    'Select Vehicle': 'Fahrzeug auswählen',
    'Category of the Part': 'Kategorie des Teils',
    'Request Name': 'Anforderungsname',
    'Part Number': 'Teilenummer',
    'Part Type': 'Teilart',
    'Blog': 'Blog',
    'Select Category': 'Kategorie auswählen',
    'Enter Request Name': 'Anforderungsnamen eingeben',
    'Enter Part Number': 'Teilenummer eingeben',
    'Login': 'Anmelden',
    'Sign Up': 'Melden Sie sich an',
    'Select Part Type': 'Teilart auswählen',
    'Logout': 'Ausloggen',
    'Change Password': 'Passwort ändern',
    'Profile': 'Profil',
    'Description': 'Beschreibung',
    'Enter Description': 'Beschreibung eingeben',
    'Document': 'Dokument',
    'Browse and choose the vehicle image you want to upload from your computer': 'Durchsuchen und wählen Sie das Fahrzeugbild aus, das Sie von Ihrem Computer hochladen möchten',
    'Request for Quote': 'Angebot anfordern',
    'Page not found!': 'Seite nicht gefunden!',
    "We couldn't find the page you were looking for": 'Wir konnten die von Ihnen gesuchte Seite nicht finden',
    'Back to home page': 'Zurück zur Startseite',
    'Value': 'Wert',
    'Add Vehicle': 'Fahrzeug hinzufügen',
    'Back': 'Zurück',
    'Continue': 'Weiter',
    'Your Booking ID': 'Ihre Buchungs-ID',
    'Booking successful': 'Buchung erfolgreich',
    'Permission': 'Berechtigung',
    'Notification': 'Benachrichtigung',
    'Email Notification': 'E-Mail-Benachrichtigung',
    'SMS Notification': 'SMS-Benachrichtigung',
    'Location': 'Standort',
    'No Notification Found': 'Keine Benachrichtigung gefunden',
    'No Order Found': 'Keine Bestellung gefunden',
    'Vehicle Type': 'Fahrzeugtyp',
    'Select Type': 'Typ auswählen',
    'Make': 'Marke',
    'Select Make': 'Marke auswählen',
    'Select Model': 'Modell auswählen',
    'Select Year': 'Jahr auswählen',
    'Model': 'Modell',
    'Year': 'Jahr',
    'Only Alphabetic Characters Allowed': 'Nur alphabtische Zeichen erlaubt',
    'Enter Full Name': 'Geben Sie den vollständigen Namen ein',
    'Name': 'Name',
    'Mobile number': 'Mobiltelefonnummer',
    'Please input your phone number!': 'Bitte geben Sie Ihre Telefonnummer ein!',
    'Phone number should be 7 to 15 digits': 'Die Telefonnummer sollte 7 bis 15 Ziffern haben',
    'Name should not contain more than 90 characters!': 'Der Name darf nicht mehr als 90 Zeichen enthalten!',
    'Name should contain at least 1 character!': 'Der Name sollte mindestens 1 Zeichen enthalten!',
    'Enter Your Email': 'Geben Sie Ihre E-Mail-Adresse ein',
    'Edit Profile': 'Profil bearbeiten',
    'Cancel': 'Abbrechen',
    'Save': 'Speichern',
    'VIN Number': 'VIN-Nummer',
    'Enter VIN Number': 'Geben Sie die VIN-Nummer ein',
    'Add New Vehicle': 'Neues Fahrzeug hinzufügen',
    'Manage Quotes': 'Angebote verwalten',
    'Requested': 'Angefragt',
    'Received': 'Empfangen',
    'Fulfilled': 'Erfüllt',
    'CHECK NOW': 'JETZT ÜBERPRÜFEN',
    'Product detail': 'Produktdetail',
    'Product Name': 'Produktname',
    'Brand': 'Marke',
    'Product Dimensions': 'Produktdimensionen',
    'No Specialist Found': 'Kein Spezialist gefunden',
    'No Dealer Found': 'Kein Händler gefunden',
    'Full Name': 'Vollständiger Name',
    'Your name will appear on your ticket': 'Ihr Name wird auf Ihrem Ticket angezeigt',
    'Mobile Number': 'Mobiltelefonnummer',
    'Search': 'Suche',
    'Select Country': 'Land auswählen',
    'NEXT': 'WEITER',
    'Enter Email Address': 'E-Mail-Adresse eingeben',
    'Please enter the name': 'Bitte geben Sie den Namen ein',
    'Please select country': 'Bitte wählen Sie das Land aus',
    'Quote ID': 'Angebots-ID',
    'Please Select Category': 'Bitte wählen Sie eine Kategorie aus',
    'Name is required': 'Der Name ist erforderlich',
    'Part Number is required': 'Die Teilenummer ist erforderlich',
    'Part Type is required': 'Die Teilart ist erforderlich',
    'Description is required': 'Die Beschreibung ist erforderlich',
    'Please Select an Image': 'Bitte wählen Sie ein Bild aus',
    'Please select vehicle type': 'Bitte wählen Sie den Fahrzeugtyp aus',
    'Please select vehicle maker': 'Bitte wählen Sie die Fahrzeugmarke aus',
    'Please select vehicle model': 'Bitte wählen Sie das Fahrzeugmodell aus',
    'Please select year': 'Bitte wählen Sie das Jahr aus',
    'Online': 'Online',
    'Type': 'Typ',
    'Please enter VIN number': 'Bitte geben Sie die VIN-Nummer ein',
    'Vehicle List': 'Fahrzeugliste',
    'Mark as Read All': 'Als „Alles lesen“ markieren',
    'of': 'von',
    'No Image Found': 'Kein Bild gefunden',
    'No Specialist Found': 'Kein Spezialist gefunden',
    'No Dealer Found': 'Kein Händler gefunden',
    'Chat': 'Plaudern',
    'Quote Request': 'Angebotsanfrage',
    'Product Categories': 'Produktkategorien',
    'No Products Found': 'Keine Produkte gefunden',
    'Parts': 'Teile',
    'Add to Fav': 'Zu Favoriten hinzufügen',
    'Product Catalogue': 'Produktkatalog',
    'Service Catalogue': 'Leistungskatalog',
    'No Categories Found': 'Keine Kategorien gefunden',
    'Specialist Replies': 'Fachliche Antworten',
    'No Reply Found': 'Keine Antwort gefunden',
    'New Price': 'Neuer Preis',
    'years': 'Jahre',
    'Used': 'Gebraucht',
    'Second Hand': 'Aus zweiter Hand',
    'New': 'Neu',
    'Browse and chose the part image you want to upload from your computer': 'Durchsuchen Sie das Teilbild, das Sie von Ihrem Computer hochladen möchten, und wählen Sie es aus',
    'Service Categories': 'Servicekategorien',
    'Services': 'Dienstleistungen',
    'Specialist Title': 'Facharzttitel',
    'My Profile': 'Mein Profil',
    'Price': 'Preis',
    'Blog Detail': 'Blog-Details',
    'Make & Model': 'Marke und Modell',
    'Related Blogs': 'Verwandte Blogs',
    'to': 'bis',
    'Password': 'Passwort',
    'Confirm Password': 'Passwort bestätigen',
    'Accept': 'Akzeptieren',
    'Please enter location': 'Bitte geben Sie den Standort ein',
    'Please enter name': 'Bitte Namen eingeben',
    'Please enter email': 'Bitte geben Sie Ihre E-Mail-Adresse ein',
    'Please enter mobile number': 'Bitte Handynummer eingeben',
    'Please enter minimum 7 number': 'Bitte geben Sie mindestens 7 Zahlen ein',
    'Please enter password': 'Bitte Passwort eingeben',
    'Please enter minimum 6 character password': 'Bitte geben Sie ein Passwort mit mindestens 6 Zeichen ein',
    'Please enter confirm password': 'Bitte geben Sie das Bestätigungspasswort ein',
    'Password not match': 'Passwort stimmt nicht überein',
    'Please add minimum one car': 'Bitte fügen Sie mindestens ein Auto hinzu',
    'Please Accept Terms & Conditions': 'Bitte akzeptieren Sie die Allgemeinen Geschäftsbedingungen',
    'Please enter valid email': 'Bitte eine gültige Email eingeben',
    'Already have an account': 'Sie haben bereits ein Konto',
    'Don’t have an account': 'Sie haben kein Konto',
    'Forgot Password': 'Passwort vergessen',
    'Remember Password': 'Passwort merken',
    'Send OTP': 'OTP senden',
    'Are you sure you want to Logout from your account': 'Sind Sie sicher, dass Sie sich von Ihrem Konto abmelden möchten',
    'You need to login again for continue usage of app if you logout': 'Wenn Sie sich abmelden, müssen Sie sich erneut anmelden, um die App weiterhin nutzen zu können',
    'Logout Account': 'Konto abmelden',
    'Are you sure you want to delete your account? Please read how account deletion will affect': 'Sind Sie sicher, dass Sie Ihr Konto löschen möchten? Bitte lesen Sie, welche Auswirkungen die Löschung Ihres Kontos hat',
    'Deleting your account removes personal information our database. Tour email becomes permanently reserved and same email cannot be re-use to register a new account': 'Durch das Löschen Ihres Kontos werden personenbezogene Daten aus unserer Datenbank entfernt. Die Tour-E-Mail-Adresse wird dauerhaft reserviert und dieselbe E-Mail-Adresse kann nicht für die Registrierung eines neuen Kontos erneut verwendet werden',
    'Password must be at least 6 characters long': 'Das Passwort muss mindestens 6 Zeichen lang sein',
    'Passwords must match': 'Die Passwörter müssen übereinstimmen',
    'Delete All': 'Alles löschen',
    "Enter VIN Number": "Geben Sie die VIN-Nummer ein",
    "Enter Password": "Geben Sie das Passwort ein",
    "Enter Name": "Geben Sie den Namen ein",
    "Enter Location": "Geben Sie den Ort ein",
    "Enter Confirm Password": "Bestätigen Sie das Passwort",
    'Logout Successfully': 'Erfolgreich abgemeldet',
    'Vehicle Added Successfully': 'Fahrzeug erfolgreich hinzugefügt.',
    'Remember Me': 'Erinnere dich an mich',
    'OTP Verification': 'OTP-Verifizierung',
    'Didn\'t Receive OTP': 'Habe kein OTP erhalten',
    'Resend': 'Erneut senden',
    'Delete': 'löschen',
    'Please Enter old password': 'Bitte geben Sie das alte Passwort ein',
    'Please Enter new password': 'Bitte geben Sie ein neues Passwort ein',
    'Confirm Password is required': 'Bestätigen Sie, dass ein Passwort erforderlich ist',
    'Verify OTP': 'OTP überprüfen',
    'Both': 'Beide',
    'Status': 'Status',
    'RECEIVED': 'ERHALTEN',
    'REQUEST': 'ANFRAGE',
    'Final Price': 'Endpreis',
    "FAQs": "FAQ",
    "Frequently Asked Questions": "Häufig gestellte Fragen",
    "Question": "Frage",
    "Answer": "Antwort",
    "Quote sended with quoteId": "Angebot mit quoteId gesendet",
    "Your Quote has been sended successfully": "Ihr Angebot wurde erfolgreich gesendet",
    "Your Quote is sended successfully": "Ihr Angebot wurde erfolgreich gesendet",
    "New Message Recieved": "Neue Nachricht erhalten",
    "Quote sended with quoteId": "Angebot mit quoteId gesendet",
    "You have send a new quote for quoteId": "Sie haben ein neues Angebot für quoteId gesendet",
    "You have sent a new quote for quoteId": "Sie haben ein neues Angebot für quoteId gesendet",
    "You have sent a quote": "Sie haben ein Angebot gesendet",
    "You have received new quote for quoteId": "Sie haben ein neues Angebot für quoteId erhalten",
    "You have received quote for quoteId": "Sie haben ein neues Angebot für quoteId erhalten",
    "You have received quote from": "Sie haben ein Angebot von erhalten",
    "Welcome new member": "Willkommen neues Mitglied",
    "If you want to see more details, click on the notification image. It will redirect you to the Quote Details Page": "Wenn Sie weitere Details sehen möchten, klicken Sie auf das Benachrichtigungsbild. Sie werden zur Seite mit den Angebotsdetails weitergeleitet",
    "Welcome! We're excited to have you join us. Explore, connect, and enjoy your time here at PimptUp. If you need help, our support team is here for you. Happy exploring!": "Willkommen! Wir freuen uns, Sie bei uns begrüßen zu dürfen. Entdecken Sie, vernetzen Sie sich und genießen Sie Ihre Zeit hier bei PimptUp. Wenn Sie Hilfe benötigen, ist unser Support-Team für Sie da. Viel Spaß beim Entdecken!",
};

export default de;
import React, { useContext, useEffect, useState } from "react";
import Dropdown from 'react-bootstrap/Dropdown';
import chatIcon from '../Assets/img/icon/chatIcon.svg';
import useRequest from "../hooks/useRequest";
import apiPath from "../constants/apiPath";
import { useParams } from "react-router-dom";
import Banners from "../Components/banners/Banners";
import ProvidersCard from "../Components/providers/ProvidersCard";
import Loader from "../Components/Loader";
import lang from "../helper/lang";
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import shortingIcon from '../Assets/img/icon/shortingIcon.svg';
import NoDataFound from "../Assets/img/NotFound.png";
import filterIcon from '../Assets/img/icon/filterIcon.svg';
import searchIcon from '../Assets/img/icon/searchIcon.svg';
import { AuthContext } from "../context/AuthContext";

const ProviderList = () => {
    const { type } = useParams()
    const [loading, setLoading] = useState(false);
    const [provider, setProvider] = useState([]);
    const { request } = useRequest();
    const { longitude, latitude } = useContext(AuthContext);
    const [model, setModel] = useState([]);
    const [make, setMake] = useState([]);
    const [types, setTypes] = useState([]);
    const [variant, setVariant] = useState([]);
    const [categories, setCategories] = useState([]);
    const [show, setShow] = useState(false);
    const [cId, setCID] = useState();
    const [tpId, setTPID] = useState();
    const [mkId, setMKID] = useState();
    const [dealIn, setDealIn] = useState();
    const [mdId, setMDID] = useState();
    const [vnId, setVNID] = useState();
    const [prType, setPrType] = useState();
    const [location, setLocation] = useState();
    const [flag, setFlag] = useState("product");

    useEffect(() => {
        getCategory();
    }, [flag]);

    useEffect(() => {
        getType();
    }, []);

    useEffect(() => {
        getProviders();
    }, [type]);

    const getCategory = () => {
        request({
            url: apiPath.categoryList + '?type=' + flag,
            method: "GET",
            onSuccess: (data) => {
                if (data.status) {
                    setCategories(data.data);
                }
            },
            onError: (err) => {
                console.log(err);
            }
        })
    }

    const getType = () => {
        request({
            url: apiPath.carTypeList,
            method: "GET",
            onSuccess: (data) => {
                if (data.status) {
                    setTypes(data.data);
                }
            },
            onError: (err) => {
                console.log(err);
            },
        });
    };

    const getVariant = (id) => {
        request({
            url: apiPath.carVariantList + `/${id}`,
            method: "GET",
            onSuccess: (data) => {
                setVariant(data.data);
            },
            onError: (err) => {
                console.log(err);
            },
        });
    };

    const getModel = (id) => {
        request({
            url: apiPath.carModelList + `/${id}`,
            method: "GET",
            onSuccess: (data) => {
                if (data.status) {
                    setModel(data.data);
                }
            },
            onError: (err) => {
                console.log(err);
            }
        })
    }

    const getMake = (id) => {
        request({
            url: apiPath.carMakeList + `?type=${id}`,
            method: "GET",
            onSuccess: (data) => {
                if (data.status) {
                    setMake(data.data);
                }
            },
            onError: (err) => {
                console.log(err);
            }
        })
    }

    const handleApply = () => {
        setLoading(true);
        let urlArray = [];
        if (tpId) {
            urlArray.push({ param: "type", value: tpId })
        }
        if (mkId) {
            urlArray.push({ param: "make", value: mkId })
        }
        if (mdId) {
            urlArray.push({ param: "model", value: mdId })
        }
        if (vnId) {
            urlArray.push({ param: "variant", value: vnId })
        }
        if (prType) {
            urlArray.push({ param: "prType", value: prType })
        }
        if (location) {
            urlArray.push({ param: "location", value: location })
        }
        if (dealIn) {
            urlArray.push({ param: "dealIn", value: dealIn })
        }
        if (flag === "product" && cId) {
            urlArray.push({ param: "prCategory", value: cId })
        }
        else if (flag === "service" && cId) {
            urlArray.push({ param: "srCategory", value: cId })
        }

        let queryParams = (type === "dealer" ? apiPath.allDealers : apiPath.allSpecialists);
        urlArray.length && urlArray?.forEach((elem, idx) => {
            if (idx === 0) {
                queryParams += `?${elem.param}=${elem.value}`
            } else {
                queryParams += `&&${elem.param}=${elem.value}`
            }
        })

        console.log('queryParams', queryParams);
        setShow(false);
        request({
            url: queryParams,
            method: "GET",
            onSuccess: (data) => {
                if (data.status) {
                    console.log(data.data)
                    setProvider(data.data)
                    setLoading(false)
                }
            },
            onError: (err) => {
                setLoading(false)
            }
        })
    }

    const getProviders = () => {
        setLoading(true);
        request({
            url: (type === "dealer" ? apiPath.allDealers : apiPath.allSpecialists),
            method: "GET",
            onSuccess: (data) => {
                if (data.status) {
                    console.log(data.data)
                    setProvider(data.data)
                    setLoading(false)
                }
            },
            onError: (err) => {
                setLoading(false)
            }
        })
    }

    const handleSort = (val) => {
        setLoading(true);
        request({
            url: (type === "dealer" ? apiPath.allDealers : apiPath.allSpecialists) + `?sort=${val}`,
            method: "GET",
            onSuccess: (data) => {
                if (data.status) {
                    console.log(data.data)
                    setProvider(data.data)
                    setLoading(false)
                }
            },
            onError: (err) => {
                setLoading(false)
            }
        })
    }

    const handleSearch = (val) => {
        request({
            url: (type === "dealer" ? apiPath.allDealers : apiPath.allSpecialists) + `?name=` + val,
            method: "GET",
            onSuccess: (data) => {
                if (data.status) {
                    setProvider(data.data);
                }
            },
            onError: (err) => {
                console.log(err);
            }
        })
    }

    const handleReset = () => {
        setCID(''); setMDID(''); setDealIn(''); setVNID(''); setTPID(''); setMKID(''); setPrType(''); setLocation();
        setCategories([]); setModel([]); setMake([]); setTypes([]); setVariant([]);
        getType(); getCategory(); getProviders();
    }

    return (
        <>
            <div onClick={() => setShow(false)} className="mainWraper">
                <div className="otherSliderMain sectionPading bgLight">
                    <Banners />
                </div>

                <div className="sectionPading">
                    <div className="container">
                        <div className="sectionHeading">
                            <div className="row align-items-center">
                                <div className="col">
                                    <div className="title d-inline-block">
                                        <div className="txt">{type === "specialist" ? lang("Specialists") : lang("Dealers")}</div>
                                        <div className="titleDesign d-flex align-items-center gap-2 mt-3"></div>
                                    </div>
                                </div>
                                <div className="col-auto">
                                    <div className="position-relative">
                                        <div onClick={(e) => { setShow(x => !x); e.stopPropagation(); }} className="p0 searchBtn d-flex align-items-cneter justify-content-center border-0 shadow-none">
                                            <img className="" src={filterIcon} alt="" />
                                        </div>
                                        <div onClick={(e) => { setShow(true); e.stopPropagation(); }} className={`${!show ? 'd-none' : ''} filterWrap-cls`}>
                                            <div style={{ marginBottom: 10, display: "flex", justifyContent: "space-between" }}>
                                                <div style={{ width: "50%", display: "flex", justifyContent: "space-around", alignItems: 'center' }}>
                                                    <label>Product</label>
                                                    <input defaultChecked style={{ width: "20px", margin: 'auto' }} type="radio" name="ctg-type" onChange={(e) => {
                                                        if (e.target.checked) {
                                                            setFlag("product");
                                                        }
                                                    }} />
                                                </div>
                                                {type !== 'dealer' && (<div style={{ width: "50%", display: "flex", justifyContent: "space-between", alignItems: 'center' }}>
                                                    <label>Service</label>
                                                    <input style={{ width: "20px", margin: 'auto' }} type="radio" name="ctg-type" onChange={(e) => {
                                                        if (e.target.checked) {
                                                            setFlag("service");
                                                        }
                                                    }} />
                                                </div>)}
                                            </div>
                                            <div>
                                                <select value={cId} onChange={(e) => { setCID(e.target.value); console.log(cId); }}>
                                                    <option value={''}>Category</option>
                                                    {
                                                        categories.length && (categories?.map((ctg, idx) => {
                                                            return <option key={idx} value={ctg?._id}><input type="checkbox" width={20} />{ctg?.name}</option>
                                                        }))
                                                    }
                                                </select>
                                            </div>
                                            <div className="d-flex gap-2">
                                                <select value={tpId} onChange={(e) => { setTPID(e.target.value); getMake(e.target.value) }}>
                                                    <option value={''}>Type</option>
                                                    {
                                                        types.length && (types?.map((tp, idx) => {
                                                            return <option key={idx} value={tp?._id}><input type="checkbox" width={20} />{tp?.name}</option>
                                                        }))
                                                    }
                                                </select>
                                                <select value={mkId} onChange={(e) => { setMKID(e.target.value); getModel(e.target.value) }}>
                                                    <option value={''}>Make</option>
                                                    {
                                                        make.length && (make?.map((mk, idx) => {
                                                            return <option key={idx} value={mk?._id}><input type="checkbox" width={20} />{mk?.name}</option>
                                                        }))
                                                    }
                                                </select>
                                            </div>
                                            <div className="d-flex gap-2">
                                                <select value={mdId} onChange={(e) => { setMDID(e.target.value); getVariant(e.target.value) }}>
                                                    <option value={''}>Model</option>
                                                    {
                                                        model.length && (model?.map((md, idx) => {
                                                            return <option key={idx} value={md?._id}><input type="checkbox" width={20} />{md?.name}</option>
                                                        }))
                                                    }
                                                </select>
                                                <select value={vnId} onChange={(e) => { setVNID(e.target.value); }}>
                                                    <option value={''}>Variant</option>
                                                    {
                                                        variant.length && (variant?.map((vn, idx) => {
                                                            return <option key={idx} value={vn?._id}><input type="checkbox" width={20} />{vn?.name}</option>
                                                        }))
                                                    }
                                                </select>
                                            </div>
                                            {type !== 'dealer' && (<div>
                                                <select value={dealIn} onChange={(e) => { setDealIn(e.target.value); }}>
                                                    <option value={''}>Deal In</option>
                                                    <option value='products'><input type="checkbox" width={20} />Products</option>
                                                    <option value='service'><input type="checkbox" width={20} />Service</option>
                                                    <option value='both'><input type="checkbox" width={20} />Both</option>

                                                </select>
                                            </div>)}

                                            {dealIn != 'service' &&
                                                <div>
                                                    <select value={prType} onChange={(e) => setPrType(e.target.value)}>
                                                        <option value={''}>Product Type</option>
                                                        <option value='new'><input type="checkbox" width={20} />New</option>
                                                        <option value='used'><input type="checkbox" width={20} />Used</option>
                                                        <option value='both'><input type="checkbox" width={20} />Both</option>

                                                    </select>
                                                </div>
                                            }
                                            <div className="border-bottom d-flex">
                                                <button className="bg-danger text-light" onClick={() => handleReset()}>Reset</button>
                                                <button className="bg-success text-light" onClick={(e) => { e.stopPropagation(); handleApply() }}>Apply</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-auto d-flex align-items-center gap-3">
                                    <InputGroup className="position-relative">
                                        <Form.Control type="search" id='headerSearch' onChange={(e) => handleSearch(e.target.value)} className="rounded-1 pe-5" placeholder={lang("Search") + "..."} />
                                        <label for="headerSearch" className="btn searchBtn d-flex align-items-cneter justify-content-center m-0 rounded-1 border-0 shadow-none position-absolute end-0 top-0 bottom-0 me-1 my-auto">
                                            <img className="w-100" src={searchIcon} alt="search" />
                                        </label>
                                    </InputGroup>
                                    <Dropdown className="filterDrop shortBy">
                                        <Dropdown.Toggle id="dropdown-basic" className="p0 searchBtn d-flex align-items-cneter justify-content-center border-0 shadow-none">
                                            <img className="" src={shortingIcon} alt="" style={{ filter: "invert(1)" }} />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu className="border-0 p-0">
                                            <Dropdown.Item className="border-bottom" onClick={() => handleSort('A-Z')} href="#sort=A-Z">A {lang("to")} Z</Dropdown.Item>
                                            <Dropdown.Item className="border-bottom" onClick={() => handleSort('Z-A')} href="#sort=Z-A">Z {lang("to")} A</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="dealerWraper mt-2">
                    {loading
                        ? (<Loader />)
                        : (<div className="container">
                            <div className="dealerOuter">
                                <div className="row g-3 row-cols-xs-2 row-cols-3 row-cols-md-4 row-cols-lg-5 row-cols-xl-6 row-cols-xxxl-8">
                                    {provider?.length ? provider?.map((pr, idx) => {
                                        return (
                                            <ProvidersCard pr={pr} favourite={pr?.is_wishlist} />
                                        )
                                    }) : (
                                        <div>
                                            <img width={120} src={NoDataFound} alt="" />
                                            <h6 className='pt-2'>{lang("No Data Found")}</h6>
                                        </div>
                                    )
                                    }
                                </div>
                            </div>
                        </div>)}
                </div>
            </div >

        </>
    )
}

export default ProviderList;

const es = {
    'Home': 'Inicio',
    'Quotes': 'Citas',
    'English': 'Inglés',
    'Spanish': 'Español',
    'French': 'Francés',
    'German': 'Alemán',
    'Messages': 'Mensajes',
    'Notifications': 'Notificaciones',
    'Categories': 'Categorías',
    'Brands': 'Marcas',
    'Products': 'Productos',
    'Specialists': 'Especialistas',
    'See All': 'Ver Todo',
    'Dealers': 'Distribuidores',
    'Setting': 'Ajustes',
    'Log Out': 'Cerrar sesión',
    'Log In': 'Iniciar sesión',
    'Account': 'Cuenta',
    'My Vehicles': 'Mis vehículos',
    'Favourites': 'Favoritos',
    'Blogs': 'Blogs',
    'Delete Account': 'Eliminar cuenta',
    'Pimpt-Up 2023, All Rights Reserved': 'Pimpt-Up 2023, Todos los derechos reservados',
    'Profile': 'Perfil',
    'About Us': 'Acerca de nosotros',
    'Contact Us': 'Contáctenos',
    'Terms & Conditions': 'Términos y condiciones',
    'Privacy Policy': 'Política de privacidad',
    'Cancellation Policy': 'Política de cancelación',
    'Help Center': 'Centro de ayuda',
    'Contact': 'Contacto',
    'Tel': 'Teléfono',
    'Mail': 'Correo',
    'Address': 'Dirección',
    'Fax': 'Fax',
    'Full Name': 'Nombre completo',
    'Email Address': 'Dirección de correo electrónico',
    'Phone Number': 'Número de teléfono',
    'Enter your email': 'Ingrese su correo electrónico',
    'Please enter a valid email': 'Por favor ingrese un correo electrónico válido',
    'Submit': 'Enviar',
    'About Pimpt Up': 'Acerca de Pimpt Up',
    'Date of Birth': 'Fecha de nacimiento',
    'Edit Details': 'Editar detalles',
    'Terms & Conditions of Pimpt Up': 'Términos y condiciones de Pimpt Up',
    'Legal': 'Legal',
    'Policies': 'Políticas',
    'Terms': 'Términos',
    'Privacy': 'Privacidad',
    'Security': 'Seguridad',
    'Old Password': 'Contraseña anterior',
    'Enter Old Password': 'Ingrese contraseña anterior',
    'New Password': 'Contraseña nueva',
    'Enter New Password': 'Ingrese contraseña nueva',
    'Confirm New Password': 'Confirmar contraseña nueva',
    'Enter Confirm New Password': 'Ingrese confirmación de contraseña nueva',
    'Save': 'Guardar',
    'No Data Found': 'No se encontraron datos',
    'No Notifications Found': 'No se encontraron notificaciones',
    'Where Quality Meets Assurance': 'Donde calidad se encuentra con garantía',
    'Request New Quote': 'Solicitar nueva cotización',
    'View More': 'Ver más',
    'Request Quote': 'Solicitar cotización',
    'Select Vehicle': 'Seleccionar vehículo',
    'Category of the Part': 'Categoría de la parte',
    'Request Name': 'Nombre de la solicitud',
    'Part Number': 'Número de parte',
    'Part Type': 'Tipo de parte',
    'Blog': 'Blog',
    'Select Category': 'Seleccionar categoría',
    'Enter Request Name': 'Ingrese nombre de solicitud',
    'Enter Part Number': 'Ingrese número de parte',
    'Login': 'Iniciar sesión',
    'Sign Up': 'Inscribirse',
    'Select Part Type': 'Seleccionar tipo de parte',
    'Logout': 'Cerrar sesión',
    'Change Password': 'Cambiar contraseña',
    'Profile': 'Perfil',
    'Description': 'Descripción',
    'Enter Description': 'Ingrese descripción',
    'Document': 'Documento',
    'Browse and choose the vehicle image you want to upload from your computer': 'Navegue y elija la imagen del vehículo que desea cargar desde su computadora',
    'Request for Quote': 'Solicitud de cotización',
    'Page not found!': '¡Página no encontrada!',
    "We couldn't find the page you were looking for": 'No pudimos encontrar la página que buscaba',
    'Back to home page': 'Volver a la página de inicio',
    'Value': 'Valor',
    'Add Vehicle': 'Agregar vehículo',
    'Back': 'Volver',
    'Continue': 'Continuar',
    'Your Booking ID': 'Su ID de reserva',
    'Booking successful': 'Reserva exitosa',
    'Permission': 'Permiso',
    'Notification': 'Notificación',
    'Email Notification': 'Notificación por correo electrónico',
    'SMS Notification': 'Notificación por SMS',
    'Location': 'Ubicación',
    'No Notification Found': 'No se encontraron notificaciones',
    'No Order Found': 'No se encontraron órdenes',
    'Vehicle Type': 'Tipo de vehículo',
    'Select Type': 'Seleccionar tipo',
    'Make': 'Marca',
    'Select Make': 'Seleccionar marca',
    'Select Model': 'Seleccionar modelo',
    'Select Year': 'Seleccionar año',
    'Model': 'Modelo',
    'Year': 'Año',
    'Only Alphabetic Characters Allowed': 'Solo se permiten caracteres alfabéticos',
    'Enter Full Name': 'Ingrese nombre completo',
    'Name': 'Nombre',
    'Mobile number': 'Número de teléfono móvil',
    'Please input your phone number!': '¡Por favor ingrese su número de teléfono!',
    'Phone number should be 7 to 15 digits': 'El número de teléfono debe tener de 7 a 15 dígitos',
    'Name should not contain more than 90 characters!': 'El nombre no debe contener más de 90 caracteres',
    'Name should contain at least 1 character!': 'El nombre debe contener al menos 1 caracter',
    'Enter Your Email': 'Ingrese su correo electrónico',
    'Edit Profile': 'Editar perfil',
    'Cancel': 'Cancelar',
    'Save': 'Guardar',
    'VIN Number': 'Número VIN',
    'Enter VIN Number': 'Ingrese número VIN',
    'Add New Vehicle': 'Agregar nuevo vehículo',
    'Manage Quotes': 'Gestionar cotizaciones',
    'Requested': 'Solicitado',
    'Received': 'Recibido',
    'Fulfilled': 'Cumplido',
    'CHECK NOW': 'VER AHORA',
    'Product detail': 'Detalle del producto',
    'Product Name': 'Nombre del producto',
    'Brand': 'Marca',
    'Product Dimensions': 'Dimensiones del producto',
    'No Specialist Found': 'No se encontraron especialistas',
    'No Dealer Found': 'No se encontraron distribuidores',
    'Full Name': 'Nombre completo',
    'Your name will appear on your ticket': 'Su nombre aparecerá en su boleto',
    'Mobile Number': 'Número de teléfono móvil',
    'Search': 'Buscar',
    'Select Country': 'Seleccionar país',
    'NEXT': 'SIGUIENTE',
    'Enter Email Address': 'Ingrese dirección de correo electrónico',
    'Please enter the name': 'Por favor ingrese el nombre',
    'Please select country': 'Por favor seleccione el país',
    'Quote ID': 'ID de cotización',
    'Please Select Category': 'Por favor seleccione la categoría',
    'Name is required': 'Se requiere el nombre',
    'Part Number is required': 'Se requiere el número de parte',
    'Part Type is required': 'Se requiere el tipo de parte',
    'Description is required': 'Se requiere la descripción',
    'Please Select an Image': 'Por favor seleccione una imagen',
    'Please select vehicle type': 'Por favor seleccione el tipo de vehículo',
    'Please select vehicle maker': 'Por favor seleccione la marca del vehículo',
    'Please select vehicle model': 'Por favor seleccione el modelo del vehículo',
    'Please select year': 'Por favor seleccione el año',
    'Online': 'En línea',
    'Type': 'Tipo',
    'Please enter VIN number': 'Por favor ingrese el número VIN',
    'Vehicle List': 'Lista de vehículos',
    'Mark as Read All': 'Marcar como leído todo',
    'of': 'de',
    'No Image Found': 'No se encontró ninguna imagen',
    'No Specialist Found': 'No se encontró ningún especialista',
    'No Dealer Found': 'No se encontró ningún distribuidor',
    'Chat': 'Charlar',
    'Quote Request': 'Solicitud de cotización',
    'Product Categories': 'Categorías de Producto',
    'No Products Found': 'No se encontraron productos',
    'Parts': 'Partes',
    'Add to Fav': 'Añadir a favoritos',
    'Product Catalogue': 'Catálogo de productos',
    'Service Catalogue': 'Catálogo de servicios',
    'No Categories Found': 'No se encontraron categorías',
    'Specialist Replies': 'Respuestas de especialistas',
    'No Reply Found': 'No se encontró respuesta',
    'New Price': 'Nuevo precio',
    'years': 'años',
    'Used': 'Usado',
    'Second Hand': 'Segunda mano',
    'New': 'Nuevo',
    'Browse and chose the part image you want to upload from your computer': 'Busque y elija la imagen de la pieza que desea cargar desde su computadora',
    'Service Categories': 'Categorías de servicio',
    'Services': 'Servicios',
    'Specialist Title': 'Título de especialista',
    'My Profile': 'Mi perfil',
    'Price': 'Precio',
    'Blog Detail': 'Detalle del blog',
    'Make & Model': 'Haz un modelo',
    'Related Blogs': 'Blogs relacionados',
    'to': 'a',
    'Password': 'Contraseña',
    'Confirm Password': 'confirmar Contraseña',
    'Accept': 'Aceptar',
    'Please enter location': 'Por favor ingresa la ubicación',
    'Please enter name': 'Por favor ingrese el nombre',
    'Please enter email': 'Por favor ingrese el correo electrónico',
    'Please enter mobile number': 'Por favor introduce el número de móvil',
    'Please enter minimum 7 number': 'Por favor ingrese mínimo 7 números',
    'Please enter password': 'Por favor, ingrese contraseña',
    'Please enter minimum 6 character password': 'Por favor ingrese una contraseña mínima de 6 caracteres',
    'Please enter confirm password': 'Por favor ingrese confirmar contraseña',
    'Password not match': 'La contraseña no coincide',
    'Please add minimum one car': 'Por favor agregue mínimo un auto',
    'Please Accept Terms & Conditions': 'Por favor acepte los términos y condiciones',
    'Please enter valid email': 'Por favor introduzca un correo electrónico válido',
    'Already have an account': 'Ya tienes una cuenta',
    'Don’t have an account': 'No tienes una cuenta',
    'Forgot Password': 'Has olvidado tu contraseña',
    'Remember Password': 'Recordar contraseña',
    'Send OTP': 'Enviar OTP',
    'Are you sure you want to Logout from your account': '¿Está seguro de que desea cerrar sesión en su cuenta?',
    'You need to login again for continue usage of app if you logout': 'Debe iniciar sesión nuevamente para continuar usando la aplicación si cierra sesión',
    'Logout Account': 'Konto abmelden',
    'Are you sure you want to delete your account? Please read how account deletion will affect': '¿Estás seguro de que quieres eliminar tu cuenta? Lea cómo afectará la eliminación de la cuenta',
    'Deleting your account removes personal information our database. Tour email becomes permanently reserved and same email cannot be re-use to register a new account': 'Al eliminar su cuenta se elimina información personal de nuestra base de datos. El correo electrónico del recorrido queda reservado permanentemente y el mismo correo electrónico no se puede reutilizar para registrar una nueva cuenta.',
    'Please Enter old password': 'Por favor ingrese la contraseña anterior',
    'Password must be at least 6 characters long': 'La contraseña debe contener 6 caracteres como mínimo',
    'Passwords must match': 'Las contraseñas deben coincidir',
    'Delete All': 'Eliminar todos',
    "Enter VIN Number": "Ingresar número de VIN",
    "Enter Password": "Ingresar contraseña",
    "Enter Name": "Ingresar nombre",
    "Enter Location": "Ingresar la ubicación",
    "Enter Confirm Password": "Confirmar contraseña",
    "Logout Successfully": "Cerrar sesión exitosamente.",
    'Vehicle Added Successfully': 'Vehículo añadido con éxito.',
    'Remember Me': 'Acuérdate de mí',
    'OTP Verification': 'Verificación OTP',
    'Didn\'t Receive OTP': 'No recibí OTP',
    'Resend': 'Reenviar',
    'Delete': 'Borrar',
    'Please Enter new password': 'Por favor ingrese una nueva contraseña',
    'Confirm Password is required': 'Confirmar Se requiere contraseña',
    'Verify OTP': 'Verificar OTP',
    'Both': 'Ambos',
    'Status': 'Estado',
    'RECEIVED': 'RECIBIÓ',
    'REQUEST': 'PEDIDO',
    'Final Price': 'Precio final',
    "FAQs": "Preguntas frecuentes",
    "Frequently Asked Questions": "Preguntas frecuentes",
    "Question": "Pregunta",
    "Answer": "Respuesta",
    "Quote sended with quoteId": "Cotización enviada con quoteId",
    "Your Quote is sended successfully": "Su cotización se envió exitosamente",
    "Your Quote has been sended successfully": "Su cotización se envió exitosamente",
    "New Message Recieved": "Nuevo mensaje recibido",
    "Quote sended with quoteId": "Cotización enviada con quoteId",
    "You have send a new quote for quoteId": "Ha enviado una nueva cotización para quoteId",
    "You have sent a new quote for quoteId": "Ha enviado una nueva cotización para quoteId",
    "You have sent a quote": "Has enviado una cotización",
    "You have received new quote for quoteId": "Ha recibido una nueva cotización para quoteId",
    "You have received quote for quoteId": "Ha recibido una nueva cotización para quoteId",
    "You have received quote from": "Has recibido cotización de",
    "Welcome new member": "Bienvenido nuevo miembro",
    "Welcome! We're excited to have you join us. Explore, connect, and enjoy your time here at PimptUp. If you need help, our support team is here for you. Happy exploring!": "¡Bienvenido! Estamos emocionados de que te unas a nosotros. Explora, conéctate y disfruta de tu tiempo aquí en PimptUp. Si necesita ayuda, nuestro equipo de soporte está aquí para ayudarlo. ¡Feliz exploración!",
    "If you want to see more details, click on the notification image. It will redirect you to the Quote Details Page": "Si quieres ver más detalles, haz clic en la imagen de notificación. Lo redireccionará a la página de detalles de la cotización.",

};

export default es;

const en = {
    'Home': 'Home',
    'Quotes': 'Quotes',
    'English': 'English',
    'German': 'German',
    'Spanish': 'Spanish',
    'French': 'French',
    'Messages': 'Messages',
    'Notifications': 'Notifications',
    'Categories': 'Categories',
    'Brands': 'Brands',
    'Products': 'Products',
    'Specialists': 'Specialists',
    'See All': 'See All',
    'Dealers': 'Dealers',
    'Setting': 'Setting',
    'Log Out': 'Log Out',
    'Log In': 'Log In',
    'Account': 'Account',
    'My Vehicles': 'My Vehicles',
    'Favourites': 'Favourites',
    'Blogs': 'Blogs',
    'Delete Account': 'Delete Account',
    'Pimpt-Up 2023, All Rights Reserved': 'Pimpt-Up 2023, All Rights Reserved',
    'Profile': 'Profile',
    'About Us': 'About Us',
    'Contact Us': 'Contact Us',
    'Terms & Conditions': 'Terms & Conditions',
    'Privacy Policy': 'Privacy Policy',
    'Cancellation Policy': 'Cancellation Policy',
    'Help Center': 'Help Center',
    'Contact': 'Contact',
    'Tel': 'Tel',
    'Mail': 'Mail',
    'Address': 'Address',
    'Fax': 'Fax',
    'Full Name': 'Full Name',
    'Email Address': 'Email Address',
    'Phone Number': 'Phone Number',
    'Enter your email': 'Enter your email',
    'Please enter a valid email': 'Please enter a valid email',
    'Submit': 'Submit',
    'About Pimpt Up': 'About Pimpt Up',
    'Date of Birth': 'Date of Birth',
    'Edit Details': 'Edit Details',
    'Terms & Conditions of Pimpt Up': 'Terms & Conditions of Pimpt Up',
    'Legal': 'Legal',
    'Policies': 'Policies',
    'Terms': 'Terms',
    'Privacy': 'Privacy',
    'Security': 'Security',
    'Old Password': 'Old Password',
    'Enter Old Password': 'Enter Old Password',
    'New Password': 'New Password',
    'Enter New Password': 'Enter New Password',
    'Confirm New Password': 'Confirm New Password',
    'Enter Confirm New Password': 'Enter Confirm New Password',
    'Save': 'Save',
    'No Data Found': 'No Data Found',
    'No Notifications Found': 'No Notifications Found',
    'Where Quality Meets Assurance': 'Where Quality Meets Assurance',
    'Request New Quote': 'Request New Quote',
    'View More': 'View More',
    'Request Quote': 'Request Quote',
    'Select Vehicle': 'Select Vehicle',
    'Category of the Part': 'Category of the Part',
    'Request Name': 'Request Name',
    'Part Number': 'Part Number',
    'Part Type': 'Part Type',
    'Blog': 'Blog',
    'Select Category': 'Select Category',
    'Enter Request Name': 'Enter Request Name',
    'Enter Part Number': 'Enter Part Number',
    'Login': 'Login',
    'Sign Up': 'Sign Up',
    'Select Part Type': 'Select Part Type',
    'Logout': 'Logout',
    'Change Password': 'Change Password',
    'Profile': 'Profile',
    'Description': 'Description',
    'Enter Description': 'Enter Description',
    'Document': 'Document',
    'Browse and chose the part image you want to upload from your computer': 'Browse and chose the part image you want to upload from your computer',
    'Request for Quote': 'Request for Quote',
    'Page not found!': 'Page not found!',
    "We couldn't find the page you were looking for": "We couldn't find the page you were looking for",
    'Back to home page': 'Back to home page',
    'Value': 'Value',
    'Add Vehicle': 'Add Vehicle',
    'Back': 'Back',
    'Continue': 'Continue',
    'Your Booking ID': 'Your Booking ID',
    'Booking successful': 'Booking successful',
    'Permission': 'Permission',
    'Notification': 'Notification',
    'Email Notification': 'Email Notification',
    'SMS Notification': 'SMS Notification',
    'Location': 'Location',
    'No Notification Found': 'No Notification Found',
    'No Order Found': 'No Order Found',
    'Vehicle Type': 'Vehicle Type',
    'Select Type': 'Select Type',
    'Make': 'Make',
    'Select Make': 'Select Make',
    'Select Model': 'Select Model',
    'Select Year': 'Select Year',
    'Model': 'Model',
    'Year': 'Year',
    'Only Alphabetic Characters Allowed': 'Only Alphabetic Characters Allowed',
    'Enter Full Name': 'Enter Full Name',
    'Name': 'Name',
    'Mobile number': 'Mobile number',
    'Please input your phone number!': 'Please input your phone number!',
    'Phone number should be 7 to 15 digits': 'Phone number should be 7 to 15 digits',
    'Name should not contain more then 90 characters!': 'Name should not contain more then 90 characters!',
    'Name should contain at least 1 characters!': 'Name should contain at least 1 characters!',
    'Enter Your Email': 'Enter Your Email',
    'Edit Profile': 'Edit Profile',
    'Cancel': 'Cancel',
    'Save': 'Save',
    'VIN Number': 'VIN Number',
    'Enter VIN Number': 'Enter VIN Number',
    'Add New Vehicle': 'Add New Vehicle',
    'Manage Quotes': 'Manage Quotes',
    'Requested': 'Requested',
    'Received': 'Received',
    'Fulfilled': 'Fulfilled',
    'CHECK NOW': 'CHECK NOW',
    'Product detail': 'Product detail',
    'Product Name': 'Product Name',
    'Brand': 'Brand',
    'Product Dimensions': 'Product Dimensions',
    'No Specialist Found': 'No Specialist Found',
    'No Dealer Found': 'No Dealer Found',
    'Full Name': 'Full Name',
    'Your name will appear on your ticket': 'Your name will appear on your ticket',
    'Mobile Number': 'Mobile Number',
    "Search": "Search",
    'Select Country': 'Select Country',
    'NEXT': 'NEXT',
    'Enter Email Address': 'Enter Email Address',
    'Please enter the name': 'Please enter the name',
    'Please select country': 'Please select country',
    'Quote ID': 'Quote ID',
    'Please Select Category': 'Please Select Category',
    'Name is required': 'Name is required',
    'Part Number is required': 'Part Number is required',
    'Part Type is required': 'Part Type is required',
    'Description is required': 'Description is required',
    'Please Select an Image': 'Please Select an Image',
    'Please select vehicle type': 'Please select vehicle type',
    'Please select vehicle maker': 'Please select vehicle maker',
    'Please select vehicle model': 'Please select vehicle model',
    'Please select year': 'Please select year',
    'Online': 'Online',
    'Type': 'Type',
    'Please enter VIN number': 'Please enter VIN number',
    'Vehicle List': 'Vehicle List',
    'Mark as Read All': 'Mark as Read All',
    'of': 'of',
    'No Image Found': 'No Image Found',
    'No Specialist Found': 'No Specialist Found',
    'No Dealer Found': 'No Dealer Found',
    'Chat': 'Chat',
    'Quote Request': 'Quote Request',
    'Product Categories': 'Product Categories',
    'No Products Found': 'No Products Found',
    'Parts': 'Parts',
    'Add to Fav': 'Add to Fav',
    'Product Catalogue': 'Product Catalogue',
    'Service Catalogue': 'Service Catalogue',
    'No Categories Found': 'No Categories Found',
    'Specialist Replies': 'Specialist Replies',
    'No Reply Found': 'No Reply Found',
    'New Price': 'New Price',
    'years': 'years',
    'Used': 'Used',
    'Second Hand': 'Second Hand',
    'New': 'New',
    'Browse and chose the vehicle image you want to upload from your computer': 'Browse and chose the vehicle image you want to upload from your computer',
    'Service Categories': 'Service Categroies',
    'Services': 'Services',
    'Specialist Title': 'Specialist Title',
    'My Profile': 'My Profile',
    'Price': 'Price',
    'Blog Detail': 'Blog Detail',
    'Make & Model': 'Make & Model',
    'Related Blogs': 'Related Blogs',
    'to': 'to',
    'Password': 'Password',
    'Confirm Password': 'Confirm Password',
    'Accept': 'Accept',
    'Please enter location': 'Please enter location',
    'Please enter name': 'Please enter name',
    'Please enter email': 'Please enter email',
    'Please enter mobile number': 'Please enter mobile number',
    'Please enter minimum 7 number': 'Please enter password',
    'Please enter password': 'Please enter password',
    'Please enter minimum 6 character password': 'Please enter minimum 6 character password',
    'Please enter confirm password': 'Please enter confirm password',
    'Password not match': 'Password not match',
    'Please add minimum one car': 'Please add minimum one car',
    'Please Accept Terms & Conditions': 'Please Accept Terms & Conditions',
    'Please enter valid email': 'Please enter valid email',
    'Already have an account': 'Already have an account',
    'Don’t have an account': 'Don’t have an account',
    'Forgot Password': 'Forgot Password',
    'Remember Password': 'Remember Password',
    'Send OTP': 'Send OTP',
    'Are you sure you want to Logout from your account': 'Are you sure you want to Logout from your account',
    'You need to login again for continue usage of app if you logout': 'You need to login again for continue usage of app if you logout',
    'Logout Account': 'Logout Account',
    'Are you sure you want to delete your account? Please read how account deletion will affect': 'Are you sure you want to delete your account? Please read how account deletion will affect',
    'Deleting your account removes personal information our database. Tour email becomes permanently reserved and same email cannot be re-use to register a new account': 'Deleting your account removes personal information our database. Tour email becomes permanently reserved and same email cannot be re-use to register a new account',
    'Password must be at least 6 characters long': 'Password must be at least 6 characters long',
    'Passwords must match': 'Passwords must match',
    'Delete All': 'Delete All',
    "Enter VIN Number": "Enter VIN Number",
    "Enter Password": "Enter Password",
    "Enter Name": "Enter Name",
    "Enter Location": "Enter Location",
    "Enter Confirm Password": "Enter Confirm Password",
    'Logout Successfully': 'Logout Successfully',
    'Vehicle Added Successfully': 'Vehicle Added Successfully',
    'Remember Me': 'Remember Me',
    'OTP Verification': 'OTP Verification',
    'Didn\'t Receive OTP': 'Didn\'t Receive OTP',
    'Resend': 'Resend',
    'Delete': 'Delete',
    'Please Enter old password': 'Please Enter old password',
    'Please Enter new password': 'Please Enter new password',
    'Confirm Password is required': 'Confirm Password is required',
    'Verify OTP': 'Verify OTP',
    'Both': 'Both',
    'Status': 'State',
    'RECEIVED': 'RECEIVED',
    'REQUEST': 'REQUEST',
    'Final Price': 'Final Price',
    'FAQs': 'FAQs',
    'Frequently Asked Questions': 'Frequently Asked Questions',
    'Question': 'Question',
    'Answer': 'Answer',
    "Quote sended with quoteId": "Quote sended with quoteId",
    "Your Quote has been sended successfully": "Your Quote has been sended successfully",
    "Your Quote is sended successfully": "Your Quote is sended successfully",
    "New Message Recieved": "New Message Recieved",
    "Quote sended with quoteId": "Quote sended with quoteId",
    "You have send a new quote for quoteId": "You have send a new quote for quoteId",
    "You have sent a new quote for quoteId": "You have sent a new quote for quoteId",
    "You have sent a quote": "You have sent a quote",
    "You have received new quote for quoteId": "You have received new quote for quoteId",
    "You have received quote from": "You have received quote from",
    "Welcome new member": "Welcome new member",
    "Welcome! We're excited to have you join us. Explore, connect, and enjoy your time here at PimptUp. If you need help, our support team is here for you. Happy exploring!": "Welcome! We're excited to have you join us. Explore, connect, and enjoy your time here at PimptUp. If you need help, our support team is here for you. Happy exploring!",
    "If you want to see more details, click on the notification image. It will redirect you to the Quote Details Page": "If you want to see more details, click on the notification image. It will redirect you to the Quote Details Page"
}

export default en;
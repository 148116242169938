import React, { useContext, useEffect, useState } from "react";
import Header from "../Components/header/Header";
import Footer from "../Components/footer/footer";
import { AuthContext } from "../context/AuthContext";
import { Link } from "react-router-dom";
import apiPath from "../constants/apiPath";
import Banners from "../Components/banners/Banners";
import lang from "../helper/lang";
import editThemeIcon from '../Assets/img/icon/editThemeIcon.svg';
import EditMobile_Mail from "../Components/Modalss/EditMobile_Mail";
import OtpModal from "../Components/Modalss/OtpModal";
import useRequest from "../hooks/useRequest";

const My_profile = () => {

    const [edit, setEdit] = useState();
    const [updatedFormData, setUpdatedFormData] = useState();
    const { userProfile, setUserProfile } = useContext(AuthContext);
    const {request} = useRequest();
    
    useEffect(() => {
        let profile = JSON.parse(localStorage.getItem("userProfile"))
        setUserProfile(profile)
        // request({
        //     url: apiPath.profile,
        //     method: "GET",
        //     onSuccess: (data)=>{
        //         if(data.status){
        //             localStorage.setItem("userProfile", JSON.stringify(data.data))
        //             setUserProfile(data.data);
        //         }
        //         else{
        //             setUserProfile(profile)
        //         }
        //     }
        // })
    }, []);

    console.log(userProfile)

    return (
        <>
            <div className="mainWraper">

                <div className="profileMainWrapper sectionPading">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-xl-7">
                                <div className="sectionHeading">
                                    <div className="row align-items-center">
                                        <div className="col">
                                            <div className="title d-inline-block">
                                                <div className="txt">{lang("Profile")}</div>
                                                <div className="titleDesign d-flex align-items-center gap-2 mt-3"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="profileMainOuter mt-2 rounded-3 p-sm-5 p-4 bg-white">
                                    <div className="imgOuter rounded-circle overflow-hidden">
                                        <img className="w-100 h-100" src={userProfile?.image ? apiPath.getImage + userProfile?.image : "https://www.w3schools.com/howto/img_avatar.png"} />
                                    </div>
                                    <div className="row mt-0 g-3">
                                        <div className="col-md-6">
                                            <div className="prodetail p-2 px-3 rounded-3">
                                                <div className="title">{lang("Full Name")}</div>
                                                <div className="value">{userProfile?.name}</div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="prodetail p-2 px-3 rounded-3">
                                                <div className="row align-items-center">
                                                    <div className="col title">{lang("Email Address")}</div>
                                                    <div className="col-auto title">
                                                        <button onClick={() => setEdit("email")} className="border-0 textBtn d-flex text-decoration-none align-items-center gap-2"><img className="" src={editThemeIcon} /> {lang('Edit')}</button>
                                                    </div>
                                                </div>
                                                <div className="value">{userProfile?.email}</div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                        <div className="prodetail p-2 px-3 rounded-3">
                                                <div className="row align-items-center">
                                                    <div className="col title">{lang("Phone Number")}</div>
                                                    <div className="col-auto title">
                                                        <button onClick={()=>setEdit("number")} className="border-0 textBtn d-flex text-decoration-none align-items-center gap-2"><img className="" src={editThemeIcon} /> {lang('Edit')}</button>
                                                    </div>
                                                </div>
                                                <div className="value">+{userProfile?.country_code} - {userProfile?.mobile_number}</div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="prodetail p-2 px-3 rounded-3">
                                                <div className="title">{lang("Date of Birth")}</div>
                                                <div className="value">{userProfile?.dob || "dd/mm/yyyy"}</div>
                                            </div>
                                        </div>
                                        <div className="col-12 text-end">
                                            <Link to="/edit-profile" class="btn btnStyle1 shadow-none text-white px-sm-5">{lang("Edit Details")}</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="otherSliderMain sectionPading bgLight">
                    <Banners />
                </div>

            </div>

            {(edit === "email" || edit === "number") && <EditMobile_Mail onUpdate={(data)=>setUpdatedFormData(data)} edit={edit} onHide={(type) => setEdit(type)} />}
            {edit === "otp" && <OtpModal formData={updatedFormData} isUpdate={true} onClose={(type) => setEdit(type)} />}
        </>
    )
}

export default My_profile;

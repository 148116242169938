import React, { useContext, useEffect, useState } from "react";
import OwlCarousel from 'react-owl-carousel3';
import Leftarrow from '../Assets/img/icon/saliderArrowLeft.png';
import Rightarrow from '../Assets/img/icon/saliderArrowRight.png';
import CoverImg1 from '../Assets/img/CoverImg1.jpg';
import Specialist1 from '../Assets/img/specialistImg1.png';
import chatIcon from '../Assets/img/icon/chatIcon.svg';
import Productimg1 from '../Assets/img/blub.png';
import useRequest from "../hooks/useRequest";
import { useNavigate, useParams } from "react-router";
import apiPath from "../constants/apiPath";
import { Severty, ShowToast } from "../helper/toast";
import Banners from "../Components/banners/Banners";
import Loader from "../Components/Loader";
import { Link } from "react-router-dom";
import { AppStateContext, useAppContext } from "../context/AppContext";
import { AuthContext } from "../context/AuthContext";
import lang from "../helper/lang";
import NoDataFound from "../Assets/img/NotFound.png";
import { specialistsSlider } from "../constants/sliders";


const ServicesCatalogueSlider = {
    margin: 16,
    loop: false,
    nav: true,
    dots: false,
    autoplay: false,
    autoplayTimeout: 3000,
    autoplayHoverPause: false,
    navText: [`<img src=${Leftarrow}>`, `<img src=${Rightarrow}>`],
    responsive: {
        0: {
            items: 1
        },
        380: {
            items: 1.25
        },
        480: {
            items: 1.50
        },
        768: {
            items: 2.25
        },
        992: {
            items: 2.5
        },
        1400: {
            items: 4
        }
    }
}

const ProductCatalogueSlider = {
    margin: 16,
    loop: false,
    nav: true,
    dots: false,
    autoplay: false,
    autoplayTimeout: 3000,
    autoplayHoverPause: false,
    navText: [`<img src=${Leftarrow}>`, `<img src=${Rightarrow}>`],
    items: 1,
    responsive: {
        0: {
            items: 1.75
        },
        480: {
            items: 2
        },
        768: {
            items: 3
        },
        992: {
            items: 4
        },
        1200: {
            items: 5
        },
        1400: {
            items: 6
        }
    }
}


const ProviderProfile = () => {
    const { id } = useParams();
    const { request } = useRequest();
    const [provider, setProvider] = useState({});
    const [makes, setMakes] = useState([]);
    const [models, setModels] = useState([]);
    const [services, setServices] = useState([]);
    const { isLoggedIn } = useContext(AuthContext)
    const { setShowDialouge, language } = useContext(AppStateContext)
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loading2, setLoading2] = useState(false);
    const [fav, setFav] = useState(false);
    const navigate = useNavigate();

    const { addChat } = useAppContext();

    const addFavourite = () => {
        isLoggedIn
            ? request({
                url: apiPath.addWishList,
                method: "POST",
                data: { vendor_id: id, type: provider?.type },
                onSuccess: (data) => {
                    if (data.status) {
                        console.log(data);
                        setFav(x => !x);
                    }
                },
                onError: (err) => {
                    console.log(err);
                }
            }) : setShowDialouge({ type: 'login' })
    }

    const getData = () => {
        setLoading(true);
        request({
            url: apiPath.details + id + "/detail",
            method: "GET",
            onSuccess: (data) => {
                if (data.status) {
                    console.log(data.data)
                    setProvider(data.data);
                    setMakes(data.data.make_id);
                    setModels(data.data.model_id);
                    setFav(data.data.is_wishlist);
                    getProducts();
                    getServices();
                }
                setLoading(false)
            },
            onError: (err) => {
                setLoading(false);
                ShowToast(err.message, Severty.ERROR);
            }
        });
    }

    const getServices = () => {
        setLoading2(true);
        request({
            url: apiPath.specialistServices(id),
            method: "GET",
            onSuccess: (data) => {
                if (data.status) {
                    console.log(data);
                    setServices(data.data);
                    setLoading2(false);
                }
            },
            onError: (err) => {
                console.log(err);
                setLoading2(false);
            }
        })
    }

    const getProducts = () => {
        setLoading2(true);
        request({
            url: apiPath.specialistProducts(id),
            method: "GET",
            onSuccess: (data) => {
                if (data.status) {
                    console.log(data);
                    setProducts(data.data);
                }
                setLoading2(false);
            },
            onError: (err) => {
                console.log(err);
                setLoading2(false);
            }
        })
    }

    const handleChat = async () => {
        if (isLoggedIn) {
            await addChat(provider, { name: provider?.name, type: 'user' })
            navigate('/chat')
        }
        else {
            setShowDialouge({ type: 'login' })
        }
    }

    useEffect(() => {
        getData();
    }, [id]);

    return (
        <>
            <div className="mainWraper">
                <div className="specialistProfileOuter">
                    <div className="container-fluid px-0">
                        <div className="proCoverImg w-100 overflow-hidden">
                            <img alt="" className="w-100 h-100" src={CoverImg1} />
                        </div>
                    </div>
                    <div className="container">
                        {loading
                            ? (<Loader />)
                            : (<div className="profileDetail d-flex flex-column flex-sm-row align-items-center gap-sm-4 gap-2">
                                <div className="proImgOuter position-relative">
                                    <div className="bgDesign position-absolute top-0">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="216" height="51" viewBox="0 0 216 51" fill="none">
                                            <path d="M108 0.998535C49 3 48.5 50.9985 0 50.9985H216C168 50.9985 165 2 108 0.998535Z" fill="#F6F9FA" />
                                        </svg>
                                    </div>
                                    <div className="proImg w-100 h-100 rounded-circle overflow-hidden position-relative">
                                        <img alt="" className="w-100 h-100" src={provider?.image ? apiPath.getImage + provider.image : Specialist1} />
                                    </div>
                                </div>
                                <div className="detail d-flex flex-column flex-md-row align-items-center align-items-sm-start align-items-md-center justify-content-between gap-lg-4 gap-2 p-2 w-100">
                                    <div className="">
                                        <div className="name text-center">{provider?.name?.toUpperCase()}</div>
                                        {/* <ul className="workType p-0 m-0">
                                            <li className="d-inline-block px-3">{lang("Make")}</li>
                                            <li className="d-inline-block px-3">{lang("Model")}</li>
                                            <li className="d-inline-block px-3">{lang("Parts")}</li>
                                        </ul> */}
                                    </div>
                                    <div className="d-flex align-items-center gap-3">
                                        <button onClick={() => addFavourite()} type="button" class={`btn btnStyle1 outLineDarkBtn addFavBtn ${fav && 'fav'} d-flex align-items-center justify-content-center gap-2 border-0 shadow-none text-white px-lg-5 px-4`}>{lang("Add to Fav")}
                                            <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10.517 17.836C10.2337 17.936 9.76699 17.936 9.48366 17.836C7.06699 17.011 1.66699 13.5693 1.66699 7.73597C1.66699 5.16097 3.74199 3.07764 6.30033 3.07764C7.81699 3.07764 9.15866 3.81097 10.0003 4.9443C10.842 3.81097 12.192 3.07764 13.7003 3.07764C16.2587 3.07764 18.3337 5.16097 18.3337 7.73597C18.3337 13.5693 12.9337 17.011 10.517 17.836Z" stroke="#121212" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>
                                        </button>
                                        <Link to={isLoggedIn && "/chat"} className="text-decoration-none">
                                            <button onClick={handleChat} type="button" class="btn btnStyle1 d-flex align-items-center justify-content-center gap-2 shadow-none text-white px-lg-5 px-4">{lang("Chat")} <img alt="" className="" src={chatIcon} /></button>
                                        </Link>
                                    </div>
                                </div>
                            </div>)}

                        <div className="workTypes sectionPading">
                            <div className="sectionHeading">
                                <div className="row align-items-center">
                                    <div className="col">
                                        <div className="title d-inline-block">
                                            <div className="txt">{lang("Make")}</div>
                                            <div className="titleDesign d-flex align-items-center gap-2 mt-2"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="typesOuter mt-2">
                                <div className="row g-3 row-cols-2 row-cols-sm-3 row-cols-md-4 row-cols-lg-5 row-cols-xl-6 row-cols-xxxl-10">
                                    {makes?.length
                                        ? <OwlCarousel {...specialistsSlider}>
                                            {makes?.map((make, idx) => {
                                                return (
                                                    <div className="col">
                                                        <div className="text-capitalize typeItem w-100 text-center p-2 d-flex align-items-center justify-content-center rounded-3 bg-white">{language !== ('en' || null) ? (make[`${language}_name`] ?? make?.name) : make?.name}</div>
                                                    </div>
                                                )
                                            })
                                            }
                                        </OwlCarousel>
                                        : (
                                            <div>
                                                <img width={120} src={NoDataFound} alt="" />
                                                <h6 className='pt-2'>{lang("No Data Found")}</h6>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="workTypes sectionPading pt-0">
                            <div className="sectionHeading">
                                <div className="row align-items-center">
                                    <div className="col">
                                        <div className="title d-inline-block">
                                            <div className="txt">{lang("Model")}</div>
                                            <div className="titleDesign d-flex align-items-center gap-2 mt-2"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="typesOuter mt-2">
                                <div className="row g-3 row-cols-2 row-cols-sm-3 row-cols-md-4 row-cols-lg-5 row-cols-xl-6 row-cols-xxxl-10">
                                    {models?.length
                                        ? <OwlCarousel {...specialistsSlider}>
                                            {models?.map((model, idx) => {
                                                return (
                                                    <div className="col">
                                                        <div className="text-capitalize typeItem w-100 text-center p-2 d-flex align-items-center justify-content-center rounded-3 bg-white">{language !== ('en' || null) ? (model[`${language}_name`] ?? model?.name) : model?.name}</div>
                                                    </div>
                                                )
                                            })
                                            }
                                        </OwlCarousel>
                                        : (
                                            <div>
                                                <img width={120} src={NoDataFound} alt="" />
                                                <h6 className='pt-2'>{lang("No Data Found")}</h6>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <div className="otherSliderMain sectionPading bgLight">
                    <Banners />
                </div>

                <div className="productSection sectionPading">
                    <div className="container">
                        <div className="sectionHeading">
                            <div className="row align-items-center">
                                <div className="col">
                                    <div className="title d-inline-block">
                                        <div className="txt">{lang("Product Catalogue")}</div>
                                        <div className="titleDesign d-flex align-items-center gap-2 mt-3"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {loading2 ? (<Loader />) : (<div className="productsOuter mt-2">
                            {
                                products?.length ? (
                                    <OwlCarousel {...ProductCatalogueSlider} >
                                        {
                                            products.map((prod) => (
                                                <div onClick={() => navigate(`/product-detail/${prod?.product_id?._id}`)} className="productItem bg-white">
                                                    <div className="productImg w-100 position-relative">
                                                        <img alt="" className="w-100 h-100" src={prod?.product_id?.thumbnail || Productimg1} />
                                                    </div>
                                                    <div className="productDetail">
                                                        <div className="proTitle text-capitalize">{prod?.product_id?.name}</div>
                                                        <div className="d-flex align-items-center justify-content-between gap-2 mt-1">
                                                            <div className="brandName text-capitalize">{language !== ('en' || null) ? (prod?.product_id?.make_id[`${language}_name`] ?? prod?.product_id?.make_id?.name) : prod?.product_id?.make_id?.name}</div>
                                                            <div className="price">R {prod?.product_id?.price}</div>
                                                        </div>
                                                        <div className="productFooter mt-2 pt-2 border-top">
                                                            <div className="usedPro d-flex align-items-center gap-2 text-capitalize">
                                                                {prod?.type?.toLowerCase() === "used" ? ("Used - " + prod?.age + " " + lang("years")) : ("New Product")}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </OwlCarousel>
                                ) : (<div>
                                    <img width={120} src={NoDataFound} alt="" />
                                    <h6 className='pt-2'>{lang("No Data Found")}</h6>
                                </div>)
                            }
                        </div>)}
                    </div>
                </div>

                {provider?.type === "Specialist" && (<div className="serviceSection sectionPading">
                    <div className="container">
                        <div className="sectionHeading">
                            <div className="row align-items-center">
                                <div className="col">
                                    <div className="title d-inline-block">
                                        <div className="txt">{lang("Service Catalogue")}</div>
                                        <div className="titleDesign d-flex align-items-center gap-2 mt-3"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {loading2 ? (<Loader />) : (<div className="productsOuter mt-2">
                            {
                                services.length > 0 ?
                                    <OwlCarousel {...ServicesCatalogueSlider} >
                                        {
                                            services.map((service, idx) => (
                                                <div onClick={() => navigate(`/service-detail/${service?.service_id?._id}`)} className="sliderItemOuter d-flex flex-column gap-3" key={idx}>
                                                    <div className="seiviceCatalogueItem d-flex align-items-center overflow-hidden bg-white">
                                                        <div className="serviceImg position-relative">
                                                            <img alt="" className="w-100 h-100" src="https://media.istockphoto.com/id/1284285171/photo/auto-mechanic-working-on-car-engine-in-mechanics-garage-repair-service-authentic-close-up-shot.jpg?s=612x612&w=0&k=20&c=6CQATEcbJCRhyMpbS1_azTfI1qZVGWDejPvDvD5Js30=" />
                                                        </div>
                                                        <div className="serviceDetail p-3 w-100">
                                                            <div className="serviceTitle text-capitalize">{language !== ('en' || null) ? (service?.service_id[`${language}_name`] ?? service?.service_id?.name) : service?.service_id?.name}</div>
                                                            <div className="servicePrice mt-lg-1">R {service?.price}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                    </OwlCarousel>
                                    : <div>
                                        <img width={120} src={NoDataFound} alt="" />
                                        <h6 className='pt-2'>{lang("No Data Found")}</h6>
                                    </div>
                            }
                        </div>)}
                    </div>
                </div>)}
            </div>

        </>
    )
}

export default ProviderProfile;

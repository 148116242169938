import { useEffect, useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import Header from "../Components/header/Header";
import Footer from "../Components/footer/footer";
import dotsIcon from '../Assets/img/icon/dotsIcon.svg';
import Banners from '../Components/banners/Banners';
import useRequest from '../hooks/useRequest';
import apiPath from "../constants/apiPath";
import ReplyCard from '../Components/quotes/ReplyCard';
import { useParams } from 'react-router';
import lang from '../helper/lang';
import NoDataFound from "../Assets/img/NotFound.png";

import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import searchIcon from '../Assets/img/icon/searchIcon.svg';

const SpecialistReplyList = () => {

    const [loading, setLoading] = useState(false);
    const [replies, setReplies] = useState([]);
    const { request } = useRequest();
    const { id } = useParams();

    const getReplies = () => {
        setLoading(true);
        request({
            url: apiPath.getReplyList(id),
            method: "GET",
            onSuccess: (data) => {
                if (data.status) {
                    setReplies(data.data);
                    console.log(data);
                    setLoading(false);
                }
            },
            onError: (err) => {
                console.log(err);
                setLoading(false);
            }
        })
    }

    useEffect(() => {
        getReplies();
    }, []);

    return (
        <>
            <div className="mainWraper">
                <div className="sectionPading">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-xl-8">
                                <div className="sectionHeading">
                                    <div className="row g-3 align-items-center">
                                        <div className="col">
                                            <div className="title d-inline-block">
                                                <div className="txt">{lang("Specialist Replies")}</div>
                                                <div className="titleDesign d-flex align-items-center gap-2 mt-3"></div>
                                            </div>
                                        </div>
                                        <div className="col-sm-auto d-flex align-items-center gap-3">
                                            <InputGroup className="position-relative">
                                                <Form.Control type="search" id='headerSearch' className="rounded-1 pe-5" placeholder={lang("Search") + "..."} />
                                                <label for="headerSearch" className="btn searchBtn d-flex align-items-cneter justify-content-center m-0 rounded-1 border-0 shadow-none position-absolute end-0 top-0 bottom-0 me-1 my-auto">
                                                    <img className="w-100" src={searchIcon} alt="search" />
                                                </label>
                                            </InputGroup>
                                            <Dropdown className="filterDrop d-lg-none" align="end">
                                                <Dropdown.Toggle id="dropdown-basic" className="p0 border-0 shadow-none bg-transparent">
                                                    <img className="" src={dotsIcon} />
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu className="border-0 p-0">
                                                    <Dropdown.Item className="border-bottom" href="#/action-1">{lang("Mark as Read All")}</Dropdown.Item>
                                                    <Dropdown.Item className="border-bottom" href="#/action-2">{lang("Delete All")}</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </div>
                                </div>

                                <div className="notificationMainOuter mt-2">
                                    <div className="notificationInner d-flex flex-column gap-3">
                                        {replies?.length ? (replies?.map((reply) => {
                                            return <ReplyCard reply={reply} />
                                        })) : (<div>
                                            <img width={120} src={NoDataFound} alt="" />
                                            <h6 className='pt-2'>{lang("No Data Found")}</h6>
                                        </div>)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="otherSliderMain sectionPading bgLight">
                    <Banners />
                </div>
            </div>

        </>
    )
}

export default SpecialistReplyList;

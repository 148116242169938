import React, { useState, useEffect } from "react";
import Header from "../Components/header/Header";
import Footer from "../Components/footer/footer";
import useRequest from "../hooks/useRequest";
import apiPath from "../constants/apiPath";
import Banners from "../Components/banners/Banners";
import BlogsCard from "../Components/blogs/BlogsCard";
import Loader from "../Components/Loader";
import lang from "../helper/lang";
import NoDataFound from "../Assets/img/NotFound.png";


const Blog_list = () => {

    const [loading, setLoading] = useState(false);
    const [blogsList, setBlogsList] = useState([]);
    const { request } = useRequest();

    const getBlogs = () => {
        setLoading(true);
        request({
            url: apiPath.getBlogList,
            method: "GET",
            onSuccess: (data) => {
                if (data.status) {
                    setBlogsList(data.data);
                    setLoading(false);
                }
            },
            onError: (err) => {
                console.log(err);
                setLoading(false);
            }
        })
    }

    useEffect(() => {
        getBlogs();
    }, []);


    return (
        <>
            <div className="mainWraper">
                <div className="otherSliderMain sectionPading bgLight">
                    <Banners />
                </div>

                <div className="sectionPading">
                    <div className="container">
                        <div className="sectionHeading">
                            <div className="row align-items-center">
                                <div className="col">
                                    <div className="title d-inline-block">
                                        <div className="txt">{lang("Blogs")}</div>
                                        <div className="titleDesign d-flex align-items-center gap-2 mt-3"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="blogsWrapper mt-2">
                        {loading
                            ? (<Loader />)
                            : (<div className="container">
                                <div className="blogsOuter">
                                    <div className="row g-3 row-cols-xs-1 row-cols-2 row-cols-md-3 row-cols-xxl-4 row-cols-xxxl-5">
                                        {blogsList?.length ? (
                                            blogsList?.map((blog) => {
                                                return <BlogsCard blog={blog} />
                                            })
                                        ) : (<div>
                                            <img width={120} src={NoDataFound} alt="" />
                                            <h6 className='pt-2'>{lang("No Data Found")}</h6>
                                        </div>)}
                                    </div>
                                </div>
                            </div>)}
                    </div>
                </div>

            </div>

        </>
    )
}

export default Blog_list;

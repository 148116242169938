/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import OwlCarousel from 'react-owl-carousel3';
import Category1 from '../../Assets/img/category1.png';
import useRequest from "../../hooks/useRequest";
import apiPath from "../../constants/apiPath";
import { Link, useParams } from "react-router-dom";
import Banners from "../../Components/banners/Banners";
import ServicesCard from "../../Components/services/ServicesCard";
import { brandsSlider, categorySlider } from "../../constants/sliders";
import Dropdown from 'react-bootstrap/Dropdown';
import shortingIcon from '../../Assets/img/icon/shortingIcon.svg';
import filterIcon from '../../Assets/img/icon/filterIcon.svg';
import lang from "../../helper/lang";
import { useAppContext } from "../../context/AppContext";
import NoDataFound from "../../Assets/img/NotFound.png";
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import searchIcon from '../../Assets/img/icon/searchIcon.svg';
import Loader from "../Loader";


const ServiceSection = () => {

    const [categories, setCategories] = useState([]);
    const [service, setService] = useState([]);
    const [loading, setLoading] = useState(false);
    const [toggle, setToggle] = useState(false);
    const [selectedCtg, setSelectedCtg] = useState({});
    const { request } = useRequest();
    const { id } = useParams();
    const { language } = useAppContext();

    const getServices = (id) => {
        setLoading(true);
        request({
            url: apiPath.serviceList,
            method: "GET",
            onSuccess: (data) => {
                if (data.status) {
                    console.log(data.list, "service list -------");
                    setService(data.list)
                }
                setLoading(false);
            },
            onError: (err) => {
                setLoading(false);
                console.log("something went wrong!", err);
            }
        });
        const sc = categories?.filter((elem) => {
            return id === elem?._id;
        });
        setSelectedCtg(sc[0]);
    }

    const handleSort = (val) => {
        if (val === 'Z-A') {
            setToggle(true);
        } else {
            setToggle(false);
        }
        setLoading(true);
        request({
            url: apiPath.getCategoryItem + "/" + id + `?sort=${val}`,
            method: "GET",
            onSuccess: (data) => {
                if (data.status) {
                    setService(data.data)
                }
                setLoading(false);
            },
            onError: (err) => {
                setLoading(false);
                console.log("something went wrong!", err);
            }
        });
        const sc = categories?.filter((elem) => {
            return id === elem?._id;
        });
        setSelectedCtg(sc[0]);
    }

    const handleSearch = (val) => {
        request({
            url: apiPath.getCategoryItem + "/" + id + "?text=" + val,
            method: "GET",
            onSuccess: (data) => {
                if (data.status) {
                    setService(data.data);
                }
            },
            onError: (err) => {
                console.log(err);
            }
        })
    }

    useEffect(() => {
        getServices();
    }, []);


    return (
        <>
            <div className="categoryServiceListing sectionPading">
                <div className="container">
                    <div className="sectionHeading">
                        <div className="row g-3 align-items-center">
                            <div className="col">
                                <div className="title d-inline-block">
                                    <div className="txt">{lang("Services")}</div>
                                    <div className="titleDesign d-flex align-items-center gap-2 mt-3"></div>
                                </div>
                            </div>
                            <div className="col-auto">
                                <Link to="/services" className="textBtn">
                                    {lang("See All")}
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="row g-3 row-cols-xs-2 row-cols-3 row-cols-md-4 row-cols-lg-5 pt-2 row-cols-xl-5 row-cols-xxxl-8">
                        {
                            loading
                                ? (<Loader />)
                                :
                                service.length
                                    ? (<OwlCarousel {...brandsSlider}>
                                        {!!service && service.length > 0
                                            ? (service.map((item, idx) => {
                                                return (
                                                    <ServicesCard item={item} key={item?._id} />
                                                )
                                            })
                                            ) : null
                                        }
                                    </OwlCarousel>)
                                    : <div>
                                        <img width={120} src={NoDataFound} alt="" />
                                        <h6 className='pt-2'>{lang("No Data Found")}</h6>
                                    </div>
                        }
                    </div>
                </div>
            </div>

        </>
    )
}

export default ServiceSection;

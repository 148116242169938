import React, { useEffect, useState } from "react";
import OwlCarousel from 'react-owl-carousel3';
import { Link } from "react-router-dom";
import OtherImg1 from '../../Assets/img/otherimg1.png';
import useRequest from "../../hooks/useRequest";
import apiPath from "../../constants/apiPath"
import Loader from "../Loader";
import { otherSlider } from "../../constants/sliders";


const Banners = () => {
    const [banners, setBanners] = useState([]);
    const [loading, setLoading] = useState(false);
    const { request } = useRequest();

    const getBanners = () => {
        setLoading(true);
        request({
            url: apiPath.bannerList,
            method: "GET",
            onSuccess: (data) => {
                if (data.status) {
                    setBanners(data.data);
                    setLoading(false);
                }
            },
            onError: (err) => {
                setLoading(false);
                console.log(err);
            }
        })
    }

    const handleCounter = (id) => {
        request({
            url: apiPath.clickCounter + id,
            method: "PUT",
            onSuccess: (data) => {
                console.log(data?.message)
            },
            onError: (err) => {
                console.log(err?.message)
            }
        })

    }

    useEffect(() => {
        getBanners();
    }, []);

    return (
        <div className="container-fluid px-0">
            <div className="brandsOuter">
                {loading ? (<Loader />) :
                    (<OwlCarousel {...otherSlider} >
                        {banners.map((banner) => {
                            return (
                                <>
                                    {banner?.type === 'category' &&
                                        <Link onClick={() => handleCounter(banner?._id)} to={banner?.category_type == 'product' ? `/product-listing/${banner?.category_id}` : `/service-categories/${banner?.category_id}`} className="otherSliderItem text-center d-block text-decoration-none rounded-3 border overflow-hidden">
                                            <img className="h-100" src={banner.image || OtherImg1} />
                                        </Link>
                                    }
                                    {banner?.type === 'normal' &&
                                        <Link onClick={() => handleCounter(banner?._id)} to={banner?.link ? banner?.link : ''} target={banner?.link && "_blank"} className="otherSliderItem text-center d-block text-decoration-none rounded-3 border overflow-hidden">
                                            <img className="h-100" src={banner.image || OtherImg1} />
                                        </Link>
                                    }
                                    {banner?.type === 'advertise' &&
                                        <Link onClick={() => handleCounter(banner?._id)} to={banner?.advertise_id ? `/provider-profile/${banner?.advertise_id}` : ''} className="otherSliderItem text-center d-block text-decoration-none rounded-3 border overflow-hidden">
                                            <img className="h-100" src={banner.image || OtherImg1} />
                                        </Link>
                                    }
                                </>
                            )
                        })}
                    </OwlCarousel>
                    )}
            </div>
        </div>
    )
}

export default Banners;
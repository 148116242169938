import React from "react";
import OwlCarousel from 'react-owl-carousel3';
import Leftarrow from '../../Assets/img/icon/saliderArrowLeft.png';
import Rightarrow from '../../Assets/img/icon/saliderArrowRight.png';
import { Link } from "react-router-dom";
// import Herosliderimg from '../../Assets/img/slideshow-home-1.jpg';
import Herosliderimg from '../../Assets/img/home_banner.jpeg';
import Plusicon from '../../Assets/img/icon/plusIcon.svg';
import lang from "../../helper/lang";

const MainHeroSlider = {
    margin: 0,
    loop: true,
    nav: true,
    dots: true,
    autoplay: true,
    autoplayTimeout: 3000,
    autoplayHoverPause: false,
    navText: [`<img src=${Leftarrow}>`, `<img src=${Rightarrow}>`],
    items: 1,
    0: {
        items: 1
    },
    768: {
    }
}

const HeroSlider = () => {

    return (
        <div className="heroSliderMain">
                    <div className="container-fluid px-0">
                        <div className="sliderOuter">
                            <OwlCarousel {...MainHeroSlider} >
                                <div className="sliderItem">
                                    <div className="sliderImg">
                                        <img className="w-100 h-50" src={Herosliderimg} />
                                    </div>
                                </div>
                                <div className="sliderItem">
                                    <div className="sliderImg">
                                        <img className="w-100 h-50" src={Herosliderimg} />
                                    </div>
                                </div>
                                <div className="sliderItem">
                                    <div className="sliderImg">
                                        <img className="w-100 h-50" src={Herosliderimg} />
                                    </div>
                                </div>
                            </OwlCarousel>
                        </div>
                    </div>
                </div>
    )   
}

export default HeroSlider;
import React, { useEffect, useState } from "react";
import useRequest from "../hooks/useRequest";
import apiPath from "../constants/apiPath";
import Banners from "../Components/banners/Banners";
import Loader from "../Components/Loader";
import ProductsCard from "../Components/products/ProductsCard";
import Dropdown from 'react-bootstrap/Dropdown';
import shortingIcon from '../Assets/img/icon/shortingIcon.svg';
import lang from "../helper/lang";
import NoDataFound from "../Assets/img/NotFound.png";
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import filterIcon from '../Assets/img/icon/filterIcon.svg';
import searchIcon from '../Assets/img/icon/searchIcon.svg';


const Products = () => {

    const [loading, setLoading] = useState(false);
    const [toggle, setToggle] = useState(false);
    const [products, setProducts] = useState([]);
    const [model, setModel] = useState([]);
    const [brands, setBrands] = useState([]);
    const [make, setMake] = useState([]);
    const [categories, setCategories] = useState([]);
    const [filter, setFilter] = useState('name');
    const { request } = useRequest();
    const [bId, setBID] = useState();
    const [cId, setCID] = useState();
    const [mkId, setMKID] = useState();
    const [mdId, setMDID] = useState();
    const [pn, setPN] = useState();
    const [show, setShow] = useState(false);
    const [url, setUrl] = useState([]);


    useEffect(() => {
        getProducts();
        getCategoryList();
        getMake();
        getBrands();
    }, []);

    useEffect(() => {
        mkId && getModel(mkId);
    }, [mkId])

    const getProducts = () => {
        setLoading(true);
        request({
            url: apiPath.featuredProducts,
            method: "GET",
            onSuccess: (data) => {
                if (data.status) {
                    setProducts(data.data);
                }
                setLoading(false);
            },
            onError: (err) => {
                setLoading(false);
                console.log(err);
            }
        })
    }

    const getCategoryList = () => {
        setLoading(true);
        request({
            url: apiPath.categoryList,
            method: "GET",
            onSuccess: (data) => {
                if (data.status) {
                    setCategories(data.data);
                }
                setLoading(false);
            },
            onError: (err) => {
                setLoading(false);
                console.log(err);
            }
        })
    }

    const handleFilter = (val) => {
        setLoading(true);
        request({
            url: apiPath.featuredProducts + '?type=' + val,
            method: "GET",
            onSuccess: (data) => {
                if (data.status) {
                    setProducts(data.data);
                }
                setLoading(false);
            },
            onError: (err) => {
                setLoading(false);
                console.log(err);
            }
        })
    }

    const getBrands = (id) => {
        // setLoading(true);
        request({
            url: apiPath.brandList,
            method: "GET",
            onSuccess: (data) => {
                if (data.status) {
                    setBrands(data.data);
                }
                // setLoading(false);
            },
            onError: (err) => {
                // setLoading(false);
                console.log(err);
            }
        })
    }

    const getModel = (id) => {
        // setLoading(true);
        request({
            url: apiPath.carModelList + `/${id}`,
            method: "GET",
            onSuccess: (data) => {
                if (data.status) {
                    setModel(data.data);
                }
                // setLoading(false);
            },
            onError: (err) => {
                // setLoading(false);
                console.log(err);
            }
        })
    }

    const getMake = (val) => {
        // setLoading(true);
        request({
            url: apiPath.carMakeList,
            method: "GET",
            onSuccess: (data) => {
                if (data.status) {
                    setMake(data.data);
                    // setMKID(data.data[0]?._id)   
                }
                // setLoading(false);
            },
            onError: (err) => {
                // setLoading(false);
                console.log(err);
            }
        })
    }

    const handleSort = (val) => {
        setLoading(true);
        request({
            url: apiPath.featuredProducts + '?sort=' + val,
            method: "GET",
            onSuccess: (data) => {
                if (data.status) {
                    setProducts(data.data);
                }
                setLoading(false);
            },
            onError: (err) => {
                setLoading(false);
                console.log(err);
            }
        })
    }

    const handleSearch = (val) => {
        setLoading(true);
        request({
            url: apiPath.featuredProducts + `?${filter}=${val}`,
            method: "GET",
            onSuccess: (data) => {
                if (data.status) {
                    setProducts(data.data);
                }
                setLoading(false);
            },
            onError: (err) => {
                setLoading(false);
                console.log(err);
            }
        })
    }

    const handleApply = () => {
        // setLoading(true);
        let urlArray = [];
        if (bId) {
            urlArray.push({ param: "brand", value: bId })
            // setUrl([...url, { param: "brand", value: bId }]);
        }
        if (cId) {
            urlArray.push({ param: "category", value: cId })
            // setUrl([...url, { param: "category", value: cId }]);
        }
        if (mkId) {
            urlArray.push({ param: "make", value: mkId })
            // setUrl(prev => [...prev, { param: "make", value: mkId }]);
        }
        if (mdId) {
            urlArray.push({ param: "model", value: mdId })
            // setUrl(prev => [...prev, { param: "model", value: mdId }]);
        }
        if (pn) {
            urlArray.push({ param: "part_num", value: pn })
            // setUrl(prev => [...prev, { param: "part_num", value: pn }]);
        }

        let queryParams = apiPath.featuredProducts;
        urlArray.length && urlArray?.forEach((elem, idx) => {
            console.log("elem", elem);
            if (idx === 0) {
                queryParams += `?${elem.param}=${elem.value}`
            } else {
                queryParams += `&&${elem.param}=${elem.value}`

            }
        })
        console.log('queryParams', queryParams);
        setShow(x => !x);
        request({
            url: queryParams,
            method: "GET",
            onSuccess: (data) => {
                if (data.status) {
                    setProducts(data.data);
                }
                // setLoading(false);
            },
            onError: (err) => {
                // setLoading(false);
                console.log(err);
            }
        })
    }

    return (
        <>
            <div className="mainWraper">
                <div className="otherSliderMain sectionPading bgLight">
                    <Banners />
                </div>

                <div className="categoryWraper sectionPading">
                    <div className="container">
                        <div className="sectionHeading">
                            <div className="row g-3 align-items-center">
                                <div className="col">
                                    <div className="title d-inline-block">
                                        <div className="txt">{lang("Products")}</div>
                                        <div className="titleDesign d-flex align-items-center gap-2 mt-3"></div>
                                    </div>
                                </div>
                                <div className="col-auto">
                                    <div className="position-relative">
                                        <div onClick={() => setShow(x => !x)} className="p0 searchBtn d-flex align-items-cneter justify-content-center border-0 shadow-none">
                                            <img className="" src={filterIcon} alt="" />
                                        </div>
                                        <div className={`${!show ? 'd-none' : ''} filterWrap-cls`}>
                                            {/* <div>
                                                <select onChange={(e) => { setBID(e.target.value); console.log(bId); }}>
                                                    <option value={''}>Brand</option>
                                                    {
                                                        brands.length && (brands?.map((br, idx) => {
                                                            return <option key={idx} value={br?._id}><input type="checkbox" width={20} />{br?.name}</option>
                                                        }))
                                                    }
                                                </select>
                                            </div> */}
                                            <div>
                                                <select onChange={(e) => { setCID(e.target.value); console.log(cId); }}>
                                                    <option value={''}>Category</option>
                                                    {
                                                        categories.length && (categories?.map((ctg, idx) => {
                                                            return <option key={idx} value={ctg?._id}><input type="checkbox" width={20} />{ctg?.name}</option>
                                                        }))
                                                    }
                                                </select>
                                            </div>
                                            <div className="d-flex gap-2">
                                                <select onChange={(e) => { setMKID(e.target.value); }}>
                                                    <option value={''}>Make</option>
                                                    {
                                                        make.length && (make?.map((mk, idx) => {
                                                            return <option key={idx} value={mk?._id}><input type="checkbox" width={20} />{mk?.name}</option>
                                                        }))
                                                    }
                                                </select>
                                                <select onChange={(e) => { setMDID(e.target.value); }}>
                                                    <option value={''}>Model</option>
                                                    {
                                                        model.length && (model?.map((md, idx) => {
                                                            return <option key={idx} value={md?._id}><input type="checkbox" width={20} />{md?.name}</option>
                                                        }))
                                                    }
                                                </select>
                                            </div>
                                            <div className="border-bottom">
                                                <input onChange={({ target }) => { setPN(target.value); }} placeholder="Part Number" />
                                            </div>
                                            <div className="border-bottom d-flex">
                                                <button onClick={() => { setBID(''); setCID(''); setMDID(''); setMKID(''); setUrl() }}>Reset</button>
                                                <button onClick={() => handleApply()}>Apply</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={`col-sm-auto d-flex align-items-center gap-3`}>
                                    <InputGroup className="position-relative">
                                        <Form.Control type="search" id='headerSearch' onChange={(e) => handleSearch(e.target.value)} className="rounded-1 pe-5" placeholder={lang("Search") + " - " + filter} />
                                        <label for="headerSearch" className="btn searchBtn d-flex align-items-cneter justify-content-center m-0 rounded-1 border-0 shadow-none position-absolute end-0 top-0 bottom-0 me-1 my-auto">
                                            <img className="w-100" src={searchIcon} alt="search" />
                                        </label>
                                    </InputGroup>

                                    <Dropdown className="filterDrop shortBy">
                                        <Dropdown.Toggle id="dropdown-basic" className="p0 searchBtn d-flex align-items-cneter justify-content-center border-0 shadow-none">
                                            <img className="" src={toggle ? shortingIcon : shortingIcon} alt="" style={{ filter: "invert(1)" }} />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu className="border-0 p-0">
                                            <Dropdown.Item className="border-bottom" onClick={() => handleSort('A-Z')} href="#sort=A-Z">A {lang("to")} Z</Dropdown.Item>
                                            <Dropdown.Item className="border-bottom" onClick={() => handleSort('Z-A')} href="#sort=Z-A">Z {lang("to")} A</Dropdown.Item>
                                            <Dropdown.Item className="border-bottom" onClick={() => handleSort('price_low')} href="#sort=Low-High">Price - Low to High</Dropdown.Item>
                                            <Dropdown.Item className="border-bottom" onClick={() => handleSort('price_high')} href="#sort=High-Low">Price - High to Low</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>

                                    {/* <Dropdown className="filterDrop searchBy">
                                        <Dropdown.Toggle id="dropdown-basic" className="p0 searchBtn d-flex align-items-cneter justify-content-center border-0 shadow-none">
                                            <img className="" src={filterIcon} alt="" />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu className="border-0 p-0">
                                            <Dropdown.Item className="border-bottom" onClick={() => setFilter('text')}>Name</Dropdown.Item>
                                            <Dropdown.Item className="border-bottom" onClick={() => setFilter('category')}>Category</Dropdown.Item>
                                            <Dropdown.Item className="border-bottom" onClick={() => setFilter('make_model')}>Make-Model</Dropdown.Item>
                                            <Dropdown.Item className="border-bottom" onClick={() => setFilter('part_number')}>Part Number</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    <InputGroup className="position-relative">
                                        <Form.Control type="search" id='headerSearch' onChange={(e) => handleSearch(e.target.value)} className="rounded-1 pe-5" placeholder={lang("Search") + " - " + filter} />
                                        <label for="headerSearch" className="btn searchBtn d-flex align-items-cneter justify-content-center m-0 rounded-1 border-0 shadow-none position-absolute end-0 top-0 bottom-0 me-1 my-auto">
                                            <img className="w-100" src={searchIcon} alt="search" />
                                        </label>
                                    </InputGroup>

                                    <Dropdown className="filterDrop searchBy">
                                        <Dropdown.Toggle id="dropdown-basic" className="p0 searchBtn d-flex align-items-cneter justify-content-center border-0 shadow-none">
                                            <img className="" src={filterIcon} alt="" />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu className="border-0 p-0">
                                            <Dropdown.Item className="border-bottom" onClick={() => handleFilter('new')} href="#productType=new">New</Dropdown.Item>
                                            <Dropdown.Item className="border-bottom" onClick={() => handleFilter('used')} href="#productType=used">Used</Dropdown.Item>
                                            <Dropdown.Item className="border-bottom" onClick={() => getProducts()} href="#productType=both">Both</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    <Dropdown className="filterDrop shortBy">
                                        <Dropdown.Toggle id="dropdown-basic" className="p0 searchBtn d-flex align-items-cneter justify-content-center border-0 shadow-none">
                                            <img className="" src={toggle ? shortingIcon : shortingIcon} alt="" style={{filter:"invert(1)"}}/>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu className="border-0 p-0">
                                            <Dropdown.Item className="border-bottom" onClick={() => handleSort('A-Z')} href="#sort=A-Z">A {lang("to")} Z</Dropdown.Item>
                                            <Dropdown.Item className="border-bottom" onClick={() => handleSort('Z-A')} href="#sort=Z-A">Z {lang("to")} A</Dropdown.Item>
                                            <Dropdown.Item className="border-bottom" onClick={() => handleSort('price_low')} href="#sort=Low-High">Price - Low to High</Dropdown.Item>
                                            <Dropdown.Item className="border-bottom" onClick={() => handleSort('price_high')} href="#sort=High-Low">Price - High to Low</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown> */}
                                </div>
                            </div>
                        </div>
                        <div className="productsOuter mt-2">
                            <div className="row g-3 row-cols-xxl-6">
                                {loading ? <Loader />
                                    : (products?.length ?
                                        products.map((prod) => {
                                            return (
                                                <ProductsCard prod={prod} favourite={prod?.is_wishlist} />
                                            );
                                        })
                                        : <div>
                                            <img width={120} src={NoDataFound} alt="" />
                                            <h6 className='pt-2'>{lang("No Data Found")}</h6>
                                        </div>)
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Products;
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import OwlCarousel from "react-owl-carousel3";
import { categorySlider } from "../../constants/sliders";
import Specialist1 from "../../Assets/img/specialistImg1.png";
import lang from "../../helper/lang";
import { useAppContext } from "../../context/AppContext";
import useRequest from "../../hooks/useRequest";
import Loader from "../Loader";
import apiPath from "../../constants/apiPath";
import { LazyLoadImage } from 'react-lazy-load-image-component';


const DealerSection = () => {

    const { language } = useAppContext();
    const [dealers, setDealers] = useState([]);
    const [loading, setLoading] = useState(false);
    const { request } = useRequest();

    const getDealers = () => {
        setLoading(true);
        request({
            url: apiPath.allDealers,
            method: "GET",
            onSuccess: (data) => {
                if (data.status) {
                    console.log(data.data)
                    setDealers(data.data)
                }
                setLoading(false);
            },
            onError: (err) => {
                setLoading(false);
                console.log(err);
            }
        })
    }

    useEffect(() => {
        getDealers();
    }, []);

    return (dealers.length &&
        <div className="container">
            <div className="sectionHeading">
                <div className="row align-items-center">
                    <div className="col">
                        <div className="title d-inline-block">
                            <div className="txt">{lang("Dealers")}</div>
                            <div className="titleDesign d-flex align-items-center gap-2 mt-3"></div>
                        </div>
                    </div>
                    <div className="col-auto">
                        <Link to="/provider-list/dealer" className="textBtn">
                            {lang("See All")}
                        </Link>
                    </div>
                </div>
            </div>
            {loading ? <Loader /> : (<div className="dealersOuter mt-2">
                <OwlCarousel {...categorySlider}>
                    {dealers?.map((dl, idx) => {
                        return (
                            <Link
                                to={`/provider-profile/${dl._id}`}
                                className="categoriesItem text-center d-block text-decoration-none"
                            >
                                <div className="categoriesImg rounded-circle overflow-hidden mx-auto">
                                    {/* <img
                                        alt=""
                                        className="h-100 w-100"
                                        src={dl?.image ? apiPath.getImage + dl?.image : Specialist1}
                                    /> */}
                                    <LazyLoadImage
                                        alt={"Image"}
                                        height={"100%"}
                                        src={dl?.image ? apiPath.getImage + dl?.image : Specialist1}
                                        width={"100%"}
                                    />
                                </div>
                                <div className="dealerName mt-3 text-uppercase">
                                    {language !== ('en' || null) ? (dl[`${language}_name`] ?? dl?.name) : dl?.name}
                                </div>
                            </Link>
                        );
                    })}
                </OwlCarousel>
            </div>)}
        </div>
    );
};

export default DealerSection;
import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Plusicon from "../../Assets/img/icon/plusIcon.svg";
import fileUploadIcon from "../../Assets/img/icon/fileUploadIcon.svg";
import apiPath from "../../constants/apiPath";
import useRequest from "../../hooks/useRequest";
import { Severty, ShowToast } from "../../helper/toast";
import lang from "../../helper/lang";
import Loader from "../Loader";

const AddVehicleModal = ({ onClose, setFormData, flag, car, setRefresh }) => {
  const [carTypeList, setCarTypeList] = useState([]);
  const [carMakeList, setCarMakeList] = useState([]);
  const [carModalList, setCarModalList] = useState([]);
  const [carVariantList, setCarVariantList] = useState([]);
  const [years, setYears] = useState([]);
  const [type, setType] = useState();
  const [make, setMake] = useState();
  const [modal, setModal] = useState();
  const [variant, setVariant] = useState();
  const [vin, setVIN] = useState();
  const [loading, setLoading] = useState(false);
  const [year, setYear] = useState();
  const [image, setImage] = useState();
  const [errors, setErrors] = useState({});
  const { request } = useRequest();

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const data = new FormData();
    data.append("image", file);
    data.append("type", "Customer");
    setLoading(true);
    request({
      url: apiPath.imageUpload,
      method: "POST",
      data: data,
      onSuccess: (data) => {
        if (data.status) {
          console.log(data);
          setImage(data.data.upload);
        }
        setLoading(false);
      },
      onError: (err) => {
        setLoading(false);
        ShowToast(err.message, Severty.ERROR);
      },
    });
  };

  useEffect(() => {
    let years = [];
    for (let i = 2000; i <= new Date().getFullYear(); i++) {
      years.push(i);
    }
    setYears(years);

    if (!car) return;
    if (flag === "edit") {
      setMake(car?.make_id?._id);
      setType(car?.type_id?._id);
      setModal(car?.model_id?._id);
      setVariant(car?.variant_id?._id);
      setImage(car?.image);
      setYear(car?.year);
      setVIN(car?.vin_number);
    }
  }, [car]);

  useEffect(() => {
    getTypeList();
    if (!car) return;
    getMakeList(car?.type_id?._id);
    getModalList(car?.make_id?._id);
    getVariantList(car?.model_id?._id);
  }, [car]);

  const getTypeList = () => {
    request({
      url: apiPath.carTypeList,
      method: "GET",
      onSuccess: (data) => {
        if (data.status) {
          setCarTypeList(data?.data);
        }
      },
      onError: (err) => {
        console.log(err);
      },
    });
  };

  const getMakeList = (id) => {
    request({
      url: apiPath.carMakeList + `?type=${id}`,
      method: "GET",
      onSuccess: (data) => {
        if (data.status) {
          setCarMakeList(data?.data);
          // !car && setMake(data?.data[0]?._id);
        }
      },
      onError: (err) => {
        console.log(err);
      },
    });
  };

  const getModalList = (id) => {
    request({
      url: apiPath.carModelList + `/${id}`,
      method: "GET",
      onSuccess: (data) => {
        setCarModalList(data?.data);
        // !car && setModal(data?.data[0]?._id);
      },
      onError: (err) => {
        console.log(err);
      },
    });
  };

  const getVariantList = (id) => {
    request({
      url: apiPath.carVariantList + `/${id}`,
      method: "GET",
      onSuccess: (data) => {
        setCarVariantList(data?.data);
        // !car && setVariant(data?.data[0]?._id);
      },
      onError: (err) => {
        console.log(err);
      },
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const newError = {};

    if (type == "" || type == null || type == undefined) {
      newError.type = lang("Please select vehicle type");
    }
    if (make == "" || make == null || make == undefined) {
      newError.make = lang("Please select vehicle maker");
    }
    if (modal == "" || modal == null || modal == undefined) {
      newError.modal = lang("Please select vehicle model");
    }
    if (variant == "" || variant == null || variant == undefined) {
      newError.variant = lang("Please select vehicle model");
    }
    if (year == "" || year == null || year == undefined) {
      if(make !== "65b11a5abed526d30b39f773"){
        newError.year = lang("Please select year");
      }
    }
    // if (vin == "" || vin == null || vin == undefined) {
    //   newError.vin = lang("Please enter VIN number");
    // }
    // if (image == "" || image == null || image == undefined) {
    //   newError.image = lang("Please Select an Image");
    // }

    setErrors(newError);

    if (Object.keys(newError).length === 0) {
      if (flag === "add") {
        request({
          url: apiPath.addVehicle,
          method: "POST",
          data: {
            type_id: type,
            make_id: make,
            model_id: modal,
            variant_id: variant,
            year,
            vin_number: vin,
            image,
          },
          onSuccess: (data) => {
            if (data.status) {
              ShowToast(data.message, Severty.SUCCESS);
              setRefresh();
            } else {
              ShowToast(data.message, Severty.ERROR);
            }
          },
          onError: (err) => {
            console.log(err);
          },
        });
        onClose();
      } else if (flag === "edit") {
        request({
          url: apiPath.addVehicle + `/${car?._id}`,
          method: "POST",
          data: {
            type_id: type,
            make_id: make,
            model_id: modal,
            variant_id: variant,
            year: year.toString(),
            vin_number: vin,
            image,
          },
          onSuccess: (data) => {
            if (data.status) {
              ShowToast(data.message, Severty.SUCCESS);
              setRefresh();
              onClose();
            }
          },
          onError: (err) => {
            console.log(err);
            onClose();
          },
        });
      } else {
        setFormData((prev) => {
          return {
            ...prev,
            // user_car: [{ type, make, modal, vin, image }],
            user_car: [
              {
                type_id: type,
                make_id: make,
                model_id: modal,
                variant_id: variant,
                year,
                vin_number: vin,
                image,
              },
            ],
          };
        });
        onClose("");
        ShowToast(lang("Vehicle Added Successfully"), Severty.SUCCESS);
      }
    }
  };

  useEffect(() => {
    const newError = {};
    if (type) newError.type = "";
    if (make) newError.make = "";
    if (modal) newError.modal = "";
    if (variant) newError.variant = "";
    if (vin) newError.vin = "";
    if (year) newError.year = "";
    if (image) newError.image = "";

    setErrors((prev) => {
      return {
        ...prev,
        ...newError,
      };
    });
  }, [type, make, modal, vin, variant, year]);

  return (
    <div>
      <Modal
        className="addVehicleModal"
        show={true}
        onHide={() => onClose("")}
        centered
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title className="fs-5 fw-bold">
            {lang("Add New Vehicle")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="addVehicleForm" onSubmit={handleSubmit}>
            <Row className="gap-3 ">
              <Form.Group as={Col} md="12">
                <Form.Label>{lang("Vehicle Type")}</Form.Label>
                <span className="text-danger"> *</span>
                <Form.Select
                  className="text-capitalize"
                  aria-label="Default select example"
                  onChange={(e) => {
                    setType(e.target.value);
                    setCarMakeList([]);
                    setCarModalList([]);
                    setCarVariantList([]);
                    getMakeList(e.target.value);
                  }}
                >
                  <option value={""}>{lang("Select Type")}</option>
                  {carTypeList?.map((types, idx) => {
                    return (
                      <option
                        className="text-capitalize"
                        value={types?._id}
                        selected={
                          flag === "edit" && car?.type_id?._id == types?._id
                        }
                      >
                        {types?.name}
                      </option>
                    );
                  })}
                </Form.Select>
              </Form.Group>
              {errors.type && (
                <div
                  style={{
                    fontSize: "13px",
                    color: "red",
                    marginTop: -12,
                    marginLeft: 10,
                  }}
                >
                  {errors.type}
                </div>
              )}

              <Form.Group as={Col} md="12">
                <Form.Label>{lang("Make")}</Form.Label>
                <span className="text-danger"> *</span>
                <Form.Select
                  className="text-capitalize"
                  aria-label="Default select example"
                  onChange={(e) => {
                    setMake(e.target.value);
                    setCarModalList([]);
                    setCarVariantList([]);
                    getModalList(e.target.value);
                  }}
                >
                  <option value={""}>{lang("Select Make")}</option>
                  {carMakeList?.length
                    ? carMakeList?.map((make, idx) => {
                      return (
                        <option
                          className="text-capitalize"
                          value={make?._id}
                          selected={
                            flag === "edit" && car?.make_id?._id === make?._id
                          }
                        >
                          {make?.name}
                        </option>
                      );
                    })
                    : null}
                </Form.Select>
              </Form.Group>
              {errors.make && (
                <div
                  style={{
                    fontSize: "13px",
                    color: "red",
                    marginTop: -12,
                    marginLeft: 10,
                  }}
                >
                  {errors.make}
                </div>
              )}

              <Form.Group as={Col} md="12">
                <Form.Label>{lang("Model")}</Form.Label>
                <span className="text-danger"> *</span>
                <Form.Select
                  className="text-capitalize"
                  aria-label="Default select example"
                  onChange={(e) => {
                    setModal(e.target.value);
                    getVariantList(e.target.value);
                    setCarVariantList([]);
                  }}
                >
                  <option value={""}>{lang("Select Make")}</option>
                  {carModalList?.length
                    ? carModalList?.map((modal, idx) => {
                      return (
                        <option
                          className="text-capitalize"
                          value={modal?._id}
                          selected={
                            flag === "edit" &&
                            car?.model_id?._id === modal?._id
                          }
                        >
                          {modal?.name}
                        </option>
                      );
                    })
                    : null}
                </Form.Select>
              </Form.Group>
              {errors.modal && (
                <div
                  style={{
                    fontSize: "13px",
                    color: "red",
                    marginTop: -12,
                    marginLeft: 10,
                  }}
                >
                  {errors.modal}
                </div>
              )}

              <Form.Group as={Col} md="12">
                <Form.Label>{lang("Variant")}</Form.Label>
                <span className="text-danger"> *</span>
                <Form.Select
                  className="text-capitalize"
                  aria-label="Default select example"
                  onChange={(e) => {
                    setVariant(e.target.value);
                  }}
                >
                  <option value={""}>{lang("Select Variant")}</option>
                  {carVariantList?.length
                    ? carVariantList?.map((variant, idx) => {
                      return (
                        <option
                          className="text-capitalize"
                          value={variant?._id}
                          selected={
                            flag === "edit" &&
                            car?.variant_id?._id === variant?._id
                          }
                        >
                          {variant?.name}
                        </option>
                      );
                    })
                    : null}
                </Form.Select>
              </Form.Group>
              {errors.variant && (
                <div
                  style={{
                    fontSize: "13px",
                    color: "red",
                    marginTop: -12,
                    marginLeft: 10,
                  }}
                >
                  {errors.variant}
                </div>
              )}

              {make != "65b11a5abed526d30b39f773" &&
                <>
                  <Form.Group as={Col} md="12">
                    <Form.Label>{lang("Year")}</Form.Label>
                    <span className="text-danger"> *</span>
                    <Form.Select
                      aria-label="Default select example"
                      onChange={(e) => setYear(e.target.value)}
                    >
                      <option value={""}>{lang("Select Year")}</option>
                      {years?.map((year) => {
                        return (
                          <option
                            value={year}
                            selected={flag === "edit" && car?.year == year}
                          >
                            {year}
                          </option>
                        );
                      })}
                    </Form.Select>
                  </Form.Group>
                  {errors.year && (
                    <div
                      style={{
                        fontSize: "13px",
                        color: "red",
                        marginTop: -12,
                        marginLeft: 10,
                      }}
                    >
                      {errors.year}
                    </div>
                  )}
                </>
              }


              <Form.Group as={Col} md="12">
                <Form.Label>{lang("VIN Number")}</Form.Label>
                {/* <span className="text-danger"> *</span> */}
                <Form.Control
                  type="text"
                  placeholder={lang("Enter VIN Number")}
                  className=""
                  onChange={(e) => setVIN(e.target.value)}
                  defaultValue={car?.vin_number || ""}
                  value={vin?.toUpperCase()}
                />
              </Form.Group>
              {/* {errors.vin && (
                <div
                  style={{
                    fontSize: "13px",
                    color: "red",
                    marginTop: -12,
                    marginLeft: 10,
                  }}
                >
                  {errors.vin}
                </div>
              )} */}

              <Form.Group as={Col} md="12" controlId="formFile">
                <Form.Control
                  type="file"
                  className="d-none"
                  onChange={(e) => handleImageChange(e)}
                />
                <Form.Label className="vehicleImgUploadLabel p-3 w-100">
                  {loading ? (
                    <Loader />
                  ) : !image ? (
                    <>
                      <div className="imgOuter text-center">
                        <img src={fileUploadIcon} alt="" />
                      </div>
                      <div className="pera fs-16 mt-1 mb-2 text-center">
                        {lang(
                          "Browse and chose the vehicle image you want to upload from your computer"
                        )}
                      </div>
                      <p className="btn btnStyle1 iconBtn mx-auto d-flex align-items-center justify-content-center border-0 shadow-none text-white">
                        <img className="" src={Plusicon} alt="" />
                      </p>
                    </>
                  ) : (
                    <img
                      alt=""
                      src={apiPath.getImage + image}
                      width={"95%"}
                      height={250}
                    />
                  )}
                </Form.Label>
              </Form.Group>
              {/* {errors.image && (
                <div
                  style={{
                    fontSize: "13px",
                    color: "red",
                    marginTop: -12,
                    marginLeft: 10,
                  }}
                >
                  {errors.image}
                </div>
              )} */}

              <Form.Group as={Col} md="12" className="mt-2">
                <Button
                  disabled={loading}
                  type="submit"
                  className="btn btnStyle1 shadow-none text-white px-4 w-100"
                >
                  {lang("Save")}
                </Button>
              </Form.Group>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default AddVehicleModal;

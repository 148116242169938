import React, { useState } from "react";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Plusicon from '../Assets/img/icon/plusIcon.svg';
import QuotesCard from "../Components/quotes/QuotesCard";
import Banners from "../Components/banners/Banners";
import { Link, useParams, useSearchParams } from "react-router-dom";
import lang from "../helper/lang";


const Manage_quotes = () => {

    const [l1, setL1] = useState(0);
    const [l2, setL2] = useState(0);
    const [l3, setL3] = useState(0);
    
    const [searchParams, setSearchParams] = useSearchParams();
    const { quoteId } = useParams();
    const status = searchParams.get("status")
    
    let rqId='', fqId='';
    if(status === 'requested'){
        rqId = quoteId
    } 
    else if(status === 'fulfill') {
        fqId = quoteId
    }

    return (
        <>
            <div className="mainWraper">
                <div className="otherSliderMain sectionPading bgLight">
                    <Banners />
                </div>

                <div className="manageQuotes sectionPading">
                    <div className="container">
                        <div className="sectionHeading">
                            <div className="row align-items-center">
                                <div className="col">
                                    <div className="title d-inline-block">
                                        <div className="txt">{lang("Manage Quotes")}  ({l1 + l2 + l3})</div>
                                        <div className="titleDesign d-flex align-items-center gap-2 mt-3"></div>
                                    </div>
                                </div>
                                <div className="col-sm-auto mt-3 mt-sm-0">
                                    <div className="buttonGroup">
                                        <Link to="/request-quote" className="btn btnStyle1 d-flex align-items-center justify-content-center gap-3 shadow-none text-white">{lang("Request New Quote")} <img className="" src={Plusicon} /></Link>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="favTabs mt-2 position-relative">
                            <Tabs
                                defaultActiveKey={status || "requested"}
                                id="uncontrolled-tab-example"
                                className="mb-3"
                            >
                                <Tab eventKey="requested" title={`${lang("Requested")} (${l1})`}>
                                    <div className="requesteQuoteOuter">
                                        <QuotesCard quoteId={rqId} setLen={setL1} status={"request"} />
                                    </div>
                                </Tab>
                                <Tab eventKey="received" title={`${lang("Received")} (${l2})`}>
                                    <div className="receiveQuoteOuter">
                                        <QuotesCard setLen={setL2} status={"received"} />
                                    </div>
                                </Tab>
                                <Tab eventKey="fulfill" title={`${lang("Fulfilled")} (${l3})`}>
                                    <div className="receiveQuoteOuter">
                                        <QuotesCard quoteId={fqId} setLen={setL3} status={"fulfill"} />
                                    </div>
                                </Tab>
                            </Tabs>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Manage_quotes;

import react, { useState, useEffect, useRef, useContext } from "react";
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { Spinner } from 'react-bootstrap';
import { Skeleton, Image } from "antd";
import backArrow from '../Assets/img/icon/backArrow.svg';
import searchIcon from '../Assets/img/icon/searchIcon.svg';
import chatEmptyState from '../Assets/img/chatEmptyState.svg';
import NoDataFound from "../Assets/img/NotFound.png";
import chatFiles from '../Assets/img/icon/chatFiles.svg';
import msgSendIcon from '../Assets/img/icon/msgSendIcon.svg';
import lang from "../helper/lang";
import { AuthContext } from "../context/AuthContext";
import useFirebase from "../hooks/useFirebase";
import apiPath from "../constants/apiPath";
import { getFirestore, query, doc, collection, orderBy, onSnapshot, where, serverTimestamp } from 'firebase/firestore';
import { formatDate } from "../helper/functions";
import moment from "moment";
import Specialist1 from "../Assets/img/specialistImg1.png";
import photoIcon from "../Assets/img/icon/photoIcon.png";
import { ShowToast, Severty } from "../helper/toast";
import useRequest from "../hooks/useRequest";
import { useAppContext } from "../context/AppContext";
import { useParams } from "react-router";

const Chat = () => {

    const [searchText, setSearchText] = useState("");
    const [selected, setSelected] = useState();
    const [loading, setLoading] = useState(true);
    const [users, setUsers] = useState([])
    const [allUsers, setAllUsers] = useState([])
    const [selectedUser, setSelectedUser] = useState()
    const [discussItem, setDiscussItem] = useState()
    const { id } = useParams();
    const { chatInboxRef, updateActiveStatus } = useFirebase()
    const { isMobile } = useAppContext()

    const { userProfile } = useContext(AuthContext)

    useEffect(() => {

        if (!chatInboxRef) return
        if (!userProfile) return

        const subCollectionRef = collection(chatInboxRef, `${userProfile._id}`);
        const q = query(subCollectionRef, orderBy('startDate', 'desc'));

        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const data = [];
            querySnapshot.forEach((doc) => {
                data.push({ id: doc.id, ...doc.data() });
            });
            console.log('Subcollection data:', data, selected);

            if (data.length !== 0) {

                if (selected) {
                    const user = data.find(item => item.id == selected)
                    if (user) {
                        setAllUsers(data)
                        return setUsers(data)
                    }
                } else {
                    if (isMobile && id !== undefined) {
                        // setSelected(data[0].id)
                        // setSelectedUser(data[0])
                        console.log("id", id);
                    } else {
                        setSelected(data[0].id)
                        setSelectedUser(data[0])
                    }
                }
            }
            setUsers(data)
            setAllUsers(data)
            setLoading(false)
        });

        return unsubscribe;

    }, [chatInboxRef, userProfile, selected]);

    useEffect(() => {
        if (!userProfile) return
        updateActiveStatus(true)

        return () => {
            updateActiveStatus(false)
        }
    }, [userProfile])

    const onSearch = (text) => {
        if (!text) return setUsers(allUsers)
        setSearchText(text)
        const filteredArray = users.filter(item => item.name.toLowerCase().includes(text.toLowerCase()));
        setUsers(filteredArray)
    }

    return (
        <>
            <div className="mainWraper">
                <div className="requestQuote sectionPading">
                    <div className="container">
                        <div class="chatMainOuter">
                            <div class="chatMainInner bgDesign p-0 overflow-hidden h-100">
                                <div class="row h-100 mx-0">
                                    <div class="col-lg-5 col-xl-4 h-100 px-0">
                                        <div class="conatctSide h-100">
                                            <div class="row flex-column flex-nowrap h-100">
                                                <div class="col-12">
                                                    <div class="contactSearch p-sm-3 p-2 border-bottom">
                                                        <form class="" role="search">
                                                            <div className="headerSearch">
                                                                <InputGroup className="position-relative">
                                                                    <Form.Control onChange={({ target }) => onSearch(target.value)} type="search" className="rounded-1 pe-5" placeholder={lang("Search") + "..."} />
                                                                    <button type="search" className="btn searchBtn d-flex align-items-cneter justify-content-center m-0 rounded-1 border-0 shadow-none position-absolute end-0 top-0 bottom-0 me-1 my-auto">
                                                                        <img className="w-100" src={searchIcon} />
                                                                    </button>
                                                                </InputGroup>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                                <div class="col-12 flex-fill overflow-hidden h-100">
                                                    <div class="contactList overflow-x-hidden overflow-y-auto h-100">
                                                        {
                                                            loading ? [1, 2, 3, 4].map(item => <Skeleton key={item} active />) : users?.length ?
                                                                users.map(user =>
                                                                    <UserSection selected={selected} user={user} setSelectedUser={setSelectedUser} setSelected={setSelected} />
                                                                ) : (<div className="ms-3">
                                                                    <img width={120} src={NoDataFound} alt="" />
                                                                    <h6 className='pt-2'>{lang("No Data Found")}</h6>
                                                                </div>)}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <ChatDetail selected={selected} setSelected={setSelected} user={selectedUser} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const UserSection = ({ user, selected, setSelectedUser, setSelected }) => {

    const { userProfile } = useContext(AuthContext)

    return (<div key={user.id} class={`contactItem p-3 border-top ${selected === user.id ? "active" : ""
        } ${user?.unread ? 'newMessage' : ''}`} onClick={() => {
            setSelectedUser(user)
            setSelected(user?.id)
        }}>
        <div class="row gx-2 align-items-center">
            <div class="col-auto">
                <div class="profileOuter rounded-circle overflow-hidden">
                    <img class="w-100 h-100" src={user?.image ? apiPath.assetURL + user?.image : Specialist1} alt="" />
                </div>
            </div>
            <div class="col">
                <div class="title d-flex align-items-center justify-content-between">
                    <span class="name text-capitalize">{user?.name}</span>
                    <span class="seenTime">{formatDate(user?.startDate?.toDate())}</span>
                </div>
                <div class="message d-flex align-items-center gap-4 justify-content-between">
                    <div className="d-flex align-items-center gap-2" >
                        {user?.sendBy == userProfile?._id && (user?.is_read ?
                            <span className="msg_ic">
                                <img style={{ width: 15, height: 15 }} src="/assets/img/seen.png" alt="" />

                            </span> : <span className="msg_ic">
                                <img style={{ width: 15, height: 15 }} src="/assets/img/unseen.png" alt="" />

                            </span>)}
                        {user?.message_type != 'text' && user?.last_message ?
                            <span className="msg_ic">
                                {/* <img src="assets/svg/chat_img_icon.svg" alt="" /> */}
                                <img src={`/assets/svg/${user?.message_type == 'image' ? 'chat_img_icon.svg' : 'document-ic.svg'}`} alt="" />
                            </span>
                            :
                            ''
                        }
                        <div class={`lastMsg ${user?.message_type != 'text' ? 'text-capitalize' : ''}`}>
                            {user?.last_message ? (user.message_type === 'text' ? user.last_message : (user.message_type == 'image' ? 'Photo' : user?.message_type ?? '')) : ''}
                        </div>
                    </div>
                    <div class="newMsg rounded-circle justify-content-center d-flex align-items-center text-white p-1 d-none">{user?.unread}</div>
                </div>
            </div>
        </div>
    </div>)
}

const ChatDetail = ({ selected, setSelected, user }) => {

    const [messages, setMessages] = useState([]);
    const [loading, setLoadind] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [text, setText] = useState('')
    const [fileUrl, setFileUrl] = useState()
    const [fileType, setFileType] = useState()
    const [isDraggingOver, setIsDraggingOver] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const [toggle, setToggle] = useState(false);
    const [isOnline, setIsOnline] = useState();
    const { request } = useRequest()

    const { userProfile } = useContext(AuthContext)
    const { chatListRef, addChatToInbox, db, addMessageToChat, markRead, uploadFileToFirestore, chatActiveRef } = useFirebase()

    const scroll = useRef();

    useEffect(() => {
        if (!chatListRef || !selected) return;
        setLoadind(true)
        setIsActive(true)

        const subCollectionRef = collection(chatListRef, `${userProfile._id}_${selected}`);


        const q = query(subCollectionRef, orderBy('created_at', 'desc'));

        const unsubscribe = onSnapshot(q, async (querySnapshot) => {
            const data = [];
            const batch = db.batch(); // create a batch to update messages in bulk
            querySnapshot.forEach((doc) => {
                const message = { id: doc.id, ...doc.data() };
                if (!message.is_read && message.sender_id != userProfile._id) { // if the message is unread, mark it as read
                    // const messageRef = doc.ref;
                    // batch.update(messageRef, { is_read: true }); // update the message document
                }
                data.push(message);
            });

            await batch.commit(); // commit the batch to update messages
            await markRead(selected)
            console.log('chat data 5:', data);
            setMessages(data);
            scroll?.current?.scrollIntoView({ behavior: "smooth" });
            setUploading(false)
            setLoadind(false)
        });

        return unsubscribe;
    }, [chatListRef, selected]);

    useEffect(() => {

        //Update Read Message 
        if (!chatListRef || !selected) return;

        const subCollectionRef = collection(chatListRef, `${selected}_${userProfile._id}`)
        console.log(subCollectionRef, 'Read Message');
        const q = query(subCollectionRef, where('is_read', '==', false), orderBy('created_at', 'desc'));

        const unsubscribe = onSnapshot(q, async (querySnapshot) => {
            const data = [];
            const batch = db.batch(); // create a batch to update messages in bulk
            querySnapshot.forEach((doc) => {
                const message = { id: doc.id, ...doc.data() };
                if (!message.is_read && message.sender_id == userProfile._id) { // if the message is unread, mark it as read
                    const messageRef = doc.ref;
                    batch.update(messageRef, { is_read: true }); // update the message document
                }
                data.push(message);
            });
            await batch.commit(); // commit the batch to update messages

            console.log('users chat data 5:', data);

        });

        return unsubscribe;
    }, [chatListRef, selected]);

    useEffect(() => {
        if (!selected) return;
        const unsubscribe = onSnapshot(doc(db, "active", `${selected}`), (doc) => {
            console.log(doc.data(), 'doc', doc);
            if (doc.data()) {
                const message = { id: doc.id, ...doc.data() };
                console.log(message);
                setIsOnline(message)
            } else {
                setIsOnline()
            }
        });
        return unsubscribe;
    }, [selected]);


    useEffect(() => {
        if (!fileUrl) return
        //  sendMessage(fileUrl, fileType)
    }, [fileUrl])

    const add = async (text, type) => {

        const timestamp = new Date().getTime();
        const fileName = 'New-' + timestamp
        // const attach_name = type == 'pdf' ? `${fileName}.pdf` : (type == 'image' ? `${fileName}.jpeg` : '')
        const attach_name = type == 'pdf' ? `PDF` : (type == 'image' ? 'Photo' : 'Video')

        const senderObj = {
            created_at: serverTimestamp(),
            is_read: false,
            last_message: text,
            type: userProfile.type,
            last_seen: serverTimestamp(),
            name: user.name,
            image: user.image,
            receiver_id: user.id,
            sender_id: userProfile._id,
            attach_name,
            message_type: type,
            sendBy: userProfile._id
            //  is_online:true
        }

        const recevierObj = {
            created_at: serverTimestamp(),
            is_read: false,
            last_message: text,
            type: userProfile.type,
            last_seen: serverTimestamp(),
            name: userProfile.name,
            image: userProfile.image,
            receiver_id: userProfile._id,
            sender_id: user.id,
            is_online: true,
            attach_name,
            message_type: type,
            sendBy: userProfile._id
        }
        await addChatToInbox(user.id, senderObj, recevierObj, text)
    }

    const sendMessage = async (text, type = "text") => {
        if (!text) return
        setText('')
        const timestamp = new Date().getTime();
        const message = {
            receiver_id: selected,
            sender_id: userProfile._id,
            type,
            is_read: false,
            message: text,
            created_at: serverTimestamp()
        }
        setMessages(prev => [{ ...message, now: true }, ...prev])
        await addMessageToChat(selected, message)
        await add(text, type)

        if (!isOnline?.is_online) {
            sendNotification(text, selected)
        }
        scroll.current.scrollIntoView({ behavior: "smooth" });
    }

    const handleKeyPress = (e) => {
        if (e.key === "Enter") {
            sendMessage(text);
        }
    }

    const handleFileChange = async (event) => {
        setUploading(true)
        const file = event.target.files[0];

        if (!file) return

        const FileType = ['application/pdf', 'image/png', 'image/jpeg', 'image/jpg', 'video/mp4', 'video/webm']
        if (!FileType.includes(file.type)) {
            ShowToast('file type is not correct', Severty.ERROR)
            setUploading(false)
            return
        }

        const type = file.type == "application/pdf" ? 'pdf' : (file.type.includes('video') ? 'video' : 'image')
        setFileType(type)
        const url = await uploadFileToFirestore(file)
        console.log("file Url", url);

        setFileUrl(url)

        sendMessage(url, type)

        // setImageFile(file)
        // setImageUrl(URL.createObjectURL(event.target.files[0]));
    };

    const handleFileChangeServer = (event) => {
        setUploading(true)
        const file = event.target.files[0];
        if (!file) return

        const FileType = ['application/pdf', 'image/png', 'image/jpeg', 'image/jpg']
        if (!FileType.includes(file.type)) {
            ShowToast('file type is not correct', Severty.ERROR)
            setUploading(false)
            return
        }
        fileUpload(file, "chat_files")
    }

    const fileUpload = (file, type = 'document') => {

        return new Promise((resolve, reject) => {
            const formData = new FormData()
            formData.append('file', file)
            formData.append('type', type)

            request({
                url: `common/file-upload`,
                method: "POST",
                data: formData,
                onSuccess: ({ data, status }) => {
                    if (!status) return ShowToast("Something Went Wrong!", Severty.ERROR);
                    resolve(data.upload)
                },
                onError: (err) => {
                    ShowToast("Something Went Wrong!", Severty.ERROR)
                    setUploading(false)
                }
            })
        })
    }

    const sendNotification = (message, to_id) => {
        const payload = {
            message, to_id
        }
        request({
            url: "/common/db/send-notification",
            method: "POST",
            data: payload,
            onSuccess: (data) => {
                console.log(data);
            },
            onError: (err) => {
                console.log(err);
            }
        })
    }

    return (
        <div class="col-lg-7 col-xl-8 h-100 px-0">
            <div class={`chatingSide ${isActive ? `active` : ''} h-100`}>
                <div class="row flex-column flex-nowrap h-100 mx-0">
                    {/* user box */}
                    <div class="col-12 px-0">
                        <div class="chatHeader online p-sm-3 p-2 border-start">
                            {user ? (
                                <div class="clientPro d-flex align-items-center gap-2">
                                    <button onClick={() => setIsActive(false)} type="button" class="btn border-0 shadow-none bg-transparent p-0 backBtn d-lg-none">
                                        <img src={backArrow} alt="" />
                                    </button>
                                    <div class="imgOuter me-1 rounded-circle overflow-hidden">
                                        <img class="w-100 h-100" src={user?.image ? apiPath.assetURL + user?.image : Specialist1} alt="" />
                                    </div>
                                    <div class="detail">
                                        <div class="name text-capitalize">{user?.name}</div>
                                        {isOnline ?
                                            <div class="status">
                                                {
                                                    isOnline.is_online ?
                                                        <span class="online d-none align-items-center">Online</span> :
                                                        <span class=" online d-none align-items-center lastSeen">Last seen {formatDate(isOnline?.last_seen?.toDate())}</span>
                                                }
                                            </div>
                                            :
                                            <div class="status">
                                                <span class="online d-none align-items-center">Offline</span>
                                            </div>
                                        }
                                    </div>
                                </div>
                            )
                                : (loading
                                    ? <div class="detail">
                                        <div class="name py-2 px-3 m-0 text-white text-capitalize">Loading. . . . .</div>
                                        <div class="status">
                                            {
                                                user?.is_online ?
                                                    <span class="online d-none align-items-center"> : </span> :
                                                    <span class=" online d-none align-items-center lastSeen"> - </span>
                                            }
                                        </div>
                                    </div>
                                    : <div class="detail">
                                        <div class="name py-2 px-3 m-0 text-white text-capitalize">No Chat Found</div>
                                        <div class="status">
                                            {
                                                user?.is_online ?
                                                    <span class="online d-none align-items-center"> : </span> :
                                                    <span class=" online d-none align-items-center lastSeen"> - </span>
                                            }
                                        </div>
                                    </div>)}
                        </div>
                    </div>
                    {/* {(user?.title?.type === 'product' || user?.title?.type === 'service') && <div className='col-10 m-auto'>
                        <ul className='text-capitalize p-0 m-0 list-unstyled d-flex flex-column gap-2'>
                            <li className='row g-1'>
                                <div className='col-6 title'>{lang("Product Name")} :</div>
                                <div className='col-6 text-capitalize value'>{user?.title?.name}</div>
                            </li>
                            <li className='row g-1'>
                                <div className='col-6 title'>{lang("Brand")} :</div>
                                <div className='col-6 text-capitalize value'>{user?.title?.brand}</div>
                            </li>
                            <li className='row g-1'>
                                <div className='col-6 title'>{lang("Price")} :</div>
                                <div className='col-6 value'>R {user?.title?.price}</div>
                            </li>
                        </ul>
                    </div>} */}
                    {/* <div class="col-12 flex-fill h-100 overflow-hidden"> */}
                    <div class="col-12 px-0 flex-fill h-100 overflow-hidden" style={{ transform: "translate(0,0)" }}>
                        {(user?.title?.type === 'product')
                            ? <div className={`m-auto mt-0 ms-0 chatProductDetailOuter ${toggle ? 'hide' : ''} shadow-sm p-3 bg-white position-fixed top-0`}>
                                <ul className='text-capitalize p-0 m-0 list-unstyled d-flex flex-column gap-2'>
                                    <li className='row g-1'>
                                        <div className='col-sm-4 col-auto title fw-medium'>{lang("Product Name")} :</div>
                                        <div className='col text-capitalize value'>{user?.title?.name}</div>
                                    </li>
                                    <li className='row g-1'>
                                        <div className='col-sm-4 col-auto title fw-medium'>{lang("Brand")} :</div>
                                        <div className='col text-capitalize value'>{user?.title?.brand}</div>
                                    </li>
                                    <li className='row g-1'>
                                        <div className='col-sm-4 col-auto title fw-medium'>{lang("Model")} :</div>
                                        <div className='col text-capitalize value'>{user?.title?.model}</div>
                                    </li>
                                    <li className='row g-1'>
                                        <div className='col-sm-4 col-auto title fw-medium'>{lang("Variant")} :</div>
                                        <div className='col text-capitalize value'>{user?.title?.variant}</div>
                                    </li>
                                    <li className='row g-1'>
                                        <div className='col-sm-4 col-auto title fw-medium'>{lang("Price")} :</div>
                                        <div className='col value'>R {user?.title?.price}</div>
                                    </li>
                                </ul>
                                <button type="button" onClick={() => setToggle(prev => !prev)} className="btn shadow-sm hideArrowBtn border-0 position-absolute">
                                    <img className="h-100" src="https://cdn.iconscout.com/icon/free/png-256/free-down-arrow-1965050-1660443.png" />
                                </button>
                            </div>
                            : (user?.title?.type === 'service')
                                ? <div className={`m-auto mt-0 ms-0 chatProductDetailOuter ${toggle ? 'hide' : ''} shadow-sm p-3 bg-white position-fixed top-0`}>
                                    <ul className='text-capitalize p-0 m-0 list-unstyled d-flex flex-column gap-2'>
                                        <li className='row g-1'>
                                            <div className='col-sm-4 col-auto title fw-medium'>{lang("Service Name")} :</div>
                                            <div className='col text-capitalize value'>{user?.title?.name}</div>
                                        </li>
                                        <li className='row g-1'>
                                            <div className='col-sm-4 col-auto title fw-medium'>{lang("Category")} :</div>
                                            <div className='col text-capitalize value'>{user?.title?.category}</div>
                                        </li>
                                        <li className='row g-1'>
                                            <div className='col-sm-4 col-auto title fw-medium'>{lang("Price")} :</div>
                                            <div className='col value'>R {user?.title?.price}</div>
                                        </li>
                                    </ul>
                                    <button type="button" onClick={() => setToggle(prev => !prev)} className="btn shadow-sm hideArrowBtn border-0 position-absolute">
                                        <img className="h-100" src="https://cdn.iconscout.com/icon/free/png-256/free-down-arrow-1965050-1660443.png" />
                                    </button>
                                </div>
                                : (user?.title?.type === 'quote') ? <div className={`m-auto mt-0 ms-0 chatProductDetailOuter ${toggle ? 'hide' : ''} shadow-sm p-3 bg-white position-fixed top-0`}>
                                    <ul className='text-capitalize p-0 m-0 list-unstyled d-flex flex-column gap-2'>
                                        <li className='row g-1'>
                                            <div className='col-sm-4 col-auto title fw-medium'>{lang("Quote Request Name")} :</div>
                                            <div className='col text-capitalize value'>{user?.title?.name}</div>
                                        </li>
                                        <li className='row g-1'>
                                            <div className='col-sm-4 col-auto title fw-medium'>{lang("QuoteId")} :</div>
                                            <div className='col text-capitalize value'>{user?.title?.quoteId}</div>
                                        </li>
                                        <li className='row g-1'>
                                            <div className='col-sm-4 col-auto title fw-medium'>{lang("Price")} :</div>
                                            <div className='col value'>R {user?.title?.price}</div>
                                        </li>
                                    </ul>
                                    <button type="button" onClick={() => setToggle(prev => !prev)} className="btn shadow-sm hideArrowBtn border-0 position-absolute">
                                        <img className="h-100" src="https://cdn.iconscout.com/icon/free/png-256/free-down-arrow-1965050-1660443.png" />
                                    </button>
                                </div> : null
                        }
                        <div class="chatOuter overflow-x-hidden overflow-y-auto h-100 p-3 d-flex flex-column-reverse">


                            {
                                loading ?
                                    [1, 2, 3].map(item => <Skeleton active key={item} />)
                                    :
                                    messages.map((item, idx) => {
                                        if (item.sender_id === userProfile._id) {
                                            if (idx === 0) {
                                                return (
                                                    <>
                                                        <span ref={scroll}></span>
                                                        <Send data={item} key={item.id} />
                                                    </>
                                                )
                                            }
                                            return <Send data={item} key={item.id} />

                                        } else {
                                            return <Receive data={item} img={user?.image} key={item.id} />
                                        }
                                    })

                            }
                            {
                                uploading &&
                                <Spinner animation="border" size="sm" />
                            }
                            {/* <Send />
                            <div class="msgDate text-center py-1 d-flex align-items-center justify-content-center"><div class="date px-3">Today</div></div>
                            <Receive /> */}

                        </div>
                    </div>
                    <div class="col-12 px-0">
                        <div class="chatFooter p-sm-3 p-2">
                            {/* <form class="" role="search"> */}
                            <div class="position-relative d-flex align-items-center gap-sm-3 gap-2">
                                <div class="fileUpload">
                                    <input type="file" onChange={handleFileChange} class="form-control d-none shadow-none border-0" id="uploadImg" />
                                    <label for="uploadImg" class="fileAtechLabel d-flex align-items-center justify-content-center"><img src={chatFiles} alt="" /></label>
                                </div>
                                <InputGroup className="position-relative">
                                    <Form.Control
                                        type="text"
                                        value={text}
                                        className="rounded-1 pe-5"
                                        placeholder={lang("Type") + "..."}
                                        onChange={({ target }) => setText(target.value)}
                                        onKeyDown={(e) => handleKeyPress(e)}
                                    />
                                </InputGroup>
                                <div class="footerBtnGroup d-flex align-items-center position-absolute gap-2">
                                    <button onClick={() => sendMessage(text)} class="btn border-0 shadow-none btnMsgSend d-flex align-items-center justify-content-center">
                                        <img className="" src={msgSendIcon} />
                                    </button>
                                </div>
                            </div>
                            {/* </form> */}
                        </div>
                    </div>
                    {/* <span ref={scroll}></span> */}
                    {/* </div> */}
                </div>
                <div className="emptyState h-100 d-none d-lg-none align-items-center justify-content-center">
                    <div className="emptystateInner">
                        <div className="emptyStateImg">
                            <img className="" src={chatEmptyState} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const Send = ({ data }) => {
    return (
        <div class="msgMain send row mx-0 gap-2">
            <div class="col msgCol px-0">


                <div class={`msg txtMsg overflow-hidden mb-2 ${data.type != 'text' ? 'd-flex flex-column align-items-end' : ''}`}>

                    {
                        data.type == "text" ?
                            <div class="txt">{data?.message}</div>
                            : (
                                data.type == "pdf" ?
                                    <a href={data.message} target="_blank" className="rounded-3 overflow-hidden">
                                        <img style={{ height: 150 }} src='/assets/img/pdf_icon.png' alt="" />
                                    </a>
                                    :
                                    (data.type == "video" ? <video width="320" height="240" controls> <source src={data.message} type="video/mp4" /> </video> : <Image src={data.message} alt="" style={{ width: "120px", height: "120px", borderRadius: "5px" }} />)
                            )}

                    <div class="msgTime mt-2 pt-1 float-end ms-3">
                        {data?.now ? 'now' : moment(data?.created_at?.toDate()).format('h:mm a')}
                        {(data?.is_read ?
                            <img className="ms-1" style={{ width: 15, height: 15 }} src="/assets/img/seen.png" alt="" />
                            :
                            <img className="ms-1" style={{ width: 15, height: 15 }} src="/assets/img/unseen.png" alt="" />)}
                    </div>
                </div>

            </div>
        </div>
    )
}

const Receive = ({ data, img }) => {

    return (
        <div class="msgMain row mx-0 gap-2">
            <div class="col-auto imgCol px-0">
                <div class="imgOuter rounded-circle overflow-hidden">
                    <img class="w-100 h-100" src={img ? apiPath.assetURL + img : Specialist1} alt="" />
                </div>
            </div>
            <div class="col msgCol px-0">
                <div class={`msg txtMsg overflow-hidden mb-2 ${data.type != 'text' ? 'd-flex flex-column align-items-end' : ''}`}>
                    {
                        data.type == "text" ?
                            <div class="txt">{data?.message}</div>
                            : (
                                data.type == "pdf" ?
                                    <a href={data.message} target="_blank" className="rounded-3 overflow-hidden">
                                        <img style={{ height: 150 }} src='/assets/img/pdf_icon.png' alt="" />
                                    </a>
                                    :
                                    (data.type == "video" ? <video width="320" height="240" controls> <source src={data.message} type="video/mp4" /> </video> : <Image src={data.message} alt="" style={{ width: "120px", height: "120px", borderRadius: "5px" }} />)
                            )}

                    <div class="msgTime mt-1 float-end ms-3">{data?.now ? 'now' : moment(data?.created_at?.toDate()).format('h:mm a')}</div>
                </div>
            </div>
        </div>
    )
}

export default Chat;

function getFileExtension(url) {
    // Get the last part of the URL after the last '/'
    const filename = url.substring(url.lastIndexOf('/') + 1);

    // Get the file extension by getting the last part of the filename after the last '.'
    const extension = filename.substring(filename.lastIndexOf('.') + 1);

    return extension;
};


/**
 * 
 * 
 *  <div class="col-lg-7 col-xl-8 h-100 px-0">
            <div class="chatingSide active h-100">
                <div class="row flex-column flex-nowrap h-100">
                    <div class="col-12">
                        <div class="chatHeader online p-sm-3 p-2 border-start">
                            <div class="clientPro d-flex align-items-center gap-2">
                                <button type="button" class="btn border-0 shadow-none bg-transparent p-0 backBtn d-lg-none"><img src={backArrow} alt="" /></button>
                                <div class="imgOuter me-1 rounded-circle overflow-hidden">
                                    <img class="w-100 h-100" src={user?.image} alt="" />
                                </div>
                                <div class="detail">
                                    <div class="name">{user?.name}</div>
                                    <div class="status">
                                        <span class="online d-none align-items-center">Online</span>
                                        <span class="lastSeen">Last seen 09:23 AM</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 flex-fill h-100 overflow-hidden">
                        <div class="chatOuter overflow-x-hidden overflow-y-auto h-100 p-3 d-flex flex-column-reverse">
                            <div class="msgMain send row mx-0 gap-2">
                                <div class="col msgCol px-0">
                                    <div class="msg txtMsg overflow-hidden mb-2">
                                        <div class="txt">Thank you</div>
                                        <div class="msgTime float-end ms-3 mt-1">09:23 AM</div>
                                    </div>
                                </div>
                            </div>
                            <div class="msgMain row mx-0 gap-2">
                                <div class="col-auto imgCol px-0">
                                    <div class="imgOuter rounded-circle overflow-hidden">
                                        <img class="w-100 h-100" src="https://images.unsplash.com/photo-1575936123452-b67c3203c357?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8aW1hZ2V8ZW58MHx8MHx8fDA%3D&w=1000&q=80" alt="" />
                                    </div>
                                </div>
                                <div class="col msgCol px-0">
                                    <div class="msg txtMsg overflow-hidden mb-2">
                                        <div class="txt">Ok Done</div>
                                        <div class="msgTime float-end ms-3 mt-1">09:23 AM</div>
                                    </div>
                                </div>
                            </div>
                            <div class="msgDate text-center py-1 d-flex align-items-center justify-content-center"><div class="date px-3">Today</div></div>
                            <div class="msgMain send row mx-0 gap-2">
                                <div class="col msgCol px-0">
                                    <div class="msg txtMsg overflow-hidden mb-2">
                                        <div class="txt">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Iure cum corrupti maxime.</div>
                                    </div>
                                    <div class="msgTime float-end ms-3 mt-1">09:23 AM</div>
                                </div>
                            </div>
                            <div class="msgMain row mx-0 gap-2">
                                <div class="col-auto imgCol px-0">
                                    <div class="imgOuter rounded-circle overflow-hidden">
                                        <img class="w-100 h-100" src="https://images.unsplash.com/photo-1575936123452-b67c3203c357?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8aW1hZ2V8ZW58MHx8MHx8fDA%3D&w=1000&q=80" alt="" />
                                    </div>
                                </div>
                                <div class="col msgCol px-0">
                                    <div class="msg txtMsg overflow-hidden mb-2">
                                        <div class="txt">Lorem ipsum dolor sit amet consectetur adipisicing elit. Atque, ab perferendis et sapiente cumque maiores earum ducimus esse reiciendis eius iusto, doloribus dicta velit tempore. Fugiat omnis dolore illum necessitatibus!</div>
                                        <div class="msgTime float-end ms-3 mt-1">09:23 AM</div>
                                    </div>
                                </div>
                            </div>
                            <div class="msgMain send row mx-0 gap-2">
                                <div class="col msgCol px-0">
                                    <div class="msg txtMsg overflow-hidden mb-2">
                                        <div class="txt">Hello john, how are you?</div>
                                        <div class="msgTime float-end ms-3 mt-1">09:23 AM</div>
                                    </div>

                                    <div class="msg docMsg overflow-hidden mb-2">
                                        <div className="document d-flex align-items-center gap-2">
                                            <div class="docIcon overflow-hidden">
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M19.5 9H13.5V3H4.5V21H19.5V9ZM18.879 7.5L15 3.621V7.5H18.879ZM3.75 1.5H15L21 7.5V21.75C21 21.9489 20.921 22.1397 20.7803 22.2803C20.6397 22.421 20.4489 22.5 20.25 22.5H3.75C3.55109 22.5 3.36032 22.421 3.21967 22.2803C3.07902 22.1397 3 21.9489 3 21.75V2.25C3 2.05109 3.07902 1.86032 3.21967 1.71967C3.36032 1.57902 3.55109 1.5 3.75 1.5ZM7.5 12H16.5V13.5H7.5V12ZM7.5 7.5H11.25V9H7.5V7.5ZM7.5 16.5H16.5V18H7.5V16.5Z" fill="#121212" />
                                                </svg>
                                            </div>
                                            <div className="documentName">New code.pdf</div>
                                        </div>
                                        <div class="txt">Hello john, how are you?</div>
                                        <div class="msgTime float-end ms-3 mt-1">09:23 AM</div>
                                    </div>
                                </div>
                            </div>
                            <div class="msgMain row mx-0 gap-2">
                                <div class="col-auto imgCol px-0">
                                    <div class="imgOuter rounded-circle overflow-hidden">
                                        <img class="w-100 h-100" src="https://images.unsplash.com/photo-1575936123452-b67c3203c357?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8aW1hZ2V8ZW58MHx8MHx8fDA%3D&w=1000&q=80" alt="" />
                                    </div>
                                </div>
                                <div class="col msgCol px-0">
                                    <div class="msg txtMsg overflow-hidden mb-2">
                                        <div class="txt">Hello john, how are you?</div>
                                        <div class="msgTime float-end ms-3 mt-1">09:23 AM</div>
                                    </div>
                                    <div class="msg docMsg overflow-hidden mb-2">
                                        <div className="document d-flex align-items-center gap-2">
                                            <div class="docIcon overflow-hidden">
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M19.5 9H13.5V3H4.5V21H19.5V9ZM18.879 7.5L15 3.621V7.5H18.879ZM3.75 1.5H15L21 7.5V21.75C21 21.9489 20.921 22.1397 20.7803 22.2803C20.6397 22.421 20.4489 22.5 20.25 22.5H3.75C3.55109 22.5 3.36032 22.421 3.21967 22.2803C3.07902 22.1397 3 21.9489 3 21.75V2.25C3 2.05109 3.07902 1.86032 3.21967 1.71967C3.36032 1.57902 3.55109 1.5 3.75 1.5ZM7.5 12H16.5V13.5H7.5V12ZM7.5 7.5H11.25V9H7.5V7.5ZM7.5 16.5H16.5V18H7.5V16.5Z" fill="#121212" />
                                                </svg>
                                            </div>
                                            <div className="documentName">New code.pdf</div>
                                        </div>
                                        <div class="txt">Hello john, how are you?</div>
                                        <div class="msgTime float-end ms-3 mt-1">09:23 AM</div>
                                    </div>
                                </div>
                            </div>
                            <div class="msgDate text-center py-1 d-flex align-items-center justify-content-center"><div class="date px-3">March 21, 2023</div></div>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="chatFooter p-sm-3 p-2">
                            <form class="" role="search">
                                <div class="position-relative d-flex align-items-center gap-sm-3 gap-2">
                                    <div class="fileUpload">
                                        <input type="file" class="form-control d-none shadow-none border-0" id="uploadImg" />
                                        <label for="uploadImg" class="fileAtechLabel d-flex align-items-center justify-content-center"><img src={chatFiles} alt="" /></label>
                                    </div>
                                    <InputGroup className="position-relative">
                                        <Form.Control type="text" className="rounded-1 pe-5" placeholder={lang("Type") + "..."} />
                                    </InputGroup>
                                    <div class="footerBtnGroup d-flex align-items-center position-absolute gap-2">
                                        <button type="button" class="btn border-0 shadow-none btnMsgSend d-flex align-items-center justify-content-center">
                                            <img className="" src={msgSendIcon} />
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <span ref={scroll}></span>
                </div>
            </div>
            <div className="emptyState h-100 d-none d-lg-none align-items-center justify-content-center">
                <div className="emptystateInner">
                    <div className="emptyStateImg">
                        <img className="" src={chatEmptyState} />
                    </div>
                </div>
            </div>
        </div>
 */

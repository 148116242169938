import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import OwlCarousel from "react-owl-carousel3";
import Specialist1 from "../../Assets/img/specialistImg1.png";
import { specialistsSlider } from "../../constants/sliders";
import apiPath from "../../constants/apiPath";
import lang from "../../helper/lang";
import { useAppContext } from "../../context/AppContext";
import Loader from "../Loader";
import useRequest from "../../hooks/useRequest";
import { LazyLoadImage } from 'react-lazy-load-image-component';


const SpecialistSection = () => {
    const { language } = useAppContext();
    const [specialists, setSpecialists] = useState([]);
    const [loading, setLoading] = useState(false);
    const { request } = useRequest();

    const getSpecialists = () => {
        setLoading(true);
        request({
            url: apiPath.allSpecialists,
            method: "GET",
            onSuccess: (data) => {
                if (data.status) {
                    console.log(data.data)
                    setSpecialists(data.data)
                }
                setLoading(false);
            },
            onError: (err) => {
                console.log(err);
                setLoading(false);
            }
        })
    }

    useEffect(() => {
        getSpecialists();
    }, []);

    return (specialists.length &&
        <div className="container">
            <div className="sectionHeading">
                <div className="row align-items-center">
                    <div className="col">
                        <div className="title d-inline-block">
                            <div className="txt">{lang("Specialists")}</div>
                            <div className="titleDesign d-flex align-items-center gap-2 mt-3"></div>
                        </div>
                    </div>
                    <div className="col-auto">
                        <Link to="/provider-list/specialist" className="textBtn">
                            {lang("See All")}
                        </Link>
                    </div>
                </div>
            </div>
            {loading ? <Loader /> : (<div className="specialistOuter mt-2">
                <OwlCarousel {...specialistsSlider}>
                    {specialists?.map((sp, idx) => {
                        return (
                            <Link
                                to={`/provider-profile/${sp._id}`}
                                className="categoriesItem text-center d-block text-decoration-none"
                            >
                                <div className="specialistItem bg-white d-flex align-items-center gap-2">
                                    <div className="imgOuter rounded-circle overflow-hidden">
                                        {/* <img
                                            alt=""
                                            className="h-100 w-100"
                                            src={
                                                sp?.image
                                                ? apiPath.getImage + sp?.image
                                                : Specialist1
                                            }
                                        /> */}
                                        <LazyLoadImage
                                            alt={"Image"}
                                            height={"100%"}
                                            src={sp?.image ? apiPath.getImage + sp?.image : Specialist1}
                                            width={"100%"}
                                        />
                                    </div>
                                    <div className="specialistDetail">
                                        <div className="name text-uppercase">{language !== ('en' || null) ? (sp[`${language}_name`] ?? sp?.name) : sp?.name}</div>
                                        {/* <div className="dec mt-1">{lang("Specialist Title")}</div> */}
                                    </div>
                                </div>
                            </Link>
                        );
                    })}
                </OwlCarousel>
            </div>)}
        </div>
    );
};

export default SpecialistSection;
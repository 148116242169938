import React, { createContext, useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { ShowToast, Severty } from '../helper/toast';
import lang from '../helper/lang';
import apiPath from '../constants/apiPath';
import axios from 'axios';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {

    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [session, setSession] = useState({ token: null });

    const [userProfile, setUserProfile] = useState()
    const [latitude, setLatitude] = useState(null);
    const [longitude, setLongitude] = useState(null);

    const getUserLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    setLatitude(position.coords.latitude);
                    setLongitude(position.coords.longitude);
                },
                (error) => {
                    console.error('Error getting user location:', error.message);
                }
            );
        } else {
            console.error('Geolocation is not supported by this browser.');
        }
    };

    useEffect(() => {
        let flag = localStorage.getItem('flag') || null;
        if (flag === null) {
            localStorage.setItem('flag', 'true');
        }
    }, [isLoggedIn]);

    useEffect(() => {
        let token = localStorage.getItem('token')
        if (!token) return;

        axios.get(apiPath.baseURL + apiPath.profile, {
            headers: { 'Authorization': `Bearer ${token}` }
        })
            .then(data => {
                if (data.status) {
                    setUserProfile(data.data);
                }
            })
            .catch(err => {
                if (err?.response?.data?.status === 400) {
                    logout('inactive');
                }
            })

        getUserLocation();
        setIsLoggedIn(true)
        setSession({ token: token })
    }, [])

    useEffect(() => {
        if (!userProfile) return
        // localStorage.setItem('userProfile',JSON.stringify(userProfile?.data))
    }, [userProfile])

    useEffect(() => {
        if (!isLoggedIn) return

    }, [isLoggedIn])

    const login = () => {
        setIsLoggedIn(true);
        return <Navigate to="/dashboard" />
    }


    const logout = (val) => {

        localStorage.removeItem('token')
        localStorage.removeItem('userProfile')
        setIsLoggedIn(false)
        setSession({ token: null })
        setUserProfile()
        if (val === 'inactive') {
            ShowToast('Account Deactivated! Please contact to admin', Severty.ERROR)
        }
        else if (val) {
            ShowToast(val, Severty.ERROR);
        } else {
            ShowToast(lang('Logout Successfully'), Severty.SUCCESS);
        }
        return <Navigate to="/" />
    };

    return (
        <AuthContext.Provider
            value={{
                isLoggedIn,
                setIsLoggedIn,
                session,
                setSession,
                userProfile,
                setUserProfile,
                login, logout,
                latitude,
                longitude
            }}>
            {children}
        </AuthContext.Provider>
    );
};
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Header from "../Components/header/Header";
import Footer from "../Components/footer/footer";
import chatIconTheme from '../Assets/img/icon/chatIconTheme.svg';
import Banners from "../Components/banners/Banners";
import { useNavigate, useParams } from "react-router";
import useRequest from "../hooks/useRequest";
import apiPath from "../constants/apiPath";
import { Button } from "antd";
import { Link } from "react-router-dom";
import { Severty, ShowToast } from "../helper/toast";
import lang from "../helper/lang";
import { useAppContext } from "../context/AppContext";
import useFirebase from "../hooks/useFirebase";

const Quotes_detail = () => {
    const { id } = useParams();
    const { request } = useRequest();
    const [quote, setQuote] = useState();
    const [title, setTitle] = useState();
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const { addChat } = useAppContext()

    const getQuoteDetail = () => {
        setLoading(true);
        request({
            url: apiPath.getQuoteDetails(id),
            method: "GET",
            onSuccess: (data) => {
                if (data.status) {
                    console.log(data);
                    setQuote(data.data);
                    setLoading(false);
                }
            },
            onError: (err) => {
                console.log(err);
                setLoading(false);
            }
        })
    }

    const fulfillQuote = () => {
        request({
            url: apiPath.fulfillQuote,
            method: "POST",
            data: { quote_id: quote?.quote_id?._id, quoteId: quote?.quote_id?.quoteId, vendor_id: quote?.vendor_id?._id },
            onSuccess: (data) => {
                if (data.status) {
                    console.log(data);
                    ShowToast(data.message, Severty.SUCCESS);
                    navigate("/quotes")
                } else {
                    alert("Error")
                }
            },
            onError: (err) => {
                console.log(err);
                ShowToast(err.message, Severty.ERROR);
            }
        })
    }

    useEffect(() => {
        if (!quote) return

        const title = {
            name: `${quote.quote_id?.name}`,
            quoteId: `${quote.quote_id?.quoteId}`,
            type: 'quote',
            price: quote?.price
        }
        setTitle(title)

    }, [quote])

    useEffect(() => {
        getQuoteDetail();
    }, []);

    return (
        <>
            <div className="mainWraper">

                <div className="quotesDetail sectionPading">
                    <div className="container">
                        <div className="sectionHeading">
                            <div className="row align-items-center">
                                <div className="col">
                                    <div className="title d-inline-block">
                                        <div className="txt">Quote Detail</div>
                                        <div className="titleDesign d-flex align-items-center gap-2 mt-3"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="favTabs mt-2 position-relative">
                            <div className="row g-3">
                                <div className="col-xl-8">
                                    <div className="quoteItem bg-white p-3 h-100">
                                        <div className="quoteType text-capitalize mb-1">{quote?.quote_id?.part_type}</div>
                                        <div className="d-flex align-items-center justify-content-between py-2 border-bottom mb-1">
                                            <div className="quoteId mb-1">
                                                <div className="quoteValue"> #{quote?.quote_id?.quoteId}</div>
                                                <div className="quoteTitle">{lang("Quote ID")}</div>
                                            </div>
                                            <div className="quoteDetail mb-1 text-end">
                                                <div className="text-capitalize quoteValue">{quote?.quote_id?.vehicle_id?.make_id?.name} - {quote?.quote_id?.vehicle_id?.model_id?.name?.toUpperCase()}</div>
                                                <div className="quoteTitle">{lang("Make & Model")}</div>
                                            </div>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between pt-2">
                                            <div className="quoteId mb-1">
                                                <div className="text-capitalize quoteValue">{quote?.quote_id?.name}</div>
                                                <div className="quoteTitle">{lang("Request Name")}</div>
                                            </div>
                                            <div className="quoteDetail mb-1 text-end">
                                                <div className="quoteValue">R {quote?.price}</div>
                                                <div className="quoteTitle">{lang("New Price")}</div>
                                            </div>
                                        </div>
                                        <div className="text-capitalize quoteDec mt-2">{quote?.quote_id?.description}</div>
                                    </div>
                                </div>
                                <div className="col-xl-4">
                                    <div className="quoteDealerPro bg-white quoteItem p-3 h-100">
                                        <div className="row g-2 align-items-center">
                                            <div className="col-auto">
                                                <div className="imgOuter rounded-circle overflow-hidden">
                                                    <img alt="sp-profile" className="w-100 h-100" src={quote?.vendor_id?.image ? apiPath.getImage + quote?.vendor_id?.image : "https://www.w3schools.com/howto/img_avatar.png"} />
                                                </div>
                                            </div>
                                            <div className="col d-flex align-items-center justify-content-between">
                                                <div className="dealerDetail">
                                                    <div className="text-capitalize name">{quote?.vendor_id?.name}</div>
                                                    <div className="text-capitalize dec">Specialist</div>
                                                </div>
                                                {/* <Link to={"/chat"} className="text-decoration-none"> */}
                                                    <button 
                                                      onClick={async()=> {
                                                        await addChat(quote?.vendor_id,{...title});  
                                                        navigate('/chat')
                                                    }}
                                                        type="button"
                                                        class="btn btnStyle1 outLineThemeBtn d-flex align-items-center justify-content-center gap-2 border-0 shadow-none text-white">
                                                        Chat
                                                        <img alt="btn" className="" src={chatIconTheme} /></button>
                                                {/* </Link> */}
                                            </div>
                                        </div>
                                        <div className="buttonGroup mt-4 d-sm-flex justify-content-end d-xl-block">
                                            <Button onClick={fulfillQuote} className="btn btnStyle1 d-flex align-items-center justify-content-center gap-3 shadow-none text-white">Mark the Request Fulfilled</Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="otherSliderMain sectionPading bgLight">
                    <Banners />
                </div>

            </div>

        </>
    )
}

export default Quotes_detail;

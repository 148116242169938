
import en from '../lang/en';
import de from '../lang/de';
import fr from '../lang/fr';
import es from '../lang/es';

export const lang = (value) => { 
    const lang = localStorage.getItem("languageSet") ? JSON.parse(localStorage.getItem("languageSet")) : { value: 'en', label: 'English' }; 

    switch (lang.value) {
        case 'en': return en[value] || value
        case 'de': return de[value] || value
        case 'fr': return fr[value] || value
        case 'es': return es[value] || value
        default: return value
    }
}

export default lang;



/**
 * 
 * import { useAppContext } from "../context/AppContext";
 * import React, { useState, useEffect } from "react";
 * const lang = JSON.parse(localStorage.getItem("languageSet")) ? JSON.parse(localStorage.getItem("languageSet")) : { value: 'en', label: 'English' };

const Lang = ({ value }) => {

    const { setSelectedLanguage, selectedLanguage } = useAppContext()

    useEffect(() => {
        const lang = JSON.parse(localStorage.getItem("languageSet")) ? JSON.parse(localStorage.getItem("languageSet")) : { value: 'en', label: 'English' };
        setSelectedLanguage(lang.value)
    }, [])

    switch (lang) {
        case 'en': return en[value]
        case 'de': return de[value]
        case 'fr': return fr[value]
        case 'es': return es[value]
        default: return value
    }
}
 */
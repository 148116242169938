import React from "react";
import { useContext, useEffect, useState } from "react";
import Starfill from '../../Assets/img/icon/starfill.png';
import Productimg1 from '../../Assets/img/blub.png';
import { Link } from "react-router-dom";
import apiPath from "../../constants/apiPath";
import useRequest from "../../hooks/useRequest";
import { AuthContext } from "../../context/AuthContext";
import lang from "../../helper/lang";
import { useAppContext } from "../../context/AppContext";
import { LazyLoadImage } from 'react-lazy-load-image-component';


const ProductsCard = ({ prod, favourite, setRefresh }) => {
    const { request } = useRequest();
    const { isLoggedIn } = useContext(AuthContext)
    const { setShowDialouge, language } = useAppContext();
    const [fav, setFav] = useState(favourite);

    useEffect(() => {
        setFav(favourite)
    }, [prod])

    const addFavourite = (id) => {
        isLoggedIn
            ? (request({
                url: apiPath.addWishList,
                method: "POST",
                data: { product_id: id, type: "Product" },
                onSuccess: (data) => {
                    if (data.status) {
                        setFav(x => !x);
                        console.log(fav);

                        if (setRefresh) {
                            setRefresh();
                        }
                    }
                },
                onError: (err) => {
                    console.log(err);
                }
            }))
            : (setShowDialouge({ type: "login" }));
    }

    return (
        <div className="col-xxl col-lg-3 col-md-4 col-6">
            <div className="productItem bg-white">
                <div className="productImg w-100 position-relative">
                    {/* <img className="w-100 h-100" src={prod?.thumbnail || Productimg1} alt="" /> */}
                    <LazyLoadImage
                        alt={"Image"}
                        height={"100%"}
                        src={prod?.thumbnail || Productimg1}
                        width={"100%"} />
                    <div onClick={() => addFavourite(prod?._id)} className={`favbtn ${isLoggedIn && fav && "favouriteItem"} position-absolute top-0 end-0 mt-1 me-1 z-3`}>
                        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10.517 17.836C10.2337 17.936 9.76699 17.936 9.48366 17.836C7.06699 17.011 1.66699 13.5693 1.66699 7.73597C1.66699 5.16097 3.74199 3.07764 6.30033 3.07764C7.81699 3.07764 9.15866 3.81097 10.0003 4.9443C10.842 3.81097 12.192 3.07764 13.7003 3.07764C16.2587 3.07764 18.3337 5.16097 18.3337 7.73597C18.3337 13.5693 12.9337 17.011 10.517 17.836Z" stroke="#919191" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </div>
                </div>
                <div className="productDetail">
                    <div className="d-flex align-items-center justify-content-between gap-2">
                        <div className="proTitle text-capitalize">{prod?.name}</div>
                        {/* <div className="proRating d-flex align-items-center gap-1"><img src={Starfill} alt="" /> 4.5</div> */}
                    </div>
                    <div className="d-flex align-items-center justify-content-between gap-2">
                        <div className="brandName text-capitalize mt-1">{prod?.make_id?.name}</div>
                        <div className="brandName text-capitalize mt-1">{prod?.category_id?.name}</div>
                    </div>
                    <div className="productFooter d-flex align-items-center justify-content-between gap-2 mt-2">
                        {/* <div className="price">{prod?.price}</div> */}
                        <Link
                            to={`/product-detail/${prod?._id}`}
                            className="btn btnStyle1 d-flex align-items-center justify-content-center gap-3 shadow-none text-white text-uppercase">{lang("CHECK NOW")}</Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProductsCard;
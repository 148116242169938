/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useContext } from "react";
import OwlCarousel from 'react-owl-carousel3';
import Dropdown from 'react-bootstrap/Dropdown';
import Leftarrow from '../Assets/img/icon/saliderArrowLeft.png';
import Rightarrow from '../Assets/img/icon/saliderArrowRight.png';
import shortingIcon from '../Assets/img/icon/shortingIcon.svg';
import serviceImg1 from '../Assets/img/serviceImg1.jpg';
import serviceImg2 from '../Assets/img/serviceImg2.jpg';
import searchIcon from '../Assets/img/icon/searchIcon.svg';
import serviceImg3 from '../Assets/img/serviceImg3.jpg';
import filterIcon from '../Assets/img/icon/filterIcon.svg';
import apiPath from '../constants/apiPath';
import useRequest from "../hooks/useRequest";
import { useParams } from "react-router";
import Banners from "../Components/banners/Banners";
import ProvidersCard2 from "../Components/providers/ProvidersCard2";
import { AuthContext } from "../context/AuthContext";
import { AppStateContext } from "../context/AppContext";
import lang from "../helper/lang";
import NoDataFound from "../Assets/img/NotFound.png";
import { Form, InputGroup } from "react-bootstrap";


const serviceDetailSlider = {
    margin: 0,
    loop: true,
    nav: true,
    dots: false,
    autoplay: true,
    autoplayTimeout: 3000,
    autoplayHoverPause: false,
    navText: [`<img src=${Leftarrow}>`, `<img src=${Rightarrow}>`],
    items: 1,
    0: {
        items: 1
    }
}


const Service_detail = () => {

    const { id } = useParams();
    const { request } = useRequest();
    const [fav, setFav] = useState(false)
    const [loading, setLoading] = useState(false)
    const [service, setService] = useState({})
    const [specialists, setSpecialists] = useState([]);
    const [make, setMake] = useState([]);
    const [model, setModel] = useState([]);
    const { isLoggedIn } = useContext(AuthContext)
    const [toggle, setToggle] = useState(false);
    const [show, setShow] = useState(false);
    const [title, setTitle] = useState();
    const { setShowDialouge, language } = useContext(AppStateContext)
    const [mkId, setMKID] = useState();
    const [mdId, setMDID] = useState();

    const addFavourite = (id) => {
        isLoggedIn
            ? request({
                url: apiPath.addWishList,
                method: "POST",
                data: { service_id: id, type: "Service" },
                onSuccess: (data) => {
                    if (data.status) {
                        setFav(x => !x);
                        console.log(data);
                    }
                },
                onError: (err) => {
                    console.log(err);
                }
            }) : setShowDialouge({ type: 'login' })
    }

    const getSpecialists = () => {
        setLoading(true);
        request({
            url: apiPath.allSpecialists + "/" + id,
            method: "GET",
            onSuccess: (data) => {
                if (data.status) {
                    setSpecialists(data.data);
                    setService(data.service);
                    setFav(data.service?.is_wishlist);
                    console.log(data);
                } else {
                    alert("Error");
                }
                setLoading(false);
            },
            onError: (err) => {
                setLoading(false);
                console.log(err);
            }
        })
    }

    const handleSort = (val) => {
        setLoading(true);
        request({
            url: apiPath.allSpecialists + "/" + id + `?sort=${val}`,
            method: "GET",
            onSuccess: (data) => {
                if (data.status) {
                    setSpecialists(data.data);
                    console.log(data);
                } else {
                    alert("Error");
                }
                setLoading(false);
            },
            onError: (err) => {
                setLoading(false);
                console.log(err);
            }
        })
    }

    const handleSearch = (val) => {
        request({
            url: apiPath.allSpecialists + "/" + id + '?name=' + val,
            method: "GET",
            onSuccess: (data) => {
                if (data.status) {
                    setSpecialists(data.data);
                    console.log(data);
                } else {
                    alert("Error");
                }
                setLoading(false);
            },
            onError: (err) => {
                setLoading(false);
                console.log(err);
            }
        })
    }

    const handleApply = () => {
        setLoading(true);
        let urlArray = [];
        if (mkId) {
            urlArray.push({ param: "make", value: mkId })
        }
        if (mdId) {
            urlArray.push({ param: "model", value: mdId })
        }

        let queryParams = apiPath.allSpecialists + "/" + id;
        urlArray.length && urlArray?.forEach((elem, idx) => {
            console.log("elem", elem);
            if (idx === 0) {
                queryParams += `?${elem.param}=${elem.value}`
            } else {
                queryParams += `&&${elem.param}=${elem.value}`
            }
        })
        console.log('queryParams', queryParams);
        setShow(x => !x);
        request({
            url: queryParams,
            method: "GET",
            onSuccess: (data) => {
                if (data.status) {
                    setSpecialists(data.data);
                }
                setLoading(false);
            },
            onError: (err) => {
                setLoading(false);
                console.log(err);
            }
        })
    }

    const getModel = (id) => {
        // setLoading(true);
        request({
            url: apiPath.carModelList + `/${id}`,
            method: "GET",
            onSuccess: (data) => {
                if (data.status) {
                    setModel(data.data);
                }
                // setLoading(false);
            },
            onError: (err) => {
                // setLoading(false);
                console.log(err);
            }
        })
    }

    const getMake = (val) => {
        // setLoading(true);
        request({
            url: apiPath.carMakeList,
            method: "GET",
            onSuccess: (data) => {
                if (data.status) {
                    setMake(data.data);
                    // setMKID(data.data[0]?._id)   
                }
                // setLoading(false);
            },
            onError: (err) => {
                // setLoading(false);
                console.log(err);
            }
        })
    }

    useEffect(() => {
        getSpecialists();
        getMake();
    }, []);

    useEffect(() => {
        mkId && getModel(mkId);
    }, [mkId])

    useEffect(() => {
        if (!service) return
        const title = {
            name: `${service.name}`,
            type: 'service',
            image: service.image,
            price: service.price,
            category: `${service.category_id?.name ?? '-'}`,
        }
        setTitle(title)
    }, [service]);

    return (
        <>
            <div className="mainWraper">
                <div className="serviceDetailOuter sectionPading pt-0 overflow-hidden">
                    <div className="container-fluid px-0 ">
                        <OwlCarousel {...serviceDetailSlider} >
                            <div className="serviceImage">
                                <img className="w-100" src={serviceImg1} alt="" />
                            </div>
                            <div className="serviceImage">
                                <img className="w-100" src={serviceImg2} alt="" />
                            </div>
                            <div className="serviceImage">
                                <img className="w-100" src={serviceImg3} alt="" />
                            </div>
                        </OwlCarousel>
                        <div className="container">
                            <div className="serviceDec bg-white p-3 rounded-4 shadow d-flex align-items-center justify-content-between">
                                <div className="detail">
                                    <div className="text-capitalize name">{language !== ('en' || null) ? (service[`${language}_name`] ?? service?.name) : service?.name}</div>
                                    <div className="serviceCharge mt-2">R {service?.price}</div>
                                </div>
                                <div onClick={() => addFavourite(service?._id)} className={`favbtn ${fav && "favouriteItem"} d-flex align-items-center justify-content-center`}>
                                    <svg width="24" height="24" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10.517 17.836C10.2337 17.936 9.76699 17.936 9.48366 17.836C7.06699 17.011 1.66699 13.5693 1.66699 7.73597C1.66699 5.16097 3.74199 3.07764 6.30033 3.07764C7.81699 3.07764 9.15866 3.81097 10.0003 4.9443C10.842 3.81097 12.192 3.07764 13.7003 3.07764C16.2587 3.07764 18.3337 5.16097 18.3337 7.73597C18.3337 13.5693 12.9337 17.011 10.517 17.836Z" stroke="#121212" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="otherSliderMain sectionPading bgLight">
                    <Banners />
                </div>

                <div className="specialistWraper sectionPading">
                    <div className="container">
                        <div className="sectionHeading">
                            <div className="row align-items-center">
                                <div className="col">
                                    <div className="title d-inline-block">
                                        <div className="txt">{lang("Specialists")}</div>
                                        <div className="titleDesign d-flex align-items-center gap-2 mt-3"></div>
                                    </div>
                                </div>
                                <div className="col-auto">
                                    <div className="position-relative">
                                        <div onClick={() => setShow(x => !x)} className="p0 searchBtn d-flex align-items-cneter justify-content-center border-0 shadow-none">
                                            <img className="" src={filterIcon} alt="" />
                                        </div>
                                        <div className={`${!show ? 'd-none' : ''} filterWrap-cls`}>
                                            <div className="d-flex gap-2">
                                                <select value={mkId} onChange={(e) => { setMKID(e.target.value); }}>
                                                    <option value={''}>Make</option>
                                                    {
                                                        make.length && (make?.map((mk, idx) => {
                                                            return <option key={idx} value={mk?._id}><input type="checkbox" width={20} />{mk?.name}</option>
                                                        }))
                                                    }
                                                </select>
                                                <select value={mdId} onChange={(e) => { setMDID(e.target.value); }}>
                                                    <option value={''}>Model</option>
                                                    {
                                                        model.length && (model?.map((md, idx) => {
                                                            return <option key={idx} value={md?._id}><input type="checkbox" width={20} />{md?.name}</option>
                                                        }))
                                                    }
                                                </select>
                                            </div>
                                            <div className="border-bottom d-flex">
                                                <button onClick={() => { setMDID(''); setMKID(''); }}>Reset</button>
                                                <button onClick={() => handleApply()}>Apply</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-auto d-flex align-items-center gap-3">
                                    <InputGroup className="position-relative">
                                        <Form.Control type="search" id='headerSearch' onChange={({ target }) => handleSearch(target.value)} className="rounded-1 pe-5" placeholder={lang("Search") + "..."} />
                                        <label for="headerSearch" className="btn searchBtn d-flex align-items-cneter justify-content-center m-0 rounded-1 border-0 shadow-none position-absolute end-0 top-0 bottom-0 me-1 my-auto">
                                            <img className="w-100" src={searchIcon} alt="search" />
                                        </label>
                                    </InputGroup>
                                    <Dropdown className="filterDrop shortBy">
                                        <Dropdown.Toggle id="dropdown-basic" className="p0 searchBtn d-flex align-items-cneter justify-content-center border-0 shadow-none">
                                            <img className="" src={shortingIcon} alt="" style={{ filter: "invert(1)" }} />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu className="border-0 p-0">
                                            <Dropdown.Item className="border-bottom" onClick={() => handleSort('A-Z')} href="#sort=A-Z">A {lang("to")} Z</Dropdown.Item>
                                            <Dropdown.Item className="border-bottom" onClick={() => handleSort('Z-A')} href="#sort=Z-A">Z {lang("to")} A</Dropdown.Item>
                                            <Dropdown.Item className="border-bottom" onClick={() => handleSort('price_low')} href="#sort=Low-High">Price - Low to High</Dropdown.Item>
                                            <Dropdown.Item className="border-bottom" onClick={() => handleSort('price_high')} href="#sort=High-Low">Price - High to Low</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </div>
                        </div>
                        <div className="specialistOuter mt-2">
                            <div className="row g-3 row-cols-xs-2 row-cols-3 row-cols-md-4 row-cols-lg-5 row-cols-xl-6 row-cols-xxxl-8">
                                {specialists?.length ? specialists?.map((sp) => {
                                    return (
                                        <ProvidersCard2 pr={sp?.user_id} price={sp?.price} title={title} />
                                    )
                                }) : (<div>
                                    <img width={120} src={NoDataFound} alt="" />
                                    <h6 className='pt-2'>{lang("No Data Found")}</h6>
                                </div>)
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Service_detail;

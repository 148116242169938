import { BrowserRouter, Routes, Route, Outlet, useLocation } from 'react-router-dom';
import { AuthContext, AuthProvider } from './context/AuthContext';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Assets/js/Custom';
import './Assets/css/style.css';
import './Assets/css/responsive.css';
import Loader from './Components/Loader';
import ScrollToTop from './Components/ScrollToTop';

import PrivateRoute from './Components/PrivateRoute'
import React, { useContext, Suspense, useState, useEffect } from 'react';
import { AppContextProvider } from './context/AppContext';
import Error from './Pages/Error';
import { ToastContainer } from "react-toastify";
import Home from "./Pages/Auth/Home"
import Category from "./Pages/Category";
import Service_categories from "./Pages/Service_categories";
import Service_detail from "./Pages/Service_detail";
import My_vehicle_list from "./Pages/My_vehicle_list";
import Product_listing from "./Pages/Product_listing";
import Product_detail from "./Pages/Product_detail";
import Favourites from "./Pages/Favourites";
import Blog_list from "./Pages/Blog_list";
import Blog_detail from "./Pages/Blog_detail";
import Manage_quotes from "./Pages/Manage_quotes";
import Quotes_detail from "./Pages/Quotes_detail";
import Request_quote from "./Pages/Request_quote";
import Chat from "./Pages/Chat";
import Notification from "./Pages/Notification";
import My_profile from "./Pages/My_profile";
import Edit_profile from "./Pages/Edit_profile";
import About from "./Pages/About";
import Privacy_policy from "./Pages/Privacy_policy";
import Terms_condition from "./Pages/Terms_condition";
import 'react-toastify/dist/ReactToastify.css';
import ProviderProfile from './Pages/ProviderProfile';
import ProviderList from './Pages/ProviderList';
import SpecialistReplyList from './Pages/SpecialistReplyList';
import Bottom_bar from './Components/bottom_bar/Bottom_bar';
import Products from './Pages/Products';
import Faq from './Pages/Faq';
import Brands from './Components/brands/Brands';
import BrandList from './Pages/BrandList';
import BrandProducts from './Pages/BrandsProduct';
import Header from './Components/header/Header';
import Footer from './Components/footer/footer';
import Services from './Pages/Services';
import QuotesDetailsPage from './Pages/QuoteDetailsPage';


window.Buffer = window.Buffer || require("buffer").Buffer;

function App() {

  return (
    <AuthProvider>
      <AppContextProvider>
        <Suspense fallback={<Loader />}>
          <BrowserRouter>
            <ScrollToTop />
            <ToastContainer closeOnClick={false} />
            <AppRoutes />
          </BrowserRouter>
        </Suspense>
      </AppContextProvider>
    </AuthProvider>
  );
}

const AppRoutes = () => {

  const { isLoggedIn } = useContext(AuthContext);
  const [query, setQuery] = useState("");
  const location = useLocation();
  console.log(location);

  let flag = localStorage.getItem('flag');

  return (
    <>
      {<Header query={query} setQuery={setQuery} />}

      <Routes >
        <Route path="/" element={<Home />} />
        <Route path="/category" element={<Category />} />
        <Route path="/product-listing/:id?" element={<Product_listing />} />
        <Route path="/brand-products/:id?" element={<BrandProducts />} />
        <Route path="/product-detail/:id" element={<Product_detail />} />
        <Route path="/service-categories/:id" element={<Service_categories />} />
        <Route path="/service-detail/:id" element={<Service_detail />} />
        <Route path="/products" element={<Products />} />
        <Route path="/provider-list/:type" element={<ProviderList />} />
        <Route path="/provider-profile/:id" element={<ProviderProfile />} />
        <Route path="/term-condition" element={<Terms_condition />} />
        <Route path="/privacy-policy" element={<Privacy_policy />} />
        <Route path="/blog" element={<Blog_list />} />
        <Route path="/blog-detail/:id" element={<Blog_detail />} />
        <Route path="/brands" element={<BrandList />} />
        <Route path="/services" element={<Services />} />
        <Route path="/about" element={<About />} />

        <Route path="/" element={<PrivateRoute><Layout /></PrivateRoute>}>
          <Route path="/vehicle-list" element={<My_vehicle_list />} />
          <Route path="/favourites" element={<Favourites />} />
          <Route path="/quotes/:quoteId?" element={<Manage_quotes />} />
          <Route path="/quote-reply/:id" element={<SpecialistReplyList />} />
          <Route path="/quote-detail/:id" element={<Quotes_detail />} />
          <Route path="/request-quote" element={<Request_quote />} />
          <Route path="/quote-details-page/:id" element={<QuotesDetailsPage />} />
          <Route path="/chat/:id?" element={<Chat />} />
          <Route path="/notification" element={<Notification />} />
          <Route path="/profile" element={<My_profile />} />
          <Route path="/edit-profile" element={<Edit_profile />} />
          <Route path="/faq" element={<Faq />} />
        </Route >

        <Route path="*" element={<Error />} />
      </Routes >

      {(location.pathname !== '/chat' || location.pathname !== '/chat/1') && <Footer query={query} setQuery={setQuery} />}
      {isLoggedIn && (location.pathname !== '/chat') && <Bottom_bar />}

    </>
  )
}

const Layout = () => {
  return (<> <Outlet />  </>)
}

export default App;
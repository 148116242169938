import React from "react";
import BlogImg1 from "../../Assets/img/blogImg1.png"
import moment from "moment";
import { Link } from "react-router-dom";
import { useAppContext } from "../../context/AppContext";


const BlogsCard = ({blog}) => {
    const {language} = useAppContext();
    return (
        <div className="col cols">
            <Link to={`/blog-detail/${blog?._id}`} className="blogItem bg-white d-block text-decoration-none">
                <div className="blogImg">
                    <img className="w-100 h-100" src={blog?.thumbnail || BlogImg1} />
                </div>
                <div className="blogDetail">
                    <div className="blogDate"> {moment(blog?.created_at).format("Do MMM YYYY")}</div>
                    <div className="text-capitalize d-flex align-items-center justify-content-between gap-2 mt-1">
                        <div className="blogTitle text-capitalize">{language!==('en' || null) ? (blog[`${language}_title`]??blog?.title) : blog?.title}</div>
                        <div className="arrow">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M4.92893 12H19.0711M19.0711 12L12 4.92896M19.0711 12L12 19.0711" stroke="#101828" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        </div>
                    </div>
                    <div className="dec mt-1">
                        <div className="text-capitalize" dangerouslySetInnerHTML={{__html: language!==('en' || null) ? (blog[`${language}_description`]??blog?.description) : blog?.description}} />
                    </div>
                </div>
            </Link>
        </div>
    )
}

export default BlogsCard;
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import OwlCarousel from "react-owl-carousel3";
import { categorySlider } from "../../constants/sliders";
import Loader from "../Loader";
import lang from "../../helper/lang";
import { useAppContext } from "../../context/AppContext";
import useRequest from "../../hooks/useRequest";
import apiPath from "../../constants/apiPath";
import NoDataFound from "../../Assets/img/NotFound.png";
import { LazyLoadImage } from 'react-lazy-load-image-component';


const CategoriesSection = () => {
    const { language } = useAppContext();
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(false);
    const { request } = useRequest();

    const getCategories = () => {
        setLoading(true);
        request({
            url: apiPath.categoryList + `?page=1&pageSize=10`,
            method: "GET",
            onSuccess: (data) => {
                if (data.status) {
                    setCategories(data.data);
                }
                setLoading(false);
            },
            onError: (err) => {
                setLoading(false);
                console.log(err);
            }
        })
    }

    useEffect(() => {
        getCategories();
    }, []);

    return (
        <div className="container">
            <div className="sectionHeading">
                <div className="row align-items-center">
                    <div className="col">
                        <div className="title d-inline-block">
                            <div className="txt">{lang("Categories")}</div>
                            <div className="titleDesign d-flex align-items-center gap-2 mt-3"></div>
                        </div>
                    </div>
                    <div className="col-auto">
                        <Link to="/category" className="textBtn">
                            {lang("See All")}
                        </Link>
                    </div>
                </div>
            </div>
            <div className="categoriesOuter mt-2">
                {loading
                    ? (<Loader />)
                    : (categories.length ? <OwlCarousel {...categorySlider}>
                        {categories?.map((ctg, idx) => {
                            return (
                                <Link
                                    to={`${ctg?.type === "service"
                                        ? `/service-categories/${ctg?._id}`
                                        : `/product-listing/${ctg?._id}`
                                        }`}
                                    className="categoriesItem text-center d-block text-decoration-none"
                                >
                                    <div className="categoriesImg rounded-circle overflow-hidden mx-auto">
                                        <LazyLoadImage
                                            alt={"Image"}
                                            height={"100%"}
                                            src={ctg.image || "https://pimptup.com/Files/Products/2346images%20(6).jpg"}
                                            width={"100%"} />
                                        {/* <img
                                            alt=""
                                            className="w-100 h-100"
                                            src={
                                                ctg.image ||
                                                "https://pimptup.com/Files/Products/2346images%20(6).jpg"
                                            }
                                        /> */}
                                    </div>
                                    <div className="categoriesName mt-3 text-uppercase">{language !== ('en' || null) ? (ctg[`${language}_name`] ?? ctg?.name) : ctg?.name}</div>
                                </Link>
                            );
                        })}
                    </OwlCarousel> : <div>
                        <img width={120} src={NoDataFound} alt="" />
                        <h6 className='pt-2'>{lang("No Data Found")}</h6>
                    </div>)
                }
            </div>
        </div>
    );
};

export default CategoriesSection;
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getDatabase } from "firebase/database";
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

const config = {
    apiKey: "AIzaSyBE-ouDIonU23JZFPq-XNXYj94y69Ygc7k",
    authDomain: "pimptup-f722b.firebaseapp.com",
    databaseURL: "https://pimptup-f722b-default-rtdb.firebaseio.com",
    projectId: "pimptup-f722b",
    storageBucket: "pimptup-f722b.appspot.com",
    messagingSenderId: "915352896435",
    appId: "1:915352896435:web:540b15925d5d5de84dc1c6",
    measurementId: "G-YWEQMVW1WF"
}

const m_app = initializeApp(config);

const app = firebase.initializeApp(config);

const db = firebase.firestore();

const m_db = getFirestore(m_app);
const realDb = getDatabase(m_app);

export { db, firebase, realDb,m_db,m_app }
import React from 'react';
import { useNavigate, Navigate, useLocation } from 'react-router-dom';
import { useAppContext } from '../context/AppContext';

const PrivateRoute = ({ children }) => {
    const { setShowDialouge } = useAppContext();
    const location = useLocation();
    // const navigate = useNavigate();
    let token = localStorage.getItem("token") ? localStorage.getItem("token") : null;
    return token ? children : (<><Navigate to={`/?path=login`}/></>);
};

export default PrivateRoute;
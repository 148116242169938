import React, { useState, useContext } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import apiPath from "../../constants/apiPath";
import { AuthContext } from '../../context/AuthContext';
import useRequest from '../../hooks/useRequest';
import PassShow from '../../Assets/img/icon/passShowIcon.svg';
import PassHide from '../../Assets/img/icon/passHideIcon.svg';
import { Formik, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { ShowToast, Severty } from "../../helper/toast";
import lang from '../../helper/lang';


const ChangePasswordModal = ({ onClose }) => {
    const { request } = useRequest();
    const [show1, setShow1] = useState(false);
    const [show2, setShow2] = useState(false);
    const [show3, setShow3] = useState(false);

    const validationSchema = Yup.object().shape({
        old_password: Yup.string().required(lang('Please Enter old password')),
        new_password: Yup.string().min(6, lang('Password must be at least 6 characters long')).required(lang('Please Enter new password')),
        confirm_password: Yup.string().oneOf([Yup.ref('new_password'), null], lang('Passwords must match')).required(lang('Confirm Password is required')),
    });

    return (
        <>
            <Modal className="setPasswordModal" show={true} onHide={(e) => onClose("")} centered backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title className="fs-5 fw-bold">{lang("Change Password")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Formik
                        initialValues={{
                            old_password: '',
                            new_password: '',
                            confirm_password: '',
                        }}
                        onSubmit={async (values) => {
                            let body = {old_password : values?.old_password, new_password: values?.new_password}
                            console.log(body)
                            request({
                                url: apiPath.changePassword,
                                method: "POST",
                                data: body,
                                onSuccess: (data)=>{
                                    if(data.status){
                                        ShowToast(data.message, Severty.SUCCESS);
                                        onClose("");
                                    } else {
                                        ShowToast(data.message, Severty.ERROR);
                                    }
                                },
                                onError: (err)=>{
                                    ShowToast(err.message, Severty.ERROR);
                                    console.log(err);
                                }
                            })
                        }}
                        validationSchema={validationSchema}
                    >
                        {({ values, handleSubmit, handleChange }) => (
                            <Form className="loginSignUpForm" onSubmit={handleSubmit}>
                                <Row className="gap-3">
                                    <Form.Group as={Col} md="12">
                                        <Form.Label>{lang("Old Password")}</Form.Label>
                                        <div className="position-relative">
                                            <Form.Control
                                                type={show1 ? "text":"password"}
                                                placeholder={lang("Enter Old Password")}
                                                className=""
                                                name="old_password"
                                                value={values?.old_password}
                                                onChange={handleChange}
                                            />
                                            <button type="button" onClick={() => setShow1(x => !x)} className="btn passBtn border-0 shadow-none p-0 position-absolute d-flex align-items-center justify-content-center">
                                                <img className="passShow" src={!show1 ? PassShow : PassHide} />
                                            </button>
                                        </div>
                                        <ErrorMessage name="old_password" render={msg => <div style={{ fontSize: "14px", color: "red", marginLeft: 10 }}>{msg}</div>} />
                                    </Form.Group>
                                    <Form.Group as={Col} md="12">
                                        <Form.Label>{lang("New Password")}</Form.Label>
                                        <div className="position-relative">
                                            <Form.Control
                                                type={show2 ? "text":"password"}
                                                placeholder={lang("Enter New Password")}
                                                className=""
                                                name="new_password"
                                                value={values?.new_password}
                                                onChange={handleChange}
                                            />
                                            <button type="button" onClick={() => setShow2(x => !x)} className="btn passBtn border-0 shadow-none p-0 position-absolute d-flex align-items-center justify-content-center">
                                                <img className="passShow" src={!show2 ? PassShow : PassHide} />

                                            </button>
                                        </div>
                                        <ErrorMessage name="new_password" render={msg => <div style={{ fontSize: "14px", color: "red", marginLeft: 10 }}>{msg}</div>} />
                                    </Form.Group>
                                    <Form.Group as={Col} md="12">
                                        <Form.Label>{lang("Confirm New Password")}</Form.Label>
                                        <div className="position-relative">
                                            <Form.Control
                                                type={show3 ? "text":"password"}
                                                placeholder={lang("Enter Confirm New Password")}
                                                className=""
                                                name="confirm_password"
                                                value={values?.confirm_password}
                                                onChange={handleChange}
                                            />
                                            <button type="button" onClick={() => setShow3(x => !x)} className="btn passBtn border-0 shadow-none p-0 position-absolute d-flex align-items-center justify-content-center">
                                                <img className="passShow" src={!show3 ? PassShow : PassHide} />
                                            </button>
                                        </div>
                                        <ErrorMessage name="confirm_password" render={msg => <div style={{ fontSize: "14px", color: "red", marginLeft: 10 }}>{msg}</div>} />
                                    </Form.Group>
                                    <Form.Group as={Col} md="12" className="mt-3">
                                        <Button type="submit" className="btn btnStyle1 shadow-none text-white px-4 w-100">{lang("Save")}</Button>
                                    </Form.Group>
                                </Row>
                            </Form>
                        )}
                    </Formik>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ChangePasswordModal;
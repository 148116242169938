const fr = {
    'Home': 'Accueil',
    'Quotes': 'Citations',
    'English': 'Anglais',
    'German': 'Allemand',
    'Spanish': 'Espagnol',
    'French': 'Français',
    'Messages': 'Messages',
    'Notifications': 'Notifications',
    'Categories': 'Catégories',
    'Brands': 'Marques',
    'Products': 'Produits',
    'Specialists': 'Spécialistes',
    'See All': 'Voir Tout',
    'Dealers': 'Concessionnaires',
    'Setting': 'Paramètres',
    'Log Out': 'Déconnexion',
    'Log In': 'Se connecter',
    'Account': 'Compte',
    'My Vehicles': 'Mes véhicules',
    'Favourites': 'Favoris',
    'Blogs': 'Blogs',
    'Delete Account': 'Supprimer le compte',
    'Pimpt-Up 2023, All Rights Reserved': 'Pimpt-Up 2023, Tous droits réservés',
    'Profile': 'Profil',
    'About Us': 'À propos de nous',
    'Contact Us': 'Contactez-nous',
    'Terms & Conditions': 'Termes et conditions',
    'Privacy Policy': 'Politique de confidentialité',
    'Cancellation Policy': 'Politique d\'annulation',
    'Help Center': 'Centre d\'aide',
    'Contact': 'Contact',
    'Tel': 'Tél',
    'Mail': 'Courrier',
    'Address': 'Adresse',
    'Fax': 'Fax',
    'Full Name': 'Nom complet',
    'Email Address': 'Adresse e-mail',
    'Phone Number': 'Numéro de téléphone',
    'Enter your email': 'Entrez votre e-mail',
    'Please enter a valid email': 'Veuillez entrer une adresse e-mail valide',
    'Submit': 'Soumettre',
    'About Pimpt Up': 'À propos de Pimpt Up',
    'Date of Birth': 'Date de naissance',
    'Edit Details': 'Modifier les détails',
    'Terms & Conditions of Pimpt Up': 'Conditions générales de Pimpt Up',
    'Legal': 'Légal',
    'Policies': 'Politiques',
    'Terms': 'Termes',
    'Privacy': 'Confidentialité',
    'Security': 'Sécurité',
    'Old Password': 'Ancien mot de passe',
    'Enter Old Password': 'Entrez l\'ancien mot de passe',
    'New Password': 'Nouveau mot de passe',
    'Enter New Password': 'Entrez le nouveau mot de passe',
    'Confirm New Password': 'Confirmer le nouveau mot de passe',
    'Enter Confirm New Password': 'Entrez la confirmation du nouveau mot de passe',
    'Save': 'Enregistrer',
    'No Data Found': 'Aucune donnée trouvée',
    'No Notifications Found': 'Aucune notification trouvée',
    'Where Quality Meets Assurance': 'Là où la qualité rencontre l\'assurance',
    'Request New Quote': 'Demander un nouveau devis',
    'View More': 'Voir plus',
    'Request Quote': 'Demander un devis',
    'Select Vehicle': 'Sélectionner un véhicule',
    'Category of the Part': 'Catégorie de la pièce',
    'Request Name': 'Nom de la demande',
    'Part Number': 'Numéro de pièce',
    'Part Type': 'Type de pièce',
    'Blog': 'Blog',
    'Select Category': 'Sélectionner une catégorie',
    'Enter Request Name': 'Entrez le nom de la demande',
    'Enter Part Number': 'Entrez le numéro de pièce',
    'Login': 'Se connecter',
    'Sign Up': 'S\'inscrire',
    'Select Part Type': 'Sélectionner le type de pièce',
    'Logout': 'Se déconnecter',
    'Change Password': 'Changer le mot de passe',
    'Profile': 'Profil',
    'Description': 'Description',
    'Enter Description': 'Entrez la description',
    'Document': 'Document',
    'Browse and choose the vehicle image you want to upload from your computer': 'Parcourez et choisissez l\'image du véhicule que vous souhaitez télécharger depuis votre ordinateur',
    'Request for Quote': 'Demande de devis',
    'Page not found!': 'Page introuvable!',
    "We couldn't find the page you were looking for": 'Nous n\'avons pas pu trouver la page que vous cherchiez',
    'Back to home page': 'Retour à la page d\'accueil',
    'Value': 'Valeur',
    'Add Vehicle': 'Ajouter un véhicule',
    'Back': 'Retour',
    'Continue': 'Continuer',
    'Your Booking ID': 'Votre ID de réservation',
    'Booking successful': 'Réservation réussie',
    'Permission': 'Permission',
    'Notification': 'Notification',
    'Email Notification': 'Notification par e-mail',
    'SMS Notification': 'Notification par SMS',
    'Location': 'Emplacement',
    'No Notification Found': 'Aucune notification trouvée',
    'No Order Found': 'Aucune commande trouvée',
    'Vehicle Type': 'Type de véhicule',
    'Select Type': 'Sélectionner le type',
    'Make': 'Marque',
    'Select Make': 'Sélectionner la marque',
    'Select Model': 'Sélectionner le modèle',
    'Select Year': 'Sélectionner l\'année',
    'Model': 'Modèle',
    'Year': 'Année',
    'Only Alphabetic Characters Allowed': 'Seuls les caractères alphabétiques sont autorisés',
    'Enter Full Name': 'Entrez le nom complet',
    'Name': 'Nom',
    'Mobile number': 'Numéro de portable',
    'Please input your phone number!': 'Veuillez entrer votre numéro de téléphone!',
    'Phone number should be 7 to 15 digits': 'Le numéro de téléphone doit comporter de 7 à 15 chiffres',
    'Name should not contain more than 90 characters!': 'Le nom ne doit pas contenir plus de 90 caractères!',
    'Name should contain at least 1 character!': 'Le nom doit contenir au moins 1 caractère!',
    'Enter Your Email': 'Entrez votre adresse e-mail',
    'Edit Profile': 'Modifier le profil',
    'Cancel': 'Annuler',
    'Save': 'Enregistrer',
    'VIN Number': 'Numéro VIN',
    'Enter VIN Number': 'Entrez le numéro VIN',
    'Add New Vehicle': 'Ajouter un nouveau véhicule',
    'Manage Quotes': 'Gérer les devis',
    'Requested': 'Demandé',
    'Received': 'Reçu',
    'Fulfilled': 'Réalisé',
    'CHECK NOW': 'VÉRIFIER MAINTENANT',
    'Product detail': 'Détail du produit',
    'Product Name': 'Nom du produit',
    'Brand': 'Marque',
    'Product Dimensions': 'Dimensions du produit',
    'No Specialist Found': 'Aucun spécialiste trouvé',
    'No Dealer Found': 'Aucun distributeur trouvé',
    'Full Name': 'Nom complet',
    'Your name will appear on your ticket': 'Votre nom apparaîtra sur votre billet',
    'Mobile Number': 'Numéro de portable',
    'Search': 'Recherche',
    'Select Country': 'Sélectionner le pays',
    'NEXT': 'SUIVANT',
    'Enter Email Address': 'Entrez l\'adresse e-mail',
    'Please enter the name': 'Veuillez entrer le nom',
    'Please select country': 'Veuillez sélectionner le pays',
    'Quote ID': 'ID de devis',
    'Please Select Category': 'Veuillez sélectionner une catégorie',
    'Name is required': 'Le nom est requis',
    'Part Number is required': 'Le numéro de pièce est requis',
    'Part Type is required': 'Le type de pièce est requis',
    'Description is required': 'La description est requise',
    'Please Select an Image': 'Veuillez sélectionner une image',
    'Please select vehicle type': 'Veuillez sélectionner le type de véhicule',
    'Please select vehicle maker': 'Veuillez sélectionner la marque du véhicule',
    'Please select vehicle model': 'Veuillez sélectionner le modèle du véhicule',
    'Please select year': 'Veuillez sélectionner l\'année',
    'Online': 'En ligne',
    'Type': 'Taper',
    'Please enter VIN number': 'Veuillez entrer le numéro VIN',
    'Vehicle List': 'Liste des véhicules',
    'Mark as Read All': 'Marquer comme lu tout',
    'of': 'de',
    'No Image Found': 'Aucune image trouvée',
    'No Specialist Found': 'Aucun spécialiste trouvé',
    'No Dealer Found': 'Aucun concessionnaire trouvé',
    'Chat': 'Discussion',
    'Quote Request': 'Demande de devis',
    'Product Categories': 'Catégories de produits',
    'No Products Found': 'Aucun produit trouvé',
    'Parts': 'Pièces',
    'Add to Fav': 'Ajouter aux favoris',
    'Product Catalogue': 'Catalogue de produits',
    'Service Catalogue': 'Catalogue de services',
    'No Categories Found': 'Aucune catégorie trouvée',
    'Specialist Replies': 'Réponses du spécialiste',
    'No Reply Found': 'Aucune réponse trouvée',
    'New Price': 'Nouveau prix',
    'years': 'années',
    'Used': 'D\'occasion',
    'Second Hand': 'Deuxième main',
    'New': 'Neuf',
    'Browse and chose the part image you want to upload from your computer': `Parcourez et choisissez l\'image du Pièces que vous souhaitez télécharger depuis votre ordinateur`,
    'Service Categories': 'Catégories de services',
    'Services': 'Services',
    'Specialist Title': 'Titre du spécialiste',
    'My Profile': 'Mon profil',
    'Price': 'Prix',
    'Blog Detail': 'Détail du blog',
    'Make & Model': 'Marque et modèle',
    'Related Blogs': 'Blogs associés',
    'to': 'à',
    'Password': 'Mot de passe',
    'Confirm Password': 'Confirmer le mot de passe',
    'Accept': 'Accepter',
    'Please enter location': 'Veuillez entrer l\'emplacement',
    'Please enter name': 'Veuillez entrer le nom',
    'Please enter email': 'Veuillez entrer l\'adresse e-mail',
    'Please enter mobile number': 'Veuillez entrer le numéro de téléphone mobile',
    'Please enter minimum 7 number': 'Veuillez entrer un mot de passe',
    'Please enter password': 'Veuillez entrer un mot de passe',
    'Please enter minimum 6 character password': 'Veuillez entrer un mot de passe d\'au moins 6 caractères',
    'Please enter confirm password': 'Veuillez entrer le mot de passe de confirmation',
    'Password not match': 'Les mots de passe ne correspondent pas',
    'Please add minimum one car': 'Veuillez ajouter au moins une voiture',
    'Please Accept Terms & Conditions': 'Veuillez accepter les conditions générales',
    'Please enter valid email': 'Veuillez entrer une adresse e-mail valide',
    'Already have an account': 'Déjà un compte',
    'Don’t have an account': 'Vous n\'avez pas de compte',
    'Forgot Password': 'Mot de passe oublié',
    'Remember Password': 'Se souvenir du mot de passe',
    'Send OTP': 'Envoyer un code OTP',
    'Are you sure you want to Logout from your account': 'Êtes-vous sûr de vouloir vous déconnecter de votre compte',
    'You need to login again for continue usage of app if you logout': 'Vous devez vous reconnecter pour continuer à utiliser l\'application si vous vous déconnectez',
    'Logout Account': 'Déconnecter le compte',
    'Are you sure you want to delete your account? Please read how account deletion will affect': 'Êtes-vous sûr de vouloir supprimer votre compte ? Veuillez lire comment la suppression du compte affectera',
    'Deleting your account removes personal information our database. Tour email becomes permanently reserved and same email cannot be re-use to register a new account': 'La suppression de votre compte supprime les informations personnelles de notre base de données. Votre adresse e-mail devient définitivement réservée et la même adresse e-mail ne peut pas être réutilisée pour vous inscrire à un nouveau compte',
    'Please Enter old password': 'Veuillez entrer l\'ancien mot de passe',
    'Password must be at least 6 characters long': 'Le mot de passe doit comporter au moins 6 caractères',
    'Passwords must match': 'Les mots de passe doivent correspondre',
    'Delete All': 'Supprimer tout',
    "Enter VIN Number": "Saisissez le numéro de VIN",
    "Enter Password": "Saisissez le mot de passe",
    "Enter Name": "Saisissez le nom",
    "Enter Location": "Saisissez l'emplacement",
    "Enter Confirm Password": "Saisissez le mot de passe de confirmation",
    "Logout Successfully": "Déconnexion réussie.",
    'Vehicle Added Successfully': 'Véhicule ajouté avec succès',
    'Remember Me': 'Souviens-toi de moi',
    'OTP Verification': 'Vérification OTP',
    'Didn\'t Receive OTP': 'Je n\'ai pas reçu d\'OTP',
    'Resend': 'Renvoyer',
    'Delete': 'Supprimer',
    'Please Enter new password': 'Veuillez saisir un nouveau mot de passe',
    'Confirm Password is required': 'Confirmer que le mot de passe est requis',
    'Verify OTP': 'Vérifier OTP',
    'Both': 'Les deux',
    'Status': 'État',
    'RECEIVED': 'REÇU',
    'REQUEST': 'DEMANDE',
    'Final Price': 'Prix final',
    "FAQs": "FAQ",
    "Frequently Asked Questions": "Questions fréquemment posées",
    "Question": "Question",
    "Answer": "Réponse",
    "Quote sended with quoteId": "Devis envoyé avec quoteId",
    "Your Quote has been sended successfully": "Votre devis est envoyé avec succès",
    "Your Quote is sended successfully": "Votre devis est envoyé avec succès",
    "New Message Recieved": "Nouveau message reçu",
    "Quote sended with quoteId": "Devis envoyé avec quoteId",
    "You have send a new quote for quoteId": "Vous avez envoyé un nouveau devis pour quoteId",
    "You have sent a new quote for quoteId": "Vous avez envoyé un nouveau devis pour quoteId",
    "You have sent a quote": "Vous avez envoyé un devis",
    "You have received new quote for quoteId": "Vous avez reçu un nouveau devis pour quoteId",
    "You have received quote for quoteId": "Vous avez reçu un nouveau devis pour quoteId",
    "You have received quote from": "Vous avez reçu un devis de",
    "Welcome new member": "Bienvenue nouveau membre",
    "Welcome! We're excited to have you join us. Explore, connect, and enjoy your time here at PimptUp. If you need help, our support team is here for you. Happy exploring!": "Accueillir! Nous sommes ravis de vous compter parmi nous. Explorez, connectez-vous et profitez de votre temps ici chez PimptUp. Si vous avez besoin d'aide, notre équipe d'assistance est là pour vous. Bonne exploration !",
    "If you want to see more details, click on the notification image. It will redirect you to the Quote Details Page": "Si vous souhaitez voir plus de détails, cliquez sur l'image de notification. Il vous redirigera vers la page de détails du devis"
};

export default fr;

import React from "react";
import { Link } from "react-router-dom";
import apiPath from "../../constants/apiPath";
import Specialist1 from "../../Assets/img/specialistImg1.png";
import lang from "../../helper/lang";
import { useAppContext } from "../../context/AppContext";


const ReplyCard = ({ reply }) => {

    const {language} = useAppContext();

    return (
        <div className="notificationItem unread p-3 bg-white position-relative">
            <Link to={`/quote-detail/${reply?._id}`} className="stretched-link"></Link>
            <div className="row g-sm-3 g-2">
                <div className="col-auto">
                    <div className="imgOuter rounded-circle overflow-hidden">
                        <img alt="Specialist" className="w-100 h-100" src={reply?.vendor_id?.image ? apiPath.getImage+reply?.vendor_id?.image : Specialist1} />
                    </div>
                </div>
                <div className="col d-flex justify-content-space-between">
                    <div className="col">
                        <div className="gap-2">
                            <div className="text-capitalize title">{language!==('en' || null) ? (reply?.vendor_id[`${language}_name`]??reply?.vendor_id?.name) : reply?.vendor_id?.name}</div>
                            <div id="notification1" className="text-capitalize notificationDec me-3">
                                {language!==('en' || null) ? (reply?.quote_id[`${language}_name`]??reply?.quote_id?.name) : reply?.quote_id?.name}
                            </div>
                        </div>
                    </div>

                    <div className="col-auto">
                        <div className="notiTime">
                            <h6>{lang("Price")}</h6>
                        </div>
                        <div className="">
                            <h6>R {reply?.price}</h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ReplyCard;
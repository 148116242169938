// import { Skeleton } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import Skeleton from 'react-loading-skeleton'

const antIcon = (
    <LoadingOutlined
        style={{
            fontSize: 350,
            color: `#000000`,    
        }}
        spin
    />
);

const Loader = () => {
    return (
        <Skeleton count={5}/>

        //  <Skeleton active />
        //  <div className="spin_loader">
        //      <Spin indicator={antIcon} /> 
        //  </div>
    )
}

export default Loader
// import Starfill from '../../Assets/img/icon/starfill.png';
import Banners from "../../Components/banners/Banners";
import Brands from "../../Components/brands/Brands";
import HeroSlider from "../../Components/HeroMain/HeroSlider";
import "react-loading-skeleton/dist/skeleton.css";
import CategoriesSection from "../../Components/sections/CategoriesSection";
import ProductSection from "../../Components/sections/ProductSection";
import SpecialistSection from "../../Components/sections/SpecialistSection";
import DealerSection from "../../Components/sections/DealerSection";
import ServiceSection from "../../Components/sections/ServiceSection";
import useRequest from "../../hooks/useRequest";
import apiPath from "../../constants/apiPath";
import { Severty, ShowToast } from "../../helper/toast";
import { useEffect } from "react";
import { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import { AppStateContext } from "../../context/AppContext";
import SplashScreen from "../SplashScreen";
import { useState } from "react";


const Home = () => {

    const { request } = useRequest();
    // const [show, setShow] = useState(false);
    const { logout } = useContext(AuthContext);
    const token = localStorage.getItem('token');
    const { setShowDialouge } = useContext(AppStateContext)

    const flag = localStorage.getItem('flag');
    const [splashScreenFinished, setSplashScreenFinished] = useState(false);

    const handleSplashScreenFinish = () => {
        setSplashScreenFinished(true);
    };

    return (
        <>
            {flag === 'true' ?
                (<SplashScreen onFinish={handleSplashScreenFinish} />)
                :
                (<div className="mainWraper">
                    <HeroSlider />

                    <div className="categorySection sectionPading pt-2 pt-sm-0">
                        <CategoriesSection />
                    </div>

                    {/* <div className="brandsSection sectionPading bgLigh pt-0">
                    <Brands />
                </div> */}
                    <div>
                        <ServiceSection />
                    </div>

                    <div className="otherSliderMain sectionPading bgLight">
                        <Banners />
                    </div>

                    <div className="dealersSection sectionPading ">
                        <DealerSection />
                    </div>

                    <div className="specialistSection sectionPading">
                        <SpecialistSection />
                    </div>

                    <div className="productSection sectionPading">
                        <ProductSection />
                    </div>


                    <div className="otherSliderMain sectionPading bgLight">
                        <Banners />
                    </div>


                </div>)}
        </>
    );
};

export default Home;

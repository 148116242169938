import React, { useEffect, useState } from "react";
import useRequest from "../hooks/useRequest";
import apiPath from "../constants/apiPath";
import Header from "../Components/header/Header";
import Banners from "../Components/banners/Banners";
import Footer from "../Components/footer/footer";
import Loader from "../Components/Loader";
import ProductsCard from "../Components/products/ProductsCard";
import Dropdown from 'react-bootstrap/Dropdown';
import shortIcon from '../Assets/img/icon/shortIcon.svg';
import shortIcon1 from '../Assets/img/icon/shortIcon1.svg';
import shortingIcon from '../Assets/img/icon/shortingIcon.svg';
import lang from "../helper/lang";
import NoDataFound from "../Assets/img/NotFound.png";
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import filterIcon from '../Assets/img/icon/filterIcon.svg';
import searchIcon from '../Assets/img/icon/searchIcon.svg';
import ServicesCard from "../Components/services/ServicesCard";


const Services = () => {

    const [loading, setLoading] = useState(false);
    const [services, setServices] = useState([]);
    const [cId, setCID] = useState();
    const [categories, setCategories] = useState([]);
    const { request } = useRequest();


    useEffect(() => {
        getServices();
        getCategoryList();
    }, []);

    const getServices = () => {
        setLoading(true);
        request({
            url: apiPath.serviceList,
            method: "GET",
            onSuccess: (data) => {
                if (data.status) {
                    setServices(data.list);
                }
                setLoading(false);
            },
            onError: (err) => {
                setLoading(false);
                console.log(err);
            }
        })
    }

    const getCategoryList = () => {
        setLoading(true);
        request({
            url: apiPath.categoryList + '?type=service',
            method: "GET",
            onSuccess: (data) => {
                if (data.status) {
                    setCategories(data.data);
                }
                setLoading(false);
            },
            onError: (err) => {
                setLoading(false);
                console.log(err);
            }
        })
    }

    const handleSort = (val) => {
        setLoading(true);
        request({
            url: apiPath.serviceList + '?sort=' + val,
            method: "GET",
            onSuccess: (data) => {
                if (data.status) {
                    setServices(data.list);
                }
                setLoading(false);
            },
            onError: (err) => {
                setLoading(false);
                console.log(err);
            }
        })
    }

    const handleSearch = (val) => {
        setLoading(true);
        request({
            url: apiPath.serviceList + (val ? `?name=${val}` : ''),
            method: "GET",
            onSuccess: (data) => {
                if (data.status) {
                    setServices(data.list);
                }
                setLoading(false);
            },
            onError: (err) => {
                setLoading(false);
                console.log(err);
            }
        })
    }

    const handleFilter = (e) => {
        let ctg = e.target.value
        setLoading(true);
        request({
            url: apiPath.serviceList + (ctg ? `?category_id=${ctg}` : ''),
            method: "GET",
            onSuccess: (data) => {
                if (data.status) {
                    setServices(data.list);
                }
                setLoading(false);
            },
            onError: (err) => {
                setLoading(false);
                console.log(err);
            }
        })
    }


    return (
        <>
            <div className="mainWraper">
                <div className="otherSliderMain sectionPading bgLight">
                    <Banners />
                </div>

                <div className="categoryWraper sectionPading">
                    <div className="container">
                        <div className="sectionHeading">
                            <div className="row g-3 align-items-center">
                                <div className="col">
                                    <div className="title d-inline-block">
                                        <div className="txt">{lang("Services")}</div>
                                        <div className="titleDesign d-flex align-items-center gap-2 mt-3"></div>
                                    </div>
                                </div>
                                <div className="col-sm-auto d-flex align-items-center gap-3">
                                    <Form.Select className="text-capitalize" onChange={handleFilter} aria-label="Default select example">
                                        <option value={''}>Select Category</option>
                                        {categories?.length ? (
                                            categories?.map(ctg => <option className="text-capitalize" value={ctg?._id}>{ctg?.name}</option>)
                                        ) : null
                                        }
                                    </Form.Select>
                                    <InputGroup className="position-relative">
                                        <Form.Control type="search" id='headerSearch' onChange={(e) => handleSearch(e.target.value)} className="rounded-1 pe-5" placeholder={lang("Search")} />
                                        <label for="headerSearch" className="btn searchBtn d-flex align-items-cneter justify-content-center m-0 rounded-1 border-0 shadow-none position-absolute end-0 top-0 bottom-0 me-1 my-auto">
                                            <img className="w-100" src={searchIcon} alt="search" />
                                        </label>
                                    </InputGroup>
                                    <Dropdown className="filterDrop shortBy">
                                        <Dropdown.Toggle id="dropdown-basic" className="p0 searchBtn d-flex align-items-cneter justify-content-center border-0 shadow-none">
                                            <img className="" src={shortingIcon} alt="" style={{ filter: "invert(1)" }} />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu className="border-0 p-0">
                                            <Dropdown.Item className="border-bottom" onClick={() => handleSort('A-Z')} href="#sort=A-Z">A {lang("to")} Z</Dropdown.Item>
                                            <Dropdown.Item className="border-bottom" onClick={() => handleSort('Z-A')} href="#sort=Z-A">Z {lang("to")} A</Dropdown.Item>
                                            <Dropdown.Item className="border-bottom" onClick={() => handleSort('price_low')} href="#sort=Low-High">Price - Low to High</Dropdown.Item>
                                            <Dropdown.Item className="border-bottom" onClick={() => handleSort('price_high')} href="#sort=High-Low">Price - High to Low</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </div>
                        </div>
                        <div className="mt-3 row g-3 row-cols-xs-2 row-cols-3 row-cols-md-4 row-cols-lg-5 pt-2 row-cols-xl-5 row-cols-xxxl-8">
                            {
                                loading
                                    ? (<Loader />)
                                    : services?.length
                                        ? (services?.map((item, idx) => {
                                            return (
                                                <ServicesCard item={item} key={item?._id} />
                                            )
                                        })
                                        ) : <div>
                                            <img width={120} src={NoDataFound} alt="" />
                                            <h6 className='pt-2'>{lang("No Data Found")}</h6>
                                        </div>
                            }
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Services;
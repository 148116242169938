import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Plusicon from "../Assets/img/icon/plusIcon.svg";
import fileUploadIcon from "../Assets/img/icon/fileUploadIcon.svg";
import VehicleImg1 from "../Assets/img/VehicleImg1.png";
import plusIconTheme from "../Assets/img/icon/plusIconTheme.svg";
import useRequest from "../hooks/useRequest";
import apiPath from "../constants/apiPath";
import { Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import Banners from "../Components/banners/Banners";
import { Severty, ShowToast } from "../helper/toast";
import AddVehicleModal from "../Components/Modalss/AddVehicleModal";
import Loader from "../Components/Loader";
import { useNavigate } from "react-router";
import { brandsSlider } from "../constants/sliders";
import OwlCarousel from "react-owl-carousel3";
import lang from "../helper/lang";
import { useAppContext } from "../context/AppContext";
import NoDataFound from "../Assets/img/NotFound.png";

const Request_quote = () => {
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [show, setShow] = useState(false);
  const [image, setImage] = useState("");
  const [requestType, setRequestType] = useState();
  const [vehicles, setVehicles] = useState([]);
  const [products, setProducts] = useState([]);
  const [typeId, setTypeId] = useState();
  const [categories, setCategories] = useState([]);
  const [serviceCategories, setServiceCategory] = useState([]);
  const [variant, setVariant] = useState();
  const [ctg, setCtg] = useState();
  const [ptp, setPtp] = useState();
  const { request } = useRequest();
  const navigate = useNavigate();
  const { language } = useAppContext();

  const validationSchema = Yup.object().shape({
    category_id: Yup.string().required(lang("Please Select Category")),
    name: Yup.string().required(lang("Name is required")),
    description: Yup.string().required(lang("Description is required")),
    request_type: Yup.string().required(lang("Request type is required")),
    vehicle_id: Yup.string().required(lang("Vehicle is required")),
    // product_id: requestType !== "Service" ? Yup.string().required(lang("Product is required")) : Yup.string().nullable(),
    service_id:
      requestType === "Service"
        ? Yup.string().required(lang("Service is required"))
        : Yup.string().nullable(),
    // variant: Yup.string().required(lang("Request type is required")),
    // part_type: Yup.string().when('request_type', {
    //     is  : (val) => val === 'Product',
    //     then: Yup.string().required(lang("Part Type is required")),
    // }),
    // image        :   Yup.string().required(lang("Please Select an Image")),
    // part_number  :   Yup.string().required(lang("Part Number is required")),
  });

  const getVehicleList = () => {
    setLoading(true);
    request({
      url: apiPath.getVehicles,
      method: "GET",
      onSuccess: (data) => {
        if (data.status) {
          setVehicles(data.data);
          setLoading(false);
        }
        console.log(data);
      },
      onError: (err) => {
        console.log(err);
        setLoading(false);
      },
    });
  };

  const getProducts = (val, cg) => {
    request({
      url:
        apiPath.getSpProducts +
        `${variant}?category_id=${ctg || cg}&&prType=${val || ptp}`,
      method: "GET",
      onSuccess: (data) => {
        if (data.status) {
          setProducts(data.data);
        }
      },
      onError: (err) => {
        console.log(err);
      },
    });
  };

  const handleCategoryChange = (e) => {
    var categoryId = e.target.value;
    setCtg(categoryId);
    if (requestType == "Service") {
      request({
        url: apiPath.serviceCategory + "/" + categoryId,
        method: "GET",
        onSuccess: (data) => {
          if (data.status) {
            setServiceCategory(data.data);
          }
        },
        onError: (err) => {
          console.log(err);
        },
      });
    } else if (requestType == "Product") {
      // request({
      //     url: apiPath.productList,
      //     method: "GET",
      //     onSuccess: (data) => {
      //         if (data.status) {
      //             setProducts(data.data);
      //         }
      //     },
      //     onError: (err) => {
      //         console.log(err);
      //     },
      // });
    }
  };

  const handleImageChange1 = (event, setFiledValue) => {
    const file = event.target.files[0];
    if (!file) return;

    const data = new FormData();
    data.append("image", file);
    data.append("type", "Customer");
    setLoading2(true);
    request({
      url: apiPath.imageUpload,
      method: "POST",
      data: data,
      onSuccess: (data) => {
        if (data.status) {
          console.log(data);
          setFiledValue("image", data.data.upload);
          setImage(data.data.upload);
        }
        setLoading2(false);
      },
      onError: (err) => {
        setLoading2(false);
        ShowToast(err.message, Severty.ERROR);
      },
    });
  };

  const handleRequestTypeChange = (e, val) => {
    var type = e?.toLowerCase();
    if (type && val) {
      request({
        url: apiPath.categoryList + `?type=${type}&type_id=${val}`,
        method: "GET",
        onSuccess: (data) => {
          if (data.status) {
            setCategories(data.data);
          }
        },
        onError: (err) => {
          console.log(err);
        },
      });
    }
  };

  useEffect(() => {
    getVehicleList();
    // getCategoryList();
  }, []);

  return (
    <>
      <div className="mainWraper">
        <div className="requestQuote sectionPading">
          <div className="container">
            <div className="sectionHeading">
              <div className="row align-items-center">
                <div className="col">
                  <div className="title d-inline-block">
                    <div className="txt">{lang("Request Quote")}</div>
                    <div className="titleDesign d-flex align-items-center gap-2 mt-3"></div>
                  </div>
                </div>
              </div>
            </div>

            <div className="requestQuoteForm mt-2">
              <Formik
                initialValues={{
                  vehicle_id: "",
                  category_id: "",
                  name: "",
                  description: "",
                  part_number: "",
                  part_type: "",
                  image: "",
                  request_type: "",
                  // product_id: "",
                  service_id: "",
                }}
                onSubmit={async (values) => {
                  let body = {
                    ...values,
                    part_number: values?.part_number?.toString(),
                  };
                  // if (values?.vehicle_id == "") {
                  //     delete body.vehicle_id;
                  // }
                  if (values?.request_type == "") {
                    delete body.request_type;
                  }
                  if (values?.product_id == "") {
                    delete body.product_id;
                  }
                  if (values?.service_id == "") {
                    delete body.service_id;
                  }
                  if (values?.image == "") {
                    delete body.image;
                  }
                  if (values?.part_number == "") {
                    delete body.part_number;
                  }

                  console.log(body, "body");

                  setLoading2(true);
                  request({
                    url: apiPath.addQuote,
                    method: "POST",
                    data: body,
                    onSuccess: (data) => {
                      if (data.status) {
                        ShowToast(data.message, Severty.SUCCESS);
                        navigate("/quotes");
                      } else {
                        ShowToast(data.message, Severty.ERROR);
                      }
                      setLoading2(false);
                    },
                    onError: (err) => {
                      ShowToast(err.message, Severty.ERROR);
                      setLoading2(false);
                      console.log(err);
                    },
                  });
                }}
                validationSchema={validationSchema}
              >
                {({ values, setFieldValue, handleSubmit, handleChange }) => (
                  <Form className="addVehicleForm" onSubmit={handleSubmit}>
                    <Row className="g-4">
                      <div className="col-12 overflow-x-auto">
                        <div className="d-flex align-items-center justify-content-between">
                          <Form.Label>{lang("Select Vehicle")}</Form.Label>
                          <a
                            onClick={() => setShow(true)}
                            href="javascript:;"
                            className="textBtn textBtn d-flex align-items-center justify-content-center gap-1"
                          >
                            <img className="" src={plusIconTheme} />{" "}
                            {lang("Add Vehicle")}
                          </a>
                        </div>

                        <div className="selectVehicleOuter col-12 d-flex overflow-x-auto align-items-center gap-3 pt-2">
                          {loading ? (
                            <Loader />
                          ) : vehicles.length ? (
                            <OwlCarousel {...brandsSlider}>
                              {vehicles?.map((vehicle, idx) => (
                                <Form.Check
                                  inline
                                  label={
                                    <>
                                      <div className="vehicleOuter text-center bg-white">
                                        <div
                                          className="brandName text-uppercase"
                                          style={{ overflow: "hidden" }}
                                        >
                                          {language !== ("en" || null)
                                            ? vehicle?.make_id[
                                                `${language}_name`
                                              ] ?? vehicle?.make_id?.name
                                            : vehicle?.make_id?.name}
                                        </div>
                                        <div className="text-capitalize vehicleModal mt-1 pb-1">
                                          {language !== ("en" || null)
                                            ? vehicle?.model_id[
                                                `${language}_name`
                                              ] ?? vehicle?.model_id?.name
                                            : vehicle?.model_id?.name}
                                        </div>
                                        <div className="vehicleImg my-2">
                                          <img
                                            className=""
                                            src={
                                              vehicle?.image
                                                ? apiPath.getImage +
                                                  vehicle.image
                                                : VehicleImg1
                                            }
                                            alt=""
                                          />
                                        </div>
                                        <div className="vehiclenumber pt-1">
                                          {vehicle?.year} Model
                                        </div>
                                        {/* <div className="vehiclenumber text-uppercase pt-1">
                                                {vehicle?.vin_number}
                                            </div> */}
                                      </div>
                                    </>
                                  }
                                  onChange={() => {
                                    setFieldValue("vehicle_id", vehicle?._id);
                                    setTypeId(vehicle?.type_id?._id);
                                    setVariant(vehicle?.variant_id?._id);
                                    handleRequestTypeChange(
                                      requestType,
                                      vehicle?.type_id?._id
                                    );
                                  }}
                                  className="selectVehicleCheck"
                                  name="vehicle_id"
                                  type={"radio"}
                                  id={`selectVehicle - ${idx} - 1`}
                                />
                              ))}
                            </OwlCarousel>
                          ) : (
                            <div>
                              <img width={120} src={NoDataFound} alt="" />
                              <h6 className="pt-2">{lang("No Data Found")}</h6>
                            </div>
                          )}
                        </div>

                        <ErrorMessage
                          name="vehicle_id"
                          render={(msg) => (
                            <div
                              style={{
                                fontSize: "14px",
                                color: "red",
                                marginLeft: 10,
                              }}
                            >
                              {msg}
                            </div>
                          )}
                        />
                      </div>

                      <Form.Group as={Col} md="6" xl="3">
                        <Form.Label>Request Type</Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          name="request_type"
                          value={values?.request_type}
                          onChange={(e) => {
                            setRequestType(e.target.value);
                            handleRequestTypeChange(e.target.value, typeId);
                            handleChange(e);
                          }}
                        >
                          <option value="">
                            {lang("Select Request Type")}
                          </option>
                          <option value="Product">{lang("Product")}</option>
                          <option value="Service">{lang("Service")}</option>
                        </Form.Select>
                        <ErrorMessage
                          name="request_type"
                          render={(msg) => (
                            <div
                              style={{
                                fontSize: "14px",
                                color: "red",
                                marginLeft: 10,
                              }}
                            >
                              {msg}
                            </div>
                          )}
                        />
                      </Form.Group>

                      <Form.Group as={Col} md="6" xl="3">
                        <Form.Label>{lang("Category of the Part")}</Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          name="category_id"
                          value={values?.category_id}
                          onChange={(e) => {
                            handleCategoryChange(e);
                            getProducts(ptp, e.target.value);
                            handleChange(e);
                          }}
                        >
                          <option>{lang("Select Category")}</option>
                          {categories?.length &&
                            categories?.map((ctg) => {
                              return (
                                <option
                                  className="text-capitalize "
                                  value={ctg?._id}
                                >
                                  {language !== ("en" || null)
                                    ? ctg[`${language}_name`] ?? ctg?.name
                                    : ctg?.name}
                                </option>
                              );
                            })}
                        </Form.Select>
                        <ErrorMessage
                          name="category_id"
                          render={(msg) => (
                            <div
                              style={{
                                fontSize: "14px",
                                color: "red",
                                marginLeft: 10,
                              }}
                            >
                              {msg}
                            </div>
                          )}
                        />
                      </Form.Group>

                      {values && values.request_type === "Product" && (
                        <Form.Group as={Col} md="6" xl="3">
                          <Form.Label>Part Type</Form.Label>
                          <Form.Select
                            aria-label="Default select example"
                            name="part_type"
                            value={values?.part_type}
                            onChange={(e) => {
                              handleChange(e);
                              getProducts(e.target.value, ctg);
                            }}
                          >
                            <option value="">{lang("Select Part Type")}</option>
                            <option value="new">{lang("New")}</option>
                            <option value="used">{lang("Used")}</option>
                            <option value="both">{lang("Both")}</option>
                          </Form.Select>

                          <ErrorMessage
                            name="part_type"
                            render={(msg) => (
                              <div
                                style={{
                                  fontSize: "14px",
                                  color: "red",
                                  marginLeft: 10,
                                }}
                              >
                                {msg}
                              </div>
                            )}
                          />
                        </Form.Group>
                      )}

                      {values && values.request_type === "Service" ? (
                        // <Form.Group as={Col} md="6" xl="3">
                        //     <Form.Label>Select Product</Form.Label>
                        //     <Form.Select
                        //         aria-label="Default select example"
                        //         name="product_id"
                        //         value={values?.product_id}
                        //         onChange={handleChange}
                        //     >
                        //         <option>{lang("Select Product")}</option>
                        //         {products?.length ?
                        //             products?.map((product) => {
                        //                 return (
                        //                     <option className="text-capitalize " value={product?.product_id?._id}>{language !== ('en' || null) ? (product[`${language}_name`] ?? product?.name) : product?.product_id?.name} ({product?.value?.map(val => val?.value + ", ")})</option>
                        //                 );
                        //             }) : <option disabled>No product in this category</option>
                        //         }

                        //     </Form.Select>
                        //     <ErrorMessage
                        //         name="product_id"
                        //         render={(msg) => (
                        //             <div
                        //                 style={{
                        //                     fontSize: "14px",
                        //                     color: "red",
                        //                     marginLeft: 10,
                        //                 }}
                        //             >
                        //                 {msg}
                        //             </div>
                        //         )}
                        //     />
                        // </Form.Group>
                        // ) :

                        <Form.Group as={Col} md="6" xl="3">
                          <Form.Label>Select Service</Form.Label>

                          <Form.Select
                            aria-label="Default select example"
                            name="service_id"
                            value={values?.service_id}
                            onChange={handleChange}
                          >
                            <option>{lang("Select Service")}</option>
                            {serviceCategories?.length &&
                              serviceCategories?.map((sctg) => {
                                return (
                                  <option
                                    className="text-capitalize "
                                    value={sctg?._id}
                                  >
                                    {language !== ("en" || null)
                                      ? sctg[`${language}_name`] ?? sctg?.name
                                      : sctg?.name}
                                  </option>
                                );
                              })}
                          </Form.Select>

                          <ErrorMessage
                            name="service_id"
                            render={(msg) => (
                              <div
                                style={{
                                  fontSize: "14px",
                                  color: "red",
                                  marginLeft: 10,
                                }}
                              >
                                {msg}
                              </div>
                            )}
                          />
                        </Form.Group>
                      ) : null}

                      <Form.Group as={Col} md="6" xl="3">
                        <Form.Label>{lang("Request Name")}</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder={lang("Enter Request Name")}
                          className=""
                          name="name"
                          onChange={handleChange}
                          value={values?.name}
                        />
                        <ErrorMessage
                          name="name"
                          render={(msg) => (
                            <div
                              style={{
                                fontSize: "14px",
                                color: "red",
                                marginLeft: 10,
                              }}
                            >
                              {msg}
                            </div>
                          )}
                        />
                      </Form.Group>

                      {values && values.request_type === "Product" && (
                        <Form.Group as={Col} md="6" xl="3">
                          <Form.Label>{lang("Part Number")}</Form.Label>
                          <Form.Control
                            type="number"
                            placeholder={lang("Enter Part Number")}
                            className=""
                            name="part_number"
                            onChange={handleChange}
                          />
                          <ErrorMessage
                            name="part_number"
                            render={(msg) => (
                              <div
                                style={{
                                  fontSize: "14px",
                                  color: "red",
                                  marginLeft: 10,
                                }}
                              >
                                {msg}
                              </div>
                            )}
                          />
                        </Form.Group>
                      )}

                      <Form.Group as={Row} className="g-3">
                        <Form.Group as={Col} md="6" xl="8" xxl="9">
                          <Form.Label>{lang("Description")}</Form.Label>
                          <Form.Control
                            as="textarea"
                            placeholder={lang("Enter Description") + "..."}
                            className="text-capitalize"
                            style={{ height: "173px" }}
                            name="description"
                            onChange={handleChange}
                          />
                          <ErrorMessage
                            name="description"
                            render={(msg) => (
                              <div
                                style={{
                                  fontSize: "14px",
                                  color: "red",
                                  marginLeft: 10,
                                }}
                              >
                                {msg}
                              </div>
                            )}
                          />
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          md="6"
                          xl="4"
                          xxl="3"
                          controlId="formFile1"
                        >
                          <Form.Label>{lang("Document")}</Form.Label>
                          <Form.Control
                            type="file"
                            className="d-none"
                            name="image"
                            onChange={(e) =>
                              handleImageChange1(e, setFieldValue)
                            }
                          />
                          <Form.Label className="vehicleImgUploadLabel p-3 w-100">
                            {loading2 ? (
                              <Loader />
                            ) : !image ? (
                              <>
                                <div className="imgOuter text-center">
                                  <img alt="" src={fileUploadIcon} />
                                </div>
                                <div className="pera fs-16 mt-1 mb-2 text-center">
                                  {lang(
                                    "Browse and chose the vehicle image you want to upload from your computer"
                                  )}
                                </div>
                                <p className="btn btnStyle1 iconBtn mx-auto d-flex align-items-center justify-content-center border-0 shadow-none text-white">
                                  <img alt="" className="" src={Plusicon} />
                                </p>
                              </>
                            ) : (
                              <img
                                alt=""
                                src={apiPath.getImage + image}
                                width={"95%"}
                              />
                            )}
                          </Form.Label>
                          <ErrorMessage
                            name="image"
                            render={(msg) => (
                              <div
                                style={{
                                  fontSize: "14px",
                                  color: "red",
                                  marginLeft: 10,
                                }}
                              >
                                {msg}
                              </div>
                            )}
                          />
                        </Form.Group>
                      </Form.Group>

                      <Form.Group as={Col} md="12" className="mt-3 text-end">
                        <Button
                          disabled={loading2}
                          type="submit"
                          className="btn btnStyle1 shadow-none text-white px-4 w-auto"
                        >
                          {lang("Request for Quote")}
                        </Button>
                      </Form.Group>
                    </Row>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>

        <div className="otherSliderMain sectionPading bgLight">
          <Banners />
        </div>
      </div>

      {show && (
        <AddVehicleModal
          onClose={() => setShow(false)}
          flag={"add"}
          setRefresh={getVehicleList}
        />
      )}
    </>
  );
};

export default Request_quote;

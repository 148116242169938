let appMode = process.env.REACT_APP_ENV;
let ASSET_URL = "https://pimpt-up.s3.eu-north-1.amazonaws.com/";
let URL;

console.log("appMode", appMode);

// 3.20.147.34

if (appMode === "development") {
  URL = "https://pimptup.com/api/";
} else {
  // URL = 'http://localhost:8585/api/'
  URL = "https://pimptup.com/api/";
}

let apiPath = {
  baseURL: URL,
  assetURL: ASSET_URL,
  dashboard: "app/auth/dashboard",

  listOrder: "app/order/list",
  viewOrder: "app/order/view",
  statusOrder: "app/order/status",

  listTransaction: "app/transaction/list",
  viewTransaction: "app/transaction/view",

  // Auth API
  logout: "app/auth/logout",
  login: "app/auth/login",
  signUp: "app/auth/sign-up",
  deleteAccount: "app/auth/delete-account",

  profile: "app/auth/get-profile",
  updateProfile: "app/auth/update-profile",
  changePassword: "app/auth/change-password",
  updateAppSetting: "app/auth/update-app-setting",

  forgotPassword: "app/auth/forgot-password",
  sentOTP: "app/auth//send-otp",
  verifyOTP: "app/auth/verify-otp",
  resetPassword: "app/auth/reset-password",
  sendUpdateOtp: "app/auth/send-update-otp",
  verifyUpdateOtp: "app/auth/verify-update-otp",

  // Product APIs
  productDetail: "common/db/product/",

  // User APIs
  userDetail: "common/db/user/",

  // Wishlist Routes
  getWishList: "app/customer/wishlist/list",
  addWishList: "app/customer/wishlist/add",

  // Specialists Routes
  specialistServices: (id) => `common/db/${id}/specialist/services`,
  specialistProducts: (id) => `common/db/${id}/specialist/products`,

  // Notification Routes
  getNotifications: "app/notification/list",
  getNotificationCount: "app/notification/count",
  markAllRead: "app/notification/read",
  markAllDelete: "app/notification/delete",
  singleDelete: "app/notification/delete/",
  singleRead: "app/notification/read/",

  // Blogs Routes
  getBlogList: "common/blog",
  blogDetail: "common/blog-detail/",

  // Quotes Routes
  getQuoteList: "app/customer/quote/list/",
  addQuote: "app/customer/quote/add",
  fulfillQuote: "app/customer/quote/fulfill",
  getReplyList: (id) => `app/customer/quote/list/received/${id}`,
  getQuoteDetails: (id) => `app/customer/quote/list/received/${id}/detail`,
  getQuoteDetailsPage: (id) => `app/customer/quote/quote-details-page/${id}`,

  // Vehiclies Routes
  getVehicles: "app/customer/car/list",
  addVehicle: "app/customer/car/add-edit",

  // Common Routes
  getImage: "https://pimpt-up.s3.eu-north-1.amazonaws.com/",
  imageUpload: "common/image-upload",
  featuredProducts: "common/db/product/feature",
  featuredServices: "common/service-category/:category_id?",
  serviceList: "common/db/services",
  getCategoryItem: "common/db/category-item",
  getAppSetting: "common/app-setting",
  getContentList: "common/content/",
  carMakeList: "common/make",
  serviceCategory: "common/service-category",
  getSpProducts: "common/db/vendor-products/",
  carTypeList: "common/type",
  carModelList: "common/model",
  carVariantList: "common/variant",
  brandList: "common/brand",
  bannerList: "common/banner",
  productList: "common/product",
  details: "common/db/",
  brancdProductList: "common/db/brand",
  allCategories: "common/category",
  categoryList: "common/db/category",
  allSpecialists: "common/db/specialist",
  allDealers: "common/db/dealer",
  globalSearch: "common/db/global-search",
  clickCounter: "common/db/click-counter/",
};
export default apiPath;

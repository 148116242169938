import React, { useContext, useState, useEffect } from "react";
import useRequest from "../../hooks/useRequest";
import apiPath from "../../constants/apiPath";
import partImg1 from "../../Assets/img/partImg1.png";
import { Link } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { AppStateContext } from "../../context/AppContext";
import { LazyLoadImage } from 'react-lazy-load-image-component';


const ServicesCard = ({ item, favourite, setRefresh }) => {
  const { request } = useRequest();
  // const [fav, setFav] = useState(favourite || item?.is_wishlist);
  const { setShowDialouge, language } = useContext(AppStateContext);
  const { isLoggedIn } = useContext(AuthContext);

  // const addFavourite = (id) => {
  //     isLoggedIn
  //     ? request({
  //         url: apiPath.addWishList,
  //         method: "POST",
  //         data: { service_id: id, type: "Service" },
  //         onSuccess: (data) => {
  //             if (data.status) {
  //                 // setFav(x => !x);
  //                 console.log(fav);

  //                 if (setRefresh) {
  //                     setRefresh();
  //                 }
  //             }
  //         },
  //         onError: (err) => {
  //             console.log(err);
  //         }
  //     })
  //     : (setShowDialouge({type: 'login'}))
  // }

  return (
    <div className="col">
      <div className="partItem position-relative overflow-hidden">
        <Link
          to={`/service-detail/${item?._id}`}
          className="stretched-link"
        ></Link>
        <div className="imgOuter">
          <LazyLoadImage
            alt={"Image"}
            height={"100%"}
            src={item?.image || partImg1}
            width={"100%"} />
          {/* <img className="w-100 h-100" src={item?.image || partImg1} alt="" /> */}
        </div>
        
        {/* <div onClick={() => { addFavourite(item?._id) }} className={`favbtn ${fav && "favouriteItem"} position-absolute top-0 end-0 mt-2 me-2 z-3`}>
              <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M10.517 17.836C10.2337 17.936 9.76699 17.936 9.48366 17.836C7.06699 17.011 1.66699 13.5693 1.66699 7.73597C1.66699 5.16097 3.74199 3.07764 6.30033 3.07764C7.81699 3.07764 9.15866 3.81097 10.0003 4.9443C10.842 3.81097 12.192 3.07764 13.7003 3.07764C16.2587 3.07764 18.3337 5.16097 18.3337 7.73597C18.3337 13.5693 12.9337 17.011 10.517 17.836Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
          </div> */}
        <div className="contentPart position-absolute bottom-0 start-0 end-0 pt-4">
          <div className="partTitle">
            {language !== ("en" || null)
              ? item[`${language}_name`] ?? item?.name
              : item?.name}
          </div>
          {/* <div className="partPrice">R {item?.price}</div> */}
        </div>
      </div>
    </div>
  );
};

export default ServicesCard;

import { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import plusIconDark from '../../Assets/img/icon/plusIconDark.svg';
import carDarkIcon from '../../Assets/img/icon/carDarkIcon.svg';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import PassShow from '../../Assets/img/icon/passShowIcon.svg';
import PassHide from '../../Assets/img/icon/passHideIcon.svg';
import useRequest from "../../hooks/useRequest";
import apiPath from "../../constants/apiPath";
import AddVehicleModal from "./AddVehicleModal";
import { ShowToast, Severty } from "../../helper/toast";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import lang from '../../helper/lang';
import ViewVehicleModal from './ViewVehicleModal';
import { Spinner } from 'react-bootstrap';
import LocationMap from '../LocationMap';


const SignUp = ({ onClose }) => {

    const [showPassword, setShowPassword] = useState(false);
    const [showCPassword, setShowCPassword] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState("");
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const [view, setView] = useState(false);
    const { request } = useRequest();
    const navigate = useNavigate();
    const [userAddress, setUserAddress] = useState(null);
    const [latLong, setlatLong] = useState({ lat: 26.8289443, lng: 75.8056178 });
    const [errors, setErrors] = useState({});
    const [acceptTnC, setAcceptTnC] = useState(false);

    const [formData, setFormData] = useState({
        name: "",
        email: "",
        country_code: "27",
        mobile_number: "",
        password: "",
        type: "Customer",
        location: "",
        latitude: "",
        longitude: "",
        user_car: []
    });


    const isValidEmail = (email) => {
        const re =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    const handleSubmit = async (e) => {
        e.preventDefault()

        const newError = {}

        const { name, email, password, country_code, mobile_number, type, location, user_car } = formData;
        if (name == "" || name == null || name == undefined) {
            newError.name = lang("Please enter name");
        }
        if (email == "" || email == null || email == undefined) {
            newError.email = lang("Please enter email");
        }
        if (!isValidEmail(email)) {
            newError.email = lang("Please enter valid email");
        }
        if (mobile_number == "" || mobile_number == null || mobile_number == undefined) {
            newError.mobile_number = lang("Please enter mobile number");
        }
        if (mobile_number.length < 7) {
            newError.mobile_number = lang("Please enter minimum 7 number");
        }
        if (password == "") {
            newError.password = lang("Please enter password");
        }
        if (password.length < 6) {
            newError.password = lang("Please enter minimum 6 character password");
        }
        if (confirmPassword == "") {
            newError.confirmPassword = lang("Please enter confirm password");
        }
        if (password != confirmPassword) {
            newError.confirmPassword = lang("Password not match");
        }
        if (location == "" || location == null || location == undefined) {
            newError.location = lang("Please enter location");
        }
        if (!user_car?.length > 0) {
            newError.user_car = lang("Please add minimum one car");
        }
        if (!acceptTnC) {
            newError.tnc = lang("Please Accept Terms & Conditions");
        }

        setErrors(newError);

        if (Object.keys(newError).length === 0) {
            setLoading(true);
            request({
                url: apiPath.signUp,
                method: "POST",
                data: formData,
                onSuccess: (data) => {
                    if (data.status) {
                        // navigate("/");
                        ShowToast(data.message, Severty.SUCCESS);
                        onClose("otp", formData);
                    }
                    else {
                        ShowToast(data.message, Severty.ERROR);
                    }
                    setLoading(false);
                },
                onError: (err) => {
                    ShowToast(err, Severty.ERROR);
                    setLoading(false);
                    console.log(err);
                },
                header: { 'Content-Type': 'application/json' }
            })
        }
    }

    const handleChange = (e) => {
        setFormData(prev => {
            return {
                ...prev,
                [e.target.name]: e.target.value
            }
        });
    }

    const handleOnChange = (value, data) => {
        setFormData(prev => {
            return {
                ...prev,
                mobile_number: value.slice(data.dialCode.length),
                country_code: data.dialCode
            }
        })
    }

    const handleLocationChange = (value) => {
        setUserAddress(value.address);
        setlatLong({ lat: parseFloat(value.latitude), lng: parseFloat(value.longitude) });
        setFormData(prev => {
            return {
                ...prev,
                location: value.address,
                latitude: parseFloat(value.latitude),
                longitude: parseFloat(value.longitude),
            }
        });
    }

    useEffect(() => {
        if (formData.name) setErrors(prev => { return { ...prev, name: "" } })
        if (formData.email) setErrors(prev => { return { ...prev, email: "" } })
        if (formData.mobile_number) setErrors(prev => { return { ...prev, mobile_number: "" } })
        if (formData.password) setErrors(prev => { return { ...prev, password: "" } })
        if (confirmPassword) setErrors(prev => { return { ...prev, confirmPassword: "" } })
        if (formData.country_code) setErrors(prev => { return { ...prev, country_code: "" } })
        if (formData.user_car.length) setErrors(prev => { return { ...prev, user_car: "" } })
        if (formData.location) setErrors(prev => { return { ...prev, location: "" } })
        if (acceptTnC) setErrors(prev => { return { ...prev, tnc: "" } })
    }, [formData, confirmPassword, acceptTnC])

    return (
        <div>
            <Modal className={`signUpModal ${(view || show) ? 'd-none' : ''}`} show={true} onHide={(e) => onClose("")} centered backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title className="fs-5 fw-bold">{lang("Sign Up")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form className="loginSignUpForm" onSubmit={handleSubmit}>
                        <Row className="gap-3">
                            <Form.Group as={Col} md="12">
                                <Form.Label>{lang("Full Name")}</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder={lang("Enter Name")}
                                    className=""
                                    name="name"
                                    onChange={handleChange}

                                />
                            </Form.Group>
                            {errors.name && <div style={{ fontSize: "13px", color: "red", marginTop: -12, marginLeft: 10 }}>{errors.name}</div>}

                            <Form.Group as={Col} md="12">
                                <Form.Label>{lang("Email Address")}</Form.Label>
                                <Form.Control
                                    type="email"
                                    placeholder={lang("Enter Email Address")}
                                    className=""
                                    name="email"
                                    onChange={handleChange}

                                />
                            </Form.Group>
                            {errors.email && <div style={{ fontSize: "13px", color: "red", marginTop: -12, marginLeft: 10 }}>{errors.email}</div>}

                            <Form.Group as={Col} md="12">
                                <Form.Label>{lang("Mobile Number")}</Form.Label>
                                <div className="input-group" >
                                    <PhoneInput
                                        inputProps={((type) => {
                                            return {
                                                name: 'phone',
                                                autoFocus: true
                                            }
                                        })()}
                                        country={"za"}
                                        onChange={handleOnChange}
                                    />
                                </div>
                            </Form.Group>
                            {errors.mobile_number && <div style={{ fontSize: "13px", color: "red", marginTop: -12, marginLeft: 10 }}>{errors.mobile_number}</div>}

                            <Form.Group as={Col} md="12">
                                <Form.Label>{lang("Password")}</Form.Label>
                                <div className="position-relative">
                                    <Form.Control
                                        type={`${showPassword ? "text" : "password"}`}
                                        placeholder={lang("Enter Password")}
                                        className=""
                                        name="password"
                                        onChange={handleChange}

                                    />
                                    <button type="button" onClick={() => setShowPassword(x => !x)} className="btn passBtn border-0 shadow-none p-0 position-absolute d-flex align-items-cneter justify-content-center">
                                        <img className="passShow" src={showPassword ? PassHide : PassShow} />
                                    </button>
                                </div>
                            </Form.Group>
                            {errors.password && <div style={{ fontSize: "13px", color: "red", marginTop: -12, marginLeft: 10 }}>{errors.password}</div>}

                            <Form.Group as={Col} md="12">
                                <Form.Label>{lang("Confirm Password")}</Form.Label>
                                <div className="position-relative">
                                    <Form.Control
                                        type={`${showCPassword ? "text" : "password"}`}
                                        placeholder={lang("Enter Confirm Password")}
                                        className=""
                                        name="confirm_password"
                                        onChange={(e) => setConfirmPassword(e.target.value)}

                                    />
                                    <button type="button" onClick={() => setShowCPassword(x => !x)} className="btn passBtn border-0 shadow-none p-0 position-absolute d-flex align-items-cneter justify-content-center">
                                        <img className="passShow" src={showCPassword ? PassHide : PassShow} />
                                    </button>
                                </div>
                            </Form.Group>
                            {errors.confirmPassword && <div style={{ fontSize: "13px", color: "red", marginTop: -12, marginLeft: 10 }}>{errors.confirmPassword}</div>}

                            <Form.Group as={Col} md="12">
                                <div className="position-relative m-0">
                                    <Form.Label>Location</Form.Label>
                                    <LocationMap onChange={handleLocationChange} user />
                                </div>
                                {errors.location && <div style={{ fontSize: "13px", color: "red", marginTop: -12, marginLeft: 10 }}>{errors.location}</div>}
                            </Form.Group>


                            <Form.Group as={Col} md="12" className="d-flex align-items-center justify-content-between">
                                <a href="javascript:;" onClick={() => setShow(true)} className="textBtn textBtnDark d-flex align-items-center justify-content-center gap-1"><img className="" src={plusIconDark} /> {lang("Add Vehicle")}</a>

                                <a href="javascript:;" onClick={() => { formData?.user_car?.length && setView(true) }} className="textBtn textBtnDark d-flex align-items-center justify-content-center gap-1"><img className="" src={carDarkIcon} /> {formData.user_car.length}  {lang("Vehicles")}</a>
                            </Form.Group>
                            {errors.user_car && <div style={{ fontSize: "13px", color: "red", marginTop: -12, marginLeft: 10 }}>{errors.user_car}</div>}

                            <Form.Group as={Col} md="12">
                                <div key={`default-checkbox`} className="mb-3 d-flex gap-2">
                                    <Form.Check
                                        type={'checkbox'}
                                        className="p-0 m-0 d-flex align-items-center gap-2"
                                        id={`default-checkbox`}
                                        onChange={() => setAcceptTnC(x => !x)}
                                    />
                                    <label>{lang("Accept")} <Link to="/term-condition" target='_blank'>{lang("Terms & Conditions")}</Link></label>
                                </div>
                                {errors?.tnc && <div style={{ fontSize: "13px", color: "red", marginTop: -12, marginLeft: 10 }}>{errors.tnc}</div>}
                            </Form.Group>

                            <Form.Group as={Col} md="12" className="mt-3">
                                <Button type="submit" className="btn btnStyle1 shadow-none text-white px-4 w-100">{loading ? <Spinner animation="border" size="sm" /> : lang("Sign Up")}</Button>
                            </Form.Group>
                            <Form.Group as={Col} md="12" className="mb-2">
                                <div className="otherLink text-center">{lang("Already have an account")}? <a href="javascript:;" onClick={() => onClose("login")} className="textBtn">{lang("Login")}</a></div>
                            </Form.Group>
                        </Row>
                    </Form>
                </Modal.Body>
            </Modal>

            {show && (
                <AddVehicleModal onClose={() => setShow(false)} setFormData={setFormData} />
            )}
            {formData?.user_car.length && view && (
                <ViewVehicleModal onClose={() => setView(false)} setFormData={setFormData} car={formData?.user_car[0]} />
            )}

        </div>
    );
}

export default SignUp;
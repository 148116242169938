import React, { useState, useEffect } from "react";
import OwlCarousel from 'react-owl-carousel3';
import Dropdown from 'react-bootstrap/Dropdown';
import Category1 from '../Assets/img/category1.png';
import shortingIcon from '../Assets/img/icon/shortingIcon.svg';
import useRequest from "../hooks/useRequest";
import apiPath from "../constants/apiPath";
import { Link, useParams } from "react-router-dom";
import Banners from "../Components/banners/Banners";
import { categorySlider } from "../constants/sliders"
import Loader from "../Components/Loader";
import ProductsCard from "../Components/products/ProductsCard";
import lang from "../helper/lang";
import { useAppContext } from "../context/AppContext";
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import searchIcon from '../Assets/img/icon/searchIcon.svg';
import filterIcon from '../Assets/img/icon/filterIcon.svg';
import NoDataFound from "../Assets/img/NotFound.png";
import { get } from "firebase/database";


const Product_listing = () => {
    const { request } = useRequest();
    const [loading, setLoading] = useState(false);
    const [categories, setCategories] = useState([]);
    const [selectedCtg, setSelectedCtg] = useState({});
    const [products, setProducts] = useState([]);
    const { id } = useParams();
    const { language } = useAppContext();
    const [model, setModel] = useState([]);
    const [brands, setBrands] = useState([]);
    const [make, setMake] = useState([]);
    const [bId, setBID] = useState();
    const [cId, setCID] = useState();
    const [mkId, setMKID] = useState();
    const [mdId, setMDID] = useState();
    const [pn, setPN] = useState();
    const [show, setShow] = useState(false);

    const getProducts = (id) => {
        setLoading(true);
        request({
            url: apiPath.getCategoryItem + "/" + id,
            method: "GET",
            onSuccess: (data) => {
                if (data.status) {
                    setProducts(data.data);
                    setLoading(false);
                } else {
                    console.log("something went wrong!")
                    setLoading(false);
                }
            },
            onError: (err) => {
                setLoading(false);
            }
        });
        const sc = categories?.filter((elem) => {
            return id === elem?._id;
        });
        setSelectedCtg(sc[0]);
    }

    const getCategoryList = () => {
        request({
            url: apiPath.categoryList + `?type=product`,
            method: "GET",
            onSuccess: (data) => {
                if (data.status) {
                    setCategories(data.data);
                    getProducts(id);
                    const sc = data.data?.filter((elem, idx) => {
                        return id === elem?._id;
                    });
                    setSelectedCtg(sc[0]);
                }
            },
            onError: (err) => {
                console.log(err);
            }
        })
    }

    const getBrands = (id) => {
        request({
            url: apiPath.brandList,
            method: "GET",
            onSuccess: (data) => {
                if (data.status) {
                    setBrands(data.data);
                }
            },
            onError: (err) => {
                console.log(err);
            }
        })
    }

    const getModel = (id) => {
        request({
            url: apiPath.carModelList + `/${id}`,
            method: "GET",
            onSuccess: (data) => {
                if (data.status) {
                    setModel(data.data);
                }
            },
            onError: (err) => {
                console.log(err);
            }
        })
    }

    const getMake = (val) => {
        request({
            url: apiPath.carMakeList,
            method: "GET",
            onSuccess: (data) => {
                if (data.status) {
                    setMake(data.data);
                }
            },
            onError: (err) => {
                console.log(err);
            }
        })
    }

    const handleSort = (val) => {
        setLoading(true);
        request({
            url: apiPath.getCategoryItem + "/" + id + `?sort=${val}`,
            method: "GET",
            onSuccess: (data) => {
                if (data.status) {
                    console.log("products", data);
                    setProducts(data.data);
                    setLoading(false);
                } else {
                    console.log("something went wrong!")
                    setLoading(false);
                }
            },
            onError: (err) => {
                setLoading(false);
            }
        });
    }

    const handleSearch = (val) => {
        setLoading(true);
        request({
            url: apiPath.getCategoryItem + "/" + id + `?name=${val}`,
            method: "GET",
            onSuccess: (data) => {
                if (data.status) {
                    setProducts(data.data);
                    setLoading(false);
                } else {
                    console.log("something went wrong!")
                    setLoading(false);
                }
            },
            onError: (err) => {
                setLoading(false);
            }
        });
    }

    const handleApply = () => {
        setLoading(true);
        let urlArray = [];
        if (bId) {
            urlArray.push({ param: "brand", value: bId })
        }
        if (cId) {
            urlArray.push({ param: "category", value: cId })
        }
        if (mkId) {
            urlArray.push({ param: "make", value: mkId })
        }
        if (mdId) {
            urlArray.push({ param: "model", value: mdId })
        }
        if (pn) {
            urlArray.push({ param: "part_num", value: pn })
        }

        let queryParams = apiPath.getCategoryItem + "/" + id;
        urlArray.length && urlArray?.forEach((elem, idx) => {
            console.log("elem", elem);
            if (idx === 0) {
                queryParams += `?${elem.param}=${elem.value}`
            } else {
                queryParams += `&&${elem.param}=${elem.value}`

            }
        })
        console.log('queryParams', queryParams);
        setShow(x => !x);
        request({
            url: queryParams,
            method: "GET",
            onSuccess: (data) => {
                if (data.status) {
                    setProducts(data.data);
                }
                setLoading(false);
            },
            onError: (err) => {
                setLoading(false);
                console.log(err);
            }
        })
    }

    useEffect(() => {
        getCategoryList();
        getMake();
        getBrands();
    }, []);

    useEffect(() => {
        mkId && getModel(mkId);
    }, [mkId])

    return (
        <>
            <div className="mainWraper">
                {categories.length ? (<div className="categorySection sectionPading">
                    <div className="container">
                        <div className="sectionHeading">
                            <div className="row align-items-center">
                                <div className="col">
                                    <div className="title d-inline-block">
                                        <div className="txt">{lang("Product Categories")} <span className="ms-2 me-2">-</span> <span className="selectedCategory text-capitalize">{language !== ('en' || null) ? (selectedCtg[`${language}_name`] ?? selectedCtg?.name) : selectedCtg?.name}</span></div>
                                        <div className="titleDesign d-flex align-items-center gap-2 mt-3"></div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="categoriesOuter mt-2">
                            <OwlCarousel {...categorySlider} >
                                {categories?.map((ctg, idx) => {
                                    return (
                                        <Link to={`/product-listing/${ctg?._id}`} onClick={() => getProducts(ctg?._id)} className={`${id === ctg?._id && 'active'} categoriesItem text-center d-block text-decoration-none`}>
                                            <div className="categoriesImg rounded-circle mx-auto position-relative">
                                                <img className="w-100 h-100" src={ctg?.image || Category1} alt="" />
                                            </div>
                                            <div className="categoriesName text-capitalize mt-3">{language !== ('en' || null) ? (ctg[`${language}_name`] ?? ctg?.name) : ctg?.name}</div>
                                            <div className="totalItem">{ctg?.productCount > 1 ? ctg?.productCount + ' Items' : ctg?.productCount + ' Item'}</div>
                                        </Link>
                                    )
                                })}
                            </OwlCarousel>
                        </div>
                    </div>
                </div>) : null}

                <div className="otherSliderMain sectionPading bgLight">
                    <Banners />
                </div>

                <div className="productSection sectionPading">
                    <div className="container">
                        <div className="sectionHeading">
                            <div className="row g-3 align-items-center">
                                <div className="col">
                                    <div className="title d-inline-block">
                                        <div className="txt">{lang("Products")}</div>
                                        <div className="titleDesign d-flex align-items-center gap-2 mt-3"></div>
                                    </div>
                                </div>
                                <div className="col-auto">
                                    <div className="position-relative">
                                        <div onClick={() => setShow(x => !x)} className="p0 searchBtn d-flex align-items-cneter justify-content-center border-0 shadow-none">
                                            <img className="" src={filterIcon} alt="" />
                                        </div>
                                        <div className={`${!show ? 'd-none' : ''} filterWrap-cls`}>
                                            <div>
                                                <select value={bId} onChange={(e) => { setBID(e.target.value); console.log(bId); }}>
                                                    <option value={''}>Brand</option>
                                                    {
                                                        brands.length && (brands?.map((br, idx) => {
                                                            return <option key={idx} value={br?._id}><input type="checkbox" width={20} />{br?.name}</option>
                                                        }))
                                                    }
                                                </select>
                                            </div>
                                            <div className="d-flex gap-2">
                                                <select value={mkId} onChange={(e) => { setMKID(e.target.value); }}>
                                                    <option value={''}>Make</option>
                                                    {
                                                        make.length && (make?.map((mk, idx) => {
                                                            return <option key={idx} value={mk?._id}><input type="checkbox" width={20} />{mk?.name}</option>
                                                        }))
                                                    }
                                                </select>
                                                <select value={mdId} onChange={(e) => { setMDID(e.target.value); }}>
                                                    <option value={''}>Model</option>
                                                    {
                                                        model.length && (model?.map((md, idx) => {
                                                            return <option key={idx} value={md?._id}><input type="checkbox" width={20} />{md?.name}</option>
                                                        }))
                                                    }
                                                </select>
                                            </div>
                                            <div className="border-bottom">
                                                <input value={pn} onChange={({ target }) => { setPN(target.value); }} placeholder="Part Number" />
                                            </div>
                                            <div className="border-bottom d-flex">
                                                <button onClick={() => { setBID(''); setPN(''); setMDID(''); setMKID(''); }}>Reset</button>
                                                <button onClick={() => handleApply()}>Apply</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-auto d-flex align-items-center gap-3">
                                    <InputGroup className="position-relative">
                                        <Form.Control type="search" id='headerSearch' onChange={({ target }) => handleSearch(target.value)} className="rounded-1 pe-5" placeholder={lang("Search") + "..."} />
                                        <label for="headerSearch" className="btn searchBtn d-flex align-items-cneter justify-content-center m-0 rounded-1 border-0 shadow-none position-absolute end-0 top-0 bottom-0 me-1 my-auto">
                                            <img className="w-100" src={searchIcon} alt="search" />
                                        </label>
                                    </InputGroup>
                                    <Dropdown className="filterDrop shortBy">
                                        <Dropdown.Toggle id="dropdown-basic" className="p0 searchBtn d-flex align-items-cneter justify-content-center border-0 shadow-none">
                                            <img className="" src={shortingIcon} alt="" style={{ filter: "invert(1)" }} />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu className="border-0 p-0">
                                            <Dropdown.Item className="border-bottom" onClick={() => handleSort('A-Z')} href="#sort=A-Z">A {lang("to")} Z</Dropdown.Item>
                                            <Dropdown.Item className="border-bottom" onClick={() => handleSort('Z-A')} href="#sort=Z-A">Z {lang("to")} A</Dropdown.Item>
                                            <Dropdown.Item className="border-bottom" onClick={() => handleSort('price_low')} href="#sort=Low-High">Price - Low to High</Dropdown.Item>
                                            <Dropdown.Item className="border-bottom" onClick={() => handleSort('price_high')} href="#sort=High-Low">Price - High to Low</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </div>
                        </div>
                        <div className="productsOuter mt-4">
                            {loading ? (<Loader />) : (<div className="row g-3 row-cols-xxl-6">
                                {products?.length
                                    ? (products?.map((item, idx) => {
                                        return (
                                            <ProductsCard prod={item} favourite={item?.is_wishlist} />
                                        )
                                    })

                                    ) : (
                                        <div>
                                            <img width={120} src={NoDataFound} alt="" />
                                            <h6 className='pt-2'>{lang("No Data Found")}</h6>
                                        </div>
                                    )
                                }
                            </div>)}
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Product_listing;

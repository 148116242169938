import React, { useContext, useState } from "react";
import Modal from 'react-bootstrap/Modal';
import lang from "../../helper/lang";
import apiPath from "../../constants/apiPath";
import useRequest from "../../hooks/useRequest";
import { Severty, ShowToast } from "../../helper/toast";
import { Spinner } from "react-bootstrap";
import { AuthContext } from "../../context/AuthContext";


const ConfirmDeleteModal = ({ onClose }) => {

    const { request } = useRequest();
    const { logout } = useContext(AuthContext);
    const [deleting, setDeleting] = useState(false);

    const deleteAccount = () => {
        setDeleting(true);
        request({
            url: apiPath.deleteAccount,
            method: "GET",
            onSuccess: (data) => {
                if (data.status) {
                    logout(data.message);
                    onClose("");
                }
                setDeleting(false);
                // ShowToast(data.message, Severty.ERROR);
            },
            onError: (err) => {
                setDeleting(false);
                ShowToast(err.message, Severty.ERROR);
            }
        })
    }

    return (
        <Modal className="loginModal" show={true} onHide={() => onClose("")} centered backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title className="fs-5 fw-bold">{lang("Delete Account")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="pera">
                    {lang("Are you sure you want to delete your account? Please read how account deletion will affect")}.
                    <br /><br />
                    {lang("Deleting your account removes personal information our database. Tour email becomes permanently reserved and same email cannot be re-use to register a new account")}.
                </div>
                <div className="mt-3 mt-sm-4">
                    <button type="button" disabled={deleting} onClick={() => deleteAccount()} class="btn btnStyle1 w-100 shadow-none text-white">{deleting ? <Spinner animation="border" size="sm" /> : lang("Delete")}</button>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default ConfirmDeleteModal;
import React, { useEffect, useState } from "react";
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import PassShow from '../../Assets/img/icon/passShowIcon.svg';
import PassHide from '../../Assets/img/icon/passHideIcon.svg';
import apiPath from "../../constants/apiPath";
import useRequest from '../../hooks/useRequest'
import { Severty, ShowToast } from "../../helper/toast";
import lang from "../../helper/lang";


const ResetPasswordModal = ({ onClose, formData }) => {
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [showCPassword, setShowCPassword] = useState(false);
    const [errors, setErrors] = useState({});
    const {request} = useRequest();

    console.log(formData);
    
    const handleSubmit = (e) => {
        e.preventDefault();
        const newError = {}
        if (password == "") {
            newError.password = lang("Please enter password");
        }
        if (password.length < 6) {
            newError.password = lang("Please enter minimum 6 character password");
        }
        if (confirmPassword == "") {
            newError.confirmPassword = lang("Please enter confirm password");
        }
        if (password != confirmPassword) {
            newError.confirmPassword = lang("Password not match");
        } 
        setErrors(newError);

        if (Object.keys(newError).length === 0) {
            let body = {
                ...formData,
                password
            }
            request({
                url: apiPath.resetPassword,
                method: "POST",
                data: body,
                onSuccess: (data) => {
                    if(data.status){
                        onClose("login", false);
                        ShowToast(data.message, Severty.SUCCESS);
                    }
                    else{
                        ShowToast(data.message, Severty.ERROR);
                    }
                },
                onError: (err) => {
                    ShowToast(err.message, Severty.ERROR);
                    console.log(err);
                },
                header: { 'Content-Type': 'application/json' }
            })
        }

    }

    useEffect(()=>{
        // if(password) setErrors(prev => { return {...prev, password:""}})
        if(password?.length >= 6) setErrors(prev => { return {...prev, password:""}})
        if(confirmPassword) setErrors(prev => { return {...prev, confirmPassword:""}})
    },[password, confirmPassword]);

    return (
        <>
            <Modal className="setPasswordModal" show={true} onHide={(e) => onClose("")} centered backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title className="fs-5 fw-bold">{lang("Reset Password")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form className="loginSignUpForm" onSubmit={handleSubmit}>
                        <Row className="gap-3">
                            <Form.Group as={Col} md="12">
                                <Form.Label>{lang("New Password")}</Form.Label>
                                <div className="position-relative">
                                    <Form.Control
                                        type={`${showPassword ? "text" : "password"}`}
                                        placeholder={lang("Enter New Password")}
                                        className=""
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                    <button type="button" onClick={()=>setShowPassword(x=>!x)} className="btn passBtn border-0 shadow-none p-0 position-absolute d-flex align-items-cneter justify-content-center">
                                        <img className="passShow" src={!showPassword ? PassShow : PassHide} />
                                    </button>
                                </div>
                            </Form.Group>
                            {errors?.password && <div style={{ fontSize: "13px", color: "red", marginTop: -12, marginLeft: 10 }}>{errors.password}</div>}

                            <Form.Group as={Col} md="12">
                                <Form.Label>{lang("Confirm New Password")}</Form.Label>
                                <div className="position-relative">
                                    <Form.Control
                                        type={`${showCPassword ? "text" : "password"}`}
                                        placeholder={lang("Enter Confirm New Password")}
                                        className=""
                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                    />
                                    <button type="button" onClick={()=>setShowCPassword(x=>!x)} className="btn passBtn border-0 shadow-none p-0 position-absolute d-flex align-items-cneter justify-content-center">
                                        <img className="passShow" src={!showCPassword ? PassShow : PassHide} />
                                    </button>
                                </div>
                            </Form.Group>
                            {errors?.confirmPassword && <div style={{ fontSize: "13px", color: "red", marginTop: -12, marginLeft: 10 }}>{errors.confirmPassword}</div>}

                            <Form.Group as={Col} md="12" className="mt-3">
                                <Button type="submit" className="btn btnStyle1 shadow-none text-white px-4 w-100">{lang("Save")}</Button>
                            </Form.Group>
                        </Row>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ResetPasswordModal;
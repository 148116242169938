import React, { useEffect, useState } from "react";
import Button from 'react-bootstrap/Button';
import Collapse from 'react-bootstrap/Collapse';
import arrowDown from '../../Assets/img/icon/arrowDown.svg';
import deleteDangerIcon from '../../Assets/img/icon/deleteDangerIcon.svg';
import apiPath from "../../constants/apiPath";
import useRequest from "../../hooks/useRequest";
import { Severty, ShowToast } from "../../helper/toast";
import moment from "moment";
import { Popconfirm } from "antd";
import lang from "../../helper/lang";
import { useNavigate } from "react-router";


const NotificationsCard = ({ notify, setRefresh }) => {

    const [open, setOpen] = useState(false);
    const { request } = useRequest();
    const navigate = useNavigate();

    const temp = new Date(notify?.created_at).toString();
    let time = temp?.split(" ")[4].substring(0, 5);
    time += time.split(":")[0] > 11 ? ' PM' : ' AM'
    const date = notify?.created_at?.split("T")[0];
    const range = moment(date, "YYYYMMDD").fromNow();

    const msg = notify?.title?.split("_")[0];
    const qId = notify?.title?.split("_")[1];
    const des = notify?.description?.split("_")[0]
    const qname = notify?.description?.split("_")[1]
    let desc2 = notify?.description?.split("_")[2]
    let des2 = desc2?.split('.')[0]
    let name = desc2?.split('.')[1] + '.' + desc2?.split('.')[2]

    const handleDelete = (id) => {
        request({
            url: apiPath.singleDelete + id,
            method: 'GET',
            onSuccess: (data) => {
                if (data.status) {
                    setRefresh();
                }
                ShowToast(data.message, Severty.ERROR);
            },
            onError: (err) => {
                ShowToast(err.message, Severty.ERROR);
            }
        })
    }

    const handleRead = (id) => {
        setOpen(!open)
        !notify?.is_read && (
            request({
                url: apiPath.singleRead + id,
                method: 'GET',
                onSuccess: (data) => {
                    if (data.status) {
                        setRefresh();
                        // ShowToast(data.message, Severty.SUCCESS);
                    }
                },
                onError: (err) => {
                    ShowToast(err.message, Severty.ERROR);
                }
            })
        )
    }

    useEffect(() => {
        console.log(range);
    }, []);
    console.log(name);

    const handleNavigate = () => {
        // if (notify?.data?.type === 'QUOTE_RECEIVED') {
        //     navigate(`/quote-detail/${notify?.data?.id}`);
        // }
        // else if (notify?.title?.includes('sent')) {
        //     navigate(`/quotes/${notify?.data?.id}?status=requested`);
        // }
        // else if (notify?.title?.includes('fulfill')) {
        //     navigate(`/quotes/${notify?.data?.id}?status=fulfill`);
        // }

        navigate(`/quote-details-page/${notify?.data?.quote_id}`);
    }

    return (
        <div onClick={() => handleRead(notify?._id)} className={`${!notify?.is_read && 'unread'} notificationItem p-3 bg-white position-relative d-flex align-items-center gap-2`} >
            <div className="row g-sm-3 g-2 w-100">
                <div className="col-auto">
                    <div onClick={() => handleNavigate()} className="imgOuter rounded-circle overflow-hidden">
                        <img className="w-100 h-100" src="https://images.unsplash.com/photo-1575936123452-b67c3203c357?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8aW1hZ2V8ZW58MHx8MHx8fDA%3D&w=1000&q=80" />
                    </div>
                </div>
                <div className="col">
                    <div className="d-flex gap-2 justify-content-between">
                        <div className="title">
                            {qId ? `${lang(msg)} ${qId}` : lang(msg)}
                        </div>
                        <div className="d-flex align-items-center gap-3">
                            <div className="notiTime">{range.includes("hour") ? time : (range.includes("a day") ? 'Yesterday' : date)}</div>
                            <Popconfirm
                                title="Remove the product"
                                description="Are you sure to remove this product?"
                                onConfirm={(e) => { e.stopPropagation(); handleDelete(notify?._id) }}
                                onCancel={(e) => e.stopPropagation()}
                                okText="Yes"
                                cancelText="No"
                            >
                                <button
                                    type="button"
                                    onClick={(e) => e.stopPropagation()}
                                    className="deleteBtn border-0 shadow-none rounded-circle d-flex align-items-center justify-content-center">
                                    <img className="w-100" src={deleteDangerIcon} />
                                </button>
                            </Popconfirm>
                        </div>
                    </div>
                    <Button
                        className="notificationReadMore position-absolute end-0 me-3 bg-transparent p-0 pe-sm-1 border-0 shadow-none"
                        aria-controls="notification1"
                        aria-expanded={open}
                    >
                        <img className="" src={arrowDown} />
                    </Button>
                    <Collapse in={open}>
                        <div id="notification1" className="notificationDec me-3">
                            {qname ? `${lang(des)} ${qname} ${des2 ? des2 : ''}` : lang(des)}
                        </div>
                    </Collapse>
                </div>
            </div>
        </div>
    )
}

export default NotificationsCard;
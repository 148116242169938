import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useRequest from "../../hooks/useRequest";
import apiPath from "../../constants/apiPath";
import Loader from "../Loader";
import lang from "../../helper/lang";
import { useAppContext } from "../../context/AppContext";
import NoDataFound from "../../Assets/img/NotFound.png";

const QuotesCard = ({ setLen, status, quoteId }) => {
  const [loading, setLoading] = useState(false);
  const [quotesList, setQuotesList] = useState([]);
  const { language } = useAppContext();
  const { request } = useRequest();

  const getQuotesList = () => {
    setLoading(true);
    request({
      url:
        apiPath.getQuoteList +
        status +
        (status === "received" ? "" : `/${quoteId}`),
      method: "GET",
      onSuccess: (data) => {
        if (data.status) {
          setLen(data.data?.length);
          setQuotesList(data.data);
        }
        setLoading(false);
      },
      onError: (err) => {
        console.log(err);
        setLoading(false);
      },
    });
  };

  useEffect(() => {
    getQuotesList();
  }, []);

  return (
    <div className="row g-3 row-cols-1 row-cols-md-2 row-cols-xl-3 row-cols-xxxl-4">
      {loading ? (
        <Loader />
      ) : quotesList?.length ? (
        quotesList?.map((quote) => {
          return (
            <div className="col">
              <Link
                to={
                  status === "received"
                    ? `/quote-reply/${quote?._id}`
                    : `/quote-details-page/${quote?._id}`
                }
                className="quoteItem bg-white p-3 d-block text-decoration-none"
              >
                <div className="quoteType text-capitalize mb-1">{}</div>
                <div className="d-flex align-items-center text-capitalize justify-content-between py-2 border-bottom mb-1">
                  <div className="quoteId mb-1">
                    <div className="quoteValue"> #{quote?.quoteId}</div>
                    <div className="quoteTitle">{lang("Quote ID")}</div>
                  </div>
                  {quote?.vehicle_id && (
                    <div className="quoteDetail mb-1 text-end">
                      <div className="quoteValue text-capitalize">
                        {status !== "received"
                          ? quote?.vehicle_id?.make_id?.name +
                            " - " +
                            quote?.vehicle_id?.model_id?.name
                          : quote?.make_id?.name +
                            " - " +
                            quote?.model_id?.name}
                      </div>
                      <div className="quoteTitle">{lang("Make & Model")}</div>
                    </div>
                  )}
                </div>
                <div className="d-flex align-items-center justify-content-between pt-2">
                  <div className="quoteId mb-1">
                    <div className="text-capitalize quoteValue">
                      {language !== ("en" || null)
                        ? quote[`${language}_name`] ?? quote?.name
                        : quote?.name}
                    </div>
                    <div className="quoteTitle">{lang("Request Name")}</div>
                  </div>
                  {/* {status === "fulfill" ? (
                    <div className="quoteDetail mb-1 text-end">
                      <div className="quoteValue">{quote?.quote_reply_id?.price ? "R " + quote?.quote_reply_id?.price : " - "}</div>
                      <div className="quoteTitle">{lang("Final Price")}</div>
                    </div>
                  ) : ( */}
                  <div className="quoteDetail mb-1 text-end">
                    <div className="quoteValue">{quote?.category_id?.name}</div>
                    <div className="quoteTitle">{lang("Category")}</div>
                  </div>
                  {/* )} */}
                </div>
                {quote?.request_type !== "Service" ? (
                  <div className="d-flex align-items-center justify-content-between pt-2">
                    {quote?.part_number && (
                      <div className="quoteId mb-1">
                        <div className="text-capitalize quoteValue">
                          {quote?.part_number}
                        </div>
                        <div className="quoteTitle">{lang("Part Number")}</div>
                      </div>
                    )}
                    <div className="quoteDetail mb-1 text-end">
                      <div className="quoteValue">{quote?.part_type}</div>
                      <div className="quoteTitle">{lang("Part Type")}</div>
                    </div>
                  </div>
                ) : null}
                <div className="text-capitalize quoteDec mt-2">
                  {language !== ("en" || null)
                    ? quote[`${language}_description`] ?? quote?.description
                    : quote?.description}
                </div>
              </Link>
            </div>
          );
        })
      ) : (
        <div>
          <img width={120} src={NoDataFound} alt="" />
          <h6 className="pt-2">{lang("No Data Found")}</h6>
        </div>
      )}
    </div>
  );
};

export default QuotesCard;

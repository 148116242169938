import React, { useState, useEffect } from 'react';
import splash from '../Assets/img/Desktop1.png';
import logo from '../Assets/img/logo.png';
import { AuthContext } from '../context/AuthContext';
import { useContext } from 'react';

const SplashScreen = ({ onFinish }) => {
    const [isVisible, setIsVisible] = useState(true);
    // const { setFlag } = useContext(AuthContext);

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsVisible(false);
            localStorage.setItem('flag', 'false');
            onFinish();
        }, 3000);

        return () => clearTimeout(timer);
    }, [onFinish]);

    return isVisible ? (
        <div className="splash-screen">
            <img className="w-100 h-100" src={splash} />
            <img className='logo' src={logo} />
        </div >
    ) : null;
};

export default SplashScreen;

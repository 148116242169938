import React, { useEffect, useState } from "react";
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import useRequest from "../../hooks/useRequest";
import apiPath from "../../constants/apiPath";
import PhoneInput from "react-phone-input-2";
import { Severty, ShowToast } from "../../helper/toast";
import lang from "../../helper/lang";

const ForgotPasswordModal = ({ onClose }) => {
    const [formData, setFormData] = useState({
        country_code:"27",
        mobile_number:"",
        type: "Customer"
    });
    const {request} = useRequest();
    const [errors, setErrors] = useState()
    
    
    const handleSubmit = (e) => {
        e.preventDefault();
        const newError = {};

        if (formData.mobile_number == "" || formData.mobile_number == null || formData.mobile_number == undefined) {
            newError.mobile_number = lang("Please enter mobile number");
        } else if (formData.mobile_number.length < 7) {
            newError.mobile_number = lang("Please enter atleast 7 digit number");
        } 
        setErrors(newError);

        if (Object.keys(newError).length === 0) {
            request({
                url: apiPath.forgotPassword,
                method: "POST",
                data: formData,
                onSuccess: (data)=>{
                    if(data.status){
                        ShowToast(data.message, Severty.SUCCESS)
                        onClose("otp", formData, true);
                    } else {
                        ShowToast(data.message, Severty.ERROR)
                    }
                },
                onError:(err)=>{
                    ShowToast(err.message, Severty.ERROR)
                }
            })
        }

    }

    return (
        <>
            <Modal className="forgotPasswordModal" show={true} onHide={() => onClose("")} centered backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title className="fs-5 fw-bold">{lang("Forgot Password")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form className="loginSignUpForm" onSubmit={handleSubmit}>
                        <Row className="gap-3">
                            <Form.Group as={Col} md="12">
                                <Form.Label>{lang("Mobile Number")}</Form.Label>
                                <div className="input-group">
                                <PhoneInput
                                    inputProps={((type) => {
                                        return {
                                            name: 'phone',
                                            autoFocus: true
                                        }
                                    })()}
                                    // country={"in"}
                                    value={formData?.country_code + formData?.mobile_number}
                                    onChange={(value, data) => { setFormData( prev=> ({...prev, country_code: data.dialCode})); setFormData( prev=> ({...prev, mobile_number: value.slice(data.dialCode.length)})); }}
                                />
                                </div>
                            </Form.Group>
                            {errors?.mobile_number && <div style={{ fontSize: "13px", color: "red", marginTop: -12, marginLeft: 10 }}>{errors.mobile_number}</div>}

                            <Form.Group as={Col} md="12" className="mt-3">
                                <Button type="submit" className="btn btnStyle1 shadow-none text-white px-4 w-100">{lang("Send OTP")}</Button>
                            </Form.Group>
                            {errors?.exist && <div style={{ fontSize: "13px", color: "red", marginTop: -12, marginLeft: 10 }}>{errors.exist}</div>}

                            <Form.Group as={Col} md="12" className="mb-2">
                                <div className="otherLink text-center">{lang("Remember Password")}? <a href="javascript:;" onClick={()=>onClose("login")} className="textBtn">{lang("Login")}</a></div>
                            </Form.Group>
                        </Row>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ForgotPasswordModal;
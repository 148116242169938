import { useEffect, useState } from "react";
import plusIconDark from "../Assets/img/icon/plusIconDark.svg";
import editDarkIcon from "../Assets/img/icon/editDarkIcon.svg";
// import deleteDangerIcon from '../Assets/img/icon/deleteDangerIcon.svg';
import VehicleImg1 from "../Assets/img/VehicleImg1.png";
import Banners from "../Components/banners/Banners";
import AddVehicleModal from "../Components/Modalss/AddVehicleModal";
import useRequest from "../hooks/useRequest";
import { Severty, ShowToast } from "../helper/toast";
import apiPath from "../constants/apiPath";
import Loader from "../Components/Loader";
import lang from "../helper/lang";
import { useAppContext } from "../context/AppContext";
import NoDataFound from "../Assets/img/NotFound.png";

const My_vehicle_list = () => {
  const [show, setShow] = useState(false);
  const [vehicles, setVehicles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [flag, setFlag] = useState("");
  const [car, setCar] = useState(null);
  const { request } = useRequest();
  const { language } = useAppContext();

  const getVehicleList = () => {
    setLoading(true);
    request({
      url: apiPath.getVehicles,
      method: "GET",
      onSuccess: (data) => {
        if (data.status) {
          setVehicles(data.data);
          // ShowToast(data.message, Severty.SUCCESS);
        }
        setLoading(false);
      },
      onError: (err) => {
        ShowToast(err.message, Severty.ERROR);
        setLoading(false);
      },
    });
  };

  useEffect(() => {
    getVehicleList();
  }, []);

  const edit_fun = async (vehicle) => {
    setFlag("edit");
    setCar(vehicle);
    setShow(true);
    console.log(vehicle);
  };
  return (
    <>
      <div className="mainWraper">
        <div className="otherSliderMain sectionPading bgLight">
          <Banners />
        </div>

        <div className="categorySection sectionPading pt-4">
          <div className="container">
            <div className="sectionHeading">
              <div className="row align-items-center">
                <div className="col">
                  <div className="title d-inline-block">
                    <div className="txt">{lang("Vehicle List")}</div>
                    <div className="titleDesign d-flex align-items-center gap-2 mt-3"></div>
                  </div>
                </div>
                <div className="col-auto">
                  <button
                    onClick={() => {
                      setShow(true);
                      setFlag("add");
                      setCar(null);
                    }}
                    type="button"
                    className="textBtn textBtnDark border-0 shadow-none p-0 d-flex align-items-center justify-content-center gap-1"
                  >
                    <img alt="" className="" src={plusIconDark} />
                    {lang("Add Vehicle")}
                  </button>
                </div>
              </div>
            </div>
            {loading ? (
              <Loader />
            ) : (
              <div className="vehicleListingOuter mt-2">
                <div className="row g-3 row-cols-xxxl-4">
                  {vehicles?.length ? (
                    vehicles?.map((vehicle) => {
                      return (
                        <div className="col-xl-4 col-md-6">
                          <div className="vehicleItem bg-white p-3 position-relative">
                            <div className="row align-items-center g-3">
                              <div className="col-auto">
                                <div className="imgOuter">
                                  <img
                                    alt=""
                                    className="w-100 h-100"
                                    src={
                                      vehicle?.image
                                        ? apiPath.getImage + vehicle?.image
                                        : VehicleImg1
                                    }
                                  />
                                </div>
                              </div>
                              <div className="col">
                                <div className="vehicleDetail">
                                  <div className="brand text-uppercase">
                                    {language !== ("en" || null)
                                      ? vehicle?.make_id[`${language}_name`] ??
                                        vehicle?.make_id?.name
                                      : vehicle?.make_id?.name}
                                  </div>

                                  <div className="name text-capitalize">
                                    {language !== ("en" || null)
                                      ? vehicle?.model_id[`${language}_name`] ??
                                        vehicle?.model_id?.name
                                      : vehicle?.model_id?.name}
                                  </div>

                                  <div className="year">
                                    {vehicle?.year || "2023"}
                                  </div>
                                  <div className="vehicleNumber text-uppercase">
                                    {vehicle?.vin_number}
                                  </div>
                                  <div className="position-absolute top-0 end-0 mt-2 me-2 d-flex align-items-center gap-2">
                                    {/* <button type="button" className="deleteBtn border-0 p-2 shadow-none rounded-circle d-flex align-items-center justify-content-center"><img className="w-100" src={deleteDangerIcon}/></button> */}
                                    <button
                                      onClick={() => {
                                        edit_fun(vehicle);
                                      }}
                                      type="button"
                                      className="editBtn border-0 p-2 shadow-none rounded-circle d-flex align-items-center justify-content-center"
                                    >
                                      <img
                                        alt=""
                                        className="w-100"
                                        src={editDarkIcon}
                                      />
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div>
                      <img width={120} src={NoDataFound} alt="" />
                      <h6 className="pt-2">{lang("No Vehicle Added")}</h6>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {show && (
        <AddVehicleModal
          onClose={() => setShow(false)}
          flag={flag}
          car={car}
          setRefresh={getVehicleList}
        />
      )}
    </>
  );
};

export default My_vehicle_list;
